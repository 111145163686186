import { ComputedRef, InjectionKey, Ref } from 'vue'
import { Application } from '@/utils/types/application'
import { CachedObjectProxy } from 'best-modules/utils'
import { Validation } from '@vuelidate/core'

const applicationKey: InjectionKey<CachedObjectProxy<Application>> =
  Symbol('Application')
const createApplicationKey: InjectionKey<ComputedRef<boolean>> =
  Symbol('createApplication')
const calculationListKey: InjectionKey<Ref<any[]>> = Symbol('calculationList')
const updateCalculationListKey: InjectionKey<() => void> = Symbol(
  'updateCalculationList'
)
const updateApplicationObjectKey: InjectionKey<(_obj: Application) => void> =
  Symbol('updateApplicationObject')
const hasApprovedCalculationKey: InjectionKey<ComputedRef<boolean>> = Symbol(
  'hasApprovedCalculation'
)
const v$Key: InjectionKey<Validation> = Symbol('v$Key')

export {
  applicationKey,
  createApplicationKey,
  calculationListKey,
  updateCalculationListKey,
  updateApplicationObjectKey,
  hasApprovedCalculationKey,
  v$Key,
}
