<template>
  <div>
    <div class="mb-3 left-block cursor-default" style="border-radius: 0">
      <span
        class="mb-3 d-block"
        style="color: #08487a; font-weight: 700; font-size: 16px"
        >Загальні дані</span
      >
      <div>
        <span class="area-label">Коротка назва</span>
        <v-text-field
          v-model="contractorData.shortName"
          :error-messages="shortNameErr"
          :readonly="readonly"
          dense
          hide-details
          @blur="v$.contractor.shortName.$touch()">
        </v-text-field>
        <v-checkbox
          id="cp-ac-edrpou"
          v-model="contractorData.resident"
          :readonly="readonly"
          class="mt-1"
          color="red"
          hide-details
          dense
          @update:model-value="handleResidentCountry">
          <template #label>
            <span style="font-size: 0.8rem">Резидент</span>
          </template>
        </v-checkbox>
        <v-checkbox
          v-model="contractorData.publicSector"
          :readonly="readonly"
          color="red"
          class="mt-0"
          hide-details
          dense>
          <template #label>
            <span style="font-size: 0.8rem"> Клієнт з державного сектора </span>
          </template>
        </v-checkbox>
        <v-row>
          <v-col
            v-if="
              contractorData?.types?.some(type => type.id === 2) &&
              contractorData?.status
            "
            :cols="12"
            :md="12">
            <span class="area-label">Клієнт:</span>
            <v-text-field
              :value="contractorData?.status?.name"
              readonly
              dense
              hide-details>
            </v-text-field>
          </v-col>
          <v-col :cols="12" :md="0" class="pt-0">
            <span class="area-label">Країна резиденства</span>
            <v-autocomplete
              v-model="contractorData.residentCountryId"
              class="mt-0"
              :items="
                $directory.get(
                  'nationalityList',
                  contractorData.residentCountry
                )
              "
              :loading="$loading.isLoading('nationalityList')"
              item-title="name"
              item-value="id"
              placeholder="Оберіть зі списку"
              dense
              hide-details
              :readonly="contractorData.resident"
              @focus="$directory.fill('nationalityList')">
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0" :cols="12" :md="12">
            <span class="area-label">Галузь</span>
            <v-select
              v-model="contractorData.industryId"
              :readonly="readonly"
              placeholder="Оберіть зi списку"
              :items="
                directoryItems('contractorIndastries', contractorData.industry)
              "
              item-title="name"
              item-value="id"
              dense
              @focus="asyncAction('addContractorIndastries')">
            </v-select>
          </v-col>
        </v-row>
        <v-text-field
          v-if="
            $array(() => contractorData.contactTypeId).includes(C.type.CLIENT)
          "
          :model-value="contractorData.client_status.name"
          label="Статус клієнта"
          disabled
          readonly
          dense>
        </v-text-field>
      </div>
    </div>
    <div class="left-block">
      <span class="area-label">Сегмент</span>
      <v-autocomplete
        v-model="contractorData.segmentId"
        :items="filteredSegmentTypes"
        item-title="name"
        item-value="id"
        hide-details
        placeholder="Оберіть зi списку"
        dense
        :readonly="readonly"
        @focus="$directory.fill('segmentTypes')">
        <template #append-item>
          <loader v-if="$loading.isLoading('segmentTypes')"></loader>
        </template>
      </v-autocomplete>
      <span class="area-label mt-3">Офіційний сайт</span>
      <div v-if="!contractorData.isSite">
        <Site
          v-if="!pageLoad"
          v-model="contractorData.webSite"
          :readonly="readonly">
        </Site>
      </div>
      <v-checkbox
        v-model="contractorData.isSite"
        :readonly="readonly"
        color="red"
        hide-details
        dense>
        <template #label>
          <span style="font-size: 0.8rem">Cайт відсутній</span>
        </template>
      </v-checkbox>
    </div>
    <div class="left-block mt-3">
      <!-- change responsible -->
      <ChangeResponsible
        v-model:responsibleId="contractorData.responsibleId"
        :read-only="contractorData.readOnly"
        :responsible="contractorData.responsible" />
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Site from '@/components/site.vue'
import { mapState, mapGetters } from 'vuex'
import { required } from '@vuelidate/validators'
import {
  getResponsibleShortName,
  getContactInitials,
  setErrHandler,
} from '@/utils/helperFunc'
import { phoneMask } from '@/utils/masks'
import { email, phone } from '@/type-ids'
// import { urlContractorAddContact } from '@/pages/request.js'
import { contractor as C } from '@/type-ids'
import ChangeResponsible from '@/components/ChangeResponsible.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { getDirectory } from '@/plugins/directory/index.ts'

export default {
  name: 'CommonCard',
  components: { ChangeResponsible, Site, Loader },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: {
    readonly: { type: Boolean },
    contractor: { type: Object },
    pageLoad: { type: Boolean },
    checkPhone: { type: Function },
    checkEmail: { type: Function },
    temporaryPhones: { type: Array },
    temporaryEmails: { type: Array },
  },
  validations() {
    return {
      contractor: {
        shortName: { required },
      },
    }
  },
  data: () => ({
    dialog: false,
    dialogState: null,
    dialogItem: {},
    C: C, // contractor types
    email,
    phone,
    changeResponsible: false,
    tempResponsibleId: null,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    filteredSegmentTypes() {
      return getDirectory('segmentTypes', this.contractorData.segment).filter(
        item => item.id === 1 || item.id === 2 || item.id === 3 || item.id === 4
      )
    },
    contractorData() {
      return this.contractor
    },
    shortNameErr() {
      return setErrHandler(this.v$.contractor.shortName)
    },
    mainPhone() {
      if (!this.contractorData.phones.length) return
      return (
        this.contractorData.phones.find(item => item.typeId === phone.type.MAIN)
          ?.phone || null
      )
    },
    mainEmail() {
      if (!this.contractorData.emails.length) return
      return (
        this.contractorData.emails.find(item => item.typeId === email.type.WORK)
          ?.email || null
      )
    },
  },
  methods: {
    getContactInitials,
    // urlContractorAddContact,
    phoneMask,
    getResponsibleShortName,
    openDialog(action, item = {}) {
      this.dialog = true
      this.dialogState = action
      this.dialogItem = item
    },
    // addMainContact(contact) {
    //   return this.$axios
    //     .post(this.urlContractorAddContact(this.$route.params.id), contact)
    //     .catch(this.$err)
    // },
    handleResidentCountry(val) {
      if (val) {
        this.contractorData.residentCountryId = 222
        this.contractorData.residentCountry = {
          id: 222,
          name: 'УКРАЇНА',
        }
      } else {
        this.contractorData.residentCountryId = null
      }
    },
  },
}
</script>

<style scoped lang="scss">
.left-block {
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 10px;
}

.v-input--selection-controls {
  margin-top: 0 !important;
}
.text-field {
  margin-bottom: 12px;
}
.text-title {
  color: #1c1d21;
  font-weight: 600;
  font-size: 14px;
  margin-right: 6px;
  display: block;
}
.text-desc {
  font-weight: 400;
  font-size: 13px;
  color: #1c1d21;
  display: block;
}
</style>
