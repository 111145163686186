<template>
  <v-hover open-delay="200">
    <template #default="{ isHovering, props }">
      <v-card
        v-bind="props"
        class="mt-3 comment-card"
        style="background: white !important"
        :elevation="0">
        <v-card-text class="d-flex pb-1 pt-2">
          <div class="text-wrapper">
            <div class="commentIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none">
                <path
                  d="M1.583 13.321C0.553 12.227 0 11 0 9.011C0 5.511 2.457 2.374 6.03 0.822998L6.923 2.201C3.588 4.005 2.936 6.346 2.676 7.822C3.213 7.544 3.916 7.447 4.605 7.511C6.409 7.678 7.831 9.159 7.831 11C7.831 11.9283 7.46225 12.8185 6.80587 13.4749C6.1495 14.1313 5.25926 14.5 4.331 14.5C3.81766 14.4955 3.31034 14.389 2.83856 14.1866C2.36679 13.9841 1.93999 13.6899 1.583 13.321ZM11.583 13.321C10.553 12.227 10 11 10 9.011C10 5.511 12.457 2.374 16.03 0.822998L16.923 2.201C13.588 4.005 12.936 6.346 12.676 7.822C13.213 7.544 13.916 7.447 14.605 7.511C16.409 7.678 17.831 9.159 17.831 11C17.831 11.9283 17.4623 12.8185 16.8059 13.4749C16.1495 14.1313 15.2593 14.5 14.331 14.5C13.8177 14.4955 13.3103 14.389 12.8386 14.1866C12.3668 13.9841 11.94 13.6899 11.583 13.321Z"
                  fill="#C0C1C7" />
              </svg>
            </div>
            <div style="width: 100%" class="pb-2">
              <div v-if="!readonly && isHovering" class="action-block">
                <v-tooltip bottom :text="onEdit ? 'відмiнити' : 'видалити'">
                  <template #activator="{ propsOne }">
                    <v-btn
                      :disabled="loading.delete"
                      icon
                      small
                      color="#C0C1C7"
                      v-bind="propsOne"
                      @click="
                        onEdit ? reset() : $emit('delete', localComment.id)
                      ">
                      <Loader v-if="loading.delete" :size="16" />
                      <v-icon v-else>
                        {{ 'mdi-trash-can-outline' }}
                      </v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
                <v-tooltip bottom text="редагувати">
                  <template #activator="{ propsTwo }">
                    <v-btn
                      v-show="!onEdit"
                      icon
                      size="x-small"
                      color="green"
                      v-bind="propsTwo"
                      @click="$emit('onEdit', localComment.id)">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none">
                        <path
                          d="M1.75 18.25H18.25M10.2035 4.34233L12.7958 1.75L17.3333 6.2875L14.741 8.87983M10.2035 4.34233L5.06375 9.48208C4.89183 9.65395 4.79522 9.88707 4.79517 10.1302V14.2882H8.95317C9.19626 14.2881 9.42938 14.1915 9.60125 14.0196L14.741 8.87983M10.2035 4.34233L14.741 8.87983"
                          stroke="#C0C1C7"
                          stroke-width="1.7"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </v-btn>
                  </template>
                </v-tooltip>
                <v-tooltip bottom text="зберегти">
                  <template #activator="{ propsThree }">
                    <v-btn
                      v-show="
                        onEdit && localComment.comment !== comment.comment
                      "
                      :disabled="loading.update"
                      icon
                      size="x-small"
                      color="green"
                      v-bind="propsThree"
                      @click="$emit('update', localComment)">
                      <Loader v-if="loading.update" :size="16" />
                      <v-icon v-else size="small">
                        {{ 'mdi-check' }}
                      </v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </div>
              <div class="d-flex mt-2 mb-2 align-center">
                <v-avatar size="52px" color="indigo" class="mr-4">
                  <temp-image
                    v-if="localComment.user && localComment.user.photo"
                    alt="user photo"
                    :src="localComment.user.photo" />
                  <v-icon v-else color="white">
                    {{ 'mdi-account-circle' }}
                  </v-icon>
                </v-avatar>
                <div class="text-left date commentAuthor">
                  {{
                    getContactInitials(localComment.user, {
                      isResponsible: true,
                    })
                  }}
                </div>
              </div>
              <div class="d-flex justify-space-between gap-3">
                <v-textarea
                  v-if="onEdit"
                  v-model="localComment.comment"
                  class="mt-0 pt-0 sm"
                  :rows="2"
                  dense
                  hide-details>
                </v-textarea>
                <div
                  v-else
                  style="max-width: 610px"
                  class="comment"
                  v-html="localComment.comment.replace(/\n/g, '<br>')"></div>
                <div class="text-right date">
                  {{ date }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
import { getContactInitials, toFormatDate } from '@/utils/helperFunc'
import Loader from '@/components/Loader.vue'
import TempImage from '@/components/temp-image.vue'
export default {
  components: { TempImage, Loader },
  emits: ['onEdit', 'update', 'delete'],
  props: {
    comment: { type: Object },
    readonly: { type: Boolean },
    onEdit: { type: Boolean },
    loading: { type: Object },
  },
  data() {
    return {
      localComment: null,
    }
  },
  computed: {
    date() {
      const createdAt = toFormatDate(this.comment.created_at)
      const updatedAt = toFormatDate(this.comment.updated_at)

      return createdAt !== updatedAt ? '(Редаговано) ' + updatedAt : createdAt
    },
  },
  methods: {
    getContactInitials,
    reset() {
      this.localComment.comment = this.comment.comment
      this.$emit('onEdit', null)
    },
  },
  watch: {
    comment: {
      handler(val) {
        this.localComment = Object.clone(val)
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.date {
  font-size: 0.8rem;
}
.sm {
  &.v-textarea {
    font-size: 0.85rem;
  }
}
.text-wrapper {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .commentIcon {
    width: 35px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    padding-right: 10px;
    border-right: 1px solid #d7d7d7;
  }

  .commentAuthor {
    color: #1c1d21;
    font-size: 14px;
    font-weight: 600;
  }
}
.action-block {
  position: absolute;
  right: 0;
  top: 3px;
}
.comment-card {
  border: 1px solid gainsboro !important;
  flex: 1 1 auto;
  position: relative;
  min-height: 55px !important;
  .comment {
    color: black;
    font-size: 0.9rem;
    flex: 1 1 auto;
  }
}
</style>
