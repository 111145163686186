<template>
  <div>
    <v-row class="pr-3">
      <v-col :cols="12" :sm="6">
        <LeasingParticipant
          v-model:signatoryId="agreement.lessorSignatoryId"
          v-model:basisId="agreement.lessorBasisId"
          label="Лізингодавець"
          :typeId="2"
          :participant="agreement?.contract?.lessor"
          :v$="{
            contactId: $v.agreement.lessorSignatoryId,
            basisId: $v.agreement.lessorBasisId,
          }"
          :readonly="readonly"
          single
          details />
      </v-col>

      <v-col :cols="12" :sm="6" :class="`${mobile ? '' : 'pl-0'}`">
        <LeasingParticipant
          v-model:signatories="agreement.lesseeSignatories"
          label="Лізингоотримувач"
          :typeId="agreement.lesseeTypeId"
          :participant="agreement.lessee"
          :v$="$v.agreement.lesseeSignatories"
          :readonly="readonly"
          details />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import LeasingParticipant from '@/pages/contracts/components/leasingParticipant/LeasingParticipant.vue'
import { mapState } from 'vuex'

export default {
  components: { LeasingParticipant },
  props: {
    readonly: { type: Boolean },
    mobile: { type: Boolean },
  },
  computed: {
    ...mapState({
      agreement: state => state.contracts.extraAgreement,
      $v: state => state.contracts.$extraAgreement,
    }),
  },
}
</script>
