<template>
  <div>
    <SectionLoader v-if="loading"></SectionLoader>
    <div>
      <div v-show="!loading">
        <v-row class="profile-block">
          <v-col cols="12" md="3" sm="12">
            <div>
              <v-file-input
                v-show="false"
                id="material-image"
                ref="fileInput"
                v-model="tempPhoto"
                :error-messages="tempPhotoErr"
                class="employee-image"
                accept="image/x-png, image/gif, image/jpeg, image/jpg, image/svg+xml"
                label="Фото"
                :rules="rules"
                variant="outlined"
                show-size
                dense
                @change="previewImage($event)">
              </v-file-input>
            </div>
            <v-fade-transition hide-on-leave>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <div
                    v-show="materialImgPreview || user.photo"
                    :class="{ 'flex-column': !user.photo }"
                    class="d-flex justify-center align-center"
                    style="position: relative">
                    <v-avatar
                      style="border: 2px solid #fc7247"
                      size="140px"
                      color="#fc7247"
                      class="pb-0 mt-2">
                      <v-icon
                        v-if="!user.photo"
                        color="white"
                        @click="triggerFileInput">
                        {{ 'mdi-account-circle' }}
                      </v-icon>
                      <temp-image
                        v-if="materialImgPreview || user.photo"
                        :src="materialImgPreview || user.photo"
                        :alt="user.photo" />
                    </v-avatar>
                    <div
                      v-if="user.telegramChatId"
                      style="position: absolute; top: 0; right: 0">
                      <v-tooltip
                        bottom
                        :text="`Телеграм аккаунт ${user.telegramChatId}`">
                        <template #activator="{ props }">
                          <v-btn icon size="small" v-bind="props">
                            <a
                              target="_blank"
                              :href="'tg://user?id=' + user.telegramChatId">
                              <Telegram />
                            </a>
                          </v-btn>
                        </template>
                        <span
                          >Телеграм аккаунт (ID:{{ user.telegramChatId }})</span
                        >
                      </v-tooltip>
                    </div>
                    <div v-if="user.photo">
                      <v-tooltip bottom text="Видалити фото">
                        <template #activator="{ props }">
                          <v-btn
                            style="position: absolute; top: 0; right: 0"
                            size="small"
                            icon
                            v-bind="props"
                            @click="clearPreviewImg()">
                            <v-icon color="#fc7247">{{ 'mdi-delete' }}</v-icon>
                          </v-btn>
                        </template>
                        <span>Видалити фото</span>
                      </v-tooltip>
                    </div>

                    <div
                      v-if="materialImgPreview === null && !user.photo"
                      class="text-center mb-2 mt-2">
                      <span class="label"
                        >Фото відображатиметься у профілі</span
                      >
                    </div>
                  </div>
                  <div v-if="!routeEmployeeCreate">
                    <span
                      style="font-size: 14px"
                      class="d-block text-center font-weight-bold"
                      >{{
                        getContactInitials(user, { isResponsible: true })
                      }}</span
                    >
                    <div
                      style="font-size: 12px"
                      class="mt-1 d-flex align-center justify-center">
                      <span class="mr-1"
                        >В системі з
                        {{ toFormatDate(user.created_at, true) }}</span
                      >
                      <v-tooltip v-if="user.isActive" right text="Активний">
                        <template #activator="{ props }">
                          <div v-bind="props">
                            <CheckIcon />
                          </div>
                        </template>
                        <span>Активний</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <profile-section v-if="!routeEmployeeCreate">
                    <template #body>
                      <span
                        v-if="!user.telegramChatId"
                        class="pt-3 d-block"
                        style="font-size: 12px; color: grey">
                        Підключіть Telegram щоб приймати сповіщення від Бота,
                        при зміні даних в системі, завжди залишайтеся в курсі
                        подій.
                      </span>
                      <div class="d-flex align-center">
                        <v-btn
                          class="mt-2 no-transform"
                          size="small"
                          color="#09487a"
                          variant="flat"
                          style="width: 100%"
                          :disabled="loading"
                          @click="
                            changeTelegramBot(
                              !!user.telegramChatId ? 'disconnect' : 'connect'
                            )
                          ">
                          <v-icon
                            class="mr-1"
                            color="#fff"
                            style="font-size: 20px"
                            >{{ 'mdi-chat-remove-outline' }}</v-icon
                          >
                          {{
                            !!user.telegramChatId
                              ? 'Відʼєднати телеграм'
                              : 'Додати телеграм'
                          }}
                        </v-btn>
                      </div>
                    </template>
                  </profile-section>
                </v-col>
              </v-row>
            </v-fade-transition>
            <profile-section>
              <template #title>Налаштування</template>
              <template #body>
                <v-row class="mt-2 mb-2">
                  <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
                    <v-checkbox
                      v-model="user.canCreateDealerPoint"
                      :false-value="false"
                      :true-value="true"
                      class="mt-0"
                      dense
                      hide-details
                      color="red"
                      :disabled="salesDirector || sysAdmin">
                      <template #label>
                        <span style="font-size: 12px">
                          Створення диллерських точок</span
                        >
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
                    <v-checkbox
                      v-model="user.allApplicationsView"
                      :false-value="false"
                      :true-value="true"
                      class="mt-0"
                      dense
                      color="red"
                      hide-details>
                      <template #label>
                        <span style="font-size: 12px">
                          Перегляд всіх заявок</span
                        >
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="pb-0 pt-0">
                    <v-checkbox
                      v-model="user.leadAccess"
                      :false-value="false"
                      :true-value="true"
                      class="mt-0"
                      dense
                      color="red"
                      hide-details>
                      <template #label>
                        <span style="font-size: 12px">
                          Доступ до розділу ліди</span
                        >
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </template>
            </profile-section>
            <profile-section v-if="!routeEmployeeCreate">
              <template #title> Сповіщення </template>
              <template #body>
                <v-row class="mt-2">
                  <v-col class="pb-0 pt-0">
                    <v-checkbox
                      v-model="user.isNotificationTelegram"
                      :disabled="loading"
                      hide-details
                      color="red"
                      @update:model-value="updateNotification('telegram')"
                      ><template #label>
                        <span style="font-size: 12px">Telegram</span>
                      </template></v-checkbox
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-0 pt-0">
                    <v-checkbox
                      v-model="user.isNotificationEmail"
                      hide-details
                      color="red"
                      :disabled="loading"
                      @update:model-value="updateNotification('email')"
                      ><template #label>
                        <span style="font-size: 12px">Email</span>
                      </template></v-checkbox
                    >
                  </v-col>
                </v-row>
              </template>
            </profile-section>
          </v-col>
          <v-col cols="12" md="9" sm="12">
            <profile-section>
              <template #title>Контактна інформація</template>
              <template #body>
                <v-row>
                  <v-col class="pb-0" cols="12" md="4" sm="12">
                    <span class="label">Прізвище</span>
                    <v-text-field
                      v-model="user.surname"
                      :error-messages="surnameErr"
                      placeholder="Введіть текст"
                      dense
                      hide-details
                      @blur="v$.user.surname.$touch()">
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="4" sm="12">
                    <span class="label">Iм`я</span>
                    <v-text-field
                      v-model="user.name"
                      :error-messages="nameErr"
                      placeholder="Введіть текст"
                      dense
                      hide-details
                      @blur="v$.user.name.$touch()">
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="4" sm="12">
                    <span class="label">По батькові</span>
                    <v-text-field
                      v-model="user.patronymic"
                      :error-messages="patronymicErr"
                      placeholder="Введіть текст"
                      hide-details
                      dense
                      @blur="v$.user.patronymic.$touch()">
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="4" sm="12">
                    <span class="label">Телефон</span>
                    <v-text-field
                      id="number"
                      v-model="user.phone"
                      :error-messages="phoneErr"
                      placeholder="Введіть номер"
                      dense
                      hide-details
                      @blur="
                        () => {
                          v$.user.phone.$touch()
                        }
                      "
                      @input="phoneMask(user.phone, 'number')">
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="4" sm="12">
                    <span class="label">Email</span>
                    <v-text-field
                      v-model="user.email"
                      :error-messages="emailErr"
                      placeholder="Введіть номер"
                      dense
                      hide-details
                      @blur="v$.user.email.$touch()">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-0" cols="12" md="4" sm="12">
                    <span class="label">Стать</span>
                    <v-select
                      v-model="user.genderId"
                      item-title="name"
                      item-value="id"
                      :error-messages="genderIdErr"
                      :items="$directory.get('genderList', user.gender)"
                      placeholder="Оберіть зі списку"
                      hide-details
                      dense
                      :loading="$loading.isLoading('genderList')"
                      @focus="$directory.fill('genderList')">
                    </v-select>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="4" sm="12">
                    <span class="label">Дата народження</span>
                    <DatePicker
                      v-model="user.birthDate"
                      single
                      hideDetails
                      placeholder="Оберіть дату" />
                  </v-col>
                  <v-col class="pb-0" cols="12" md="4" sm="12">
                    <span class="label">Посада</span>
                    <v-text-field
                      v-model="user.post"
                      :error-messages="positionErr"
                      placeholder="Введіть текст"
                      hide-details
                      dense
                      @blur="v$.user.post.$touch()">
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="4" sm="12">
                    <span class="label">IНН</span>
                    <v-text-field
                      id="employees-inn"
                      v-model="user.inn"
                      :error-messages="innErr"
                      placeholder="Введіть номер"
                      dense
                      hide-details
                      @input="maxNumLength('employees-inn', 10)"
                      @blur="v$.user.inn.$touch()">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" class="pb-0">
                    <span class="label">Департамент</span>
                    <v-select
                      v-model="user.departmentId"
                      :error-messages="departmentIdErr"
                      :items="$directory.get('departments', user.department)"
                      item-title="name"
                      item-value="id"
                      placeholder="Оберіть зі списку"
                      hide-details
                      dense
                      :loading="$loading.isLoading('departments')"
                      @focus="$directory.fill('departments')"
                      @blur="v$.user.departmentId.$touch()">
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" class="pb-0">
                    <span class="label">Роль користувача</span>
                    <v-select
                      v-model="user.roleId"
                      :items="$directory.get('allUserRoles', user.role)"
                      :error-messages="roleIdErr"
                      item-title="nameUa"
                      item-value="id"
                      dense
                      hide-details
                      placeholder="Оберіть зі списку"
                      :loading="$loading.isLoading('allUserRoles')"
                      @update:model-value="setUserRights"
                      @focus="$directory.fill('allUserRoles')"
                      @blur="v$.user.roleId.$touch()">
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <span class="label">profileDWH</span>
                    <v-text-field
                      v-model="user.profileDWH"
                      placeholder="Введіть текст"
                      hide-details
                      dense>
                    </v-text-field>
                  </v-col>
                  <v-col v-if="routeEmployeeCreate" cols="12" md="4" sm="12">
                    <span class="label">Пароль</span>
                    <v-text-field
                      v-model="user.password"
                      placeholder="Введіть текст"
                      hide-details
                      type="password"
                      dense>
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- password section -->
                <profile-section v-if="!routeEmployeeCreate">
                  <template #title>
                    <v-btn
                      class="mt-3"
                      variant="tonal"
                      @click="changePassword = !changePassword">
                      <v-icon style="font-size: 20px" class="mr-1">{{
                        'mdi-lock-outline'
                      }}</v-icon>
                      <span>{{
                        !changePassword ? 'Змінити пароль' : 'Скасувати'
                      }}</span>
                    </v-btn>
                  </template>
                  <template #body>
                    <div v-if="changePassword">
                      <v-row class="pt-2 align-center">
                        <v-col class="pb-0" cols="12" sm="12" md="4">
                          <span class="label">Новий пароль</span>
                          <v-text-field
                            v-model="password"
                            :error-messages="passwordErr"
                            type="password"
                            placeholder="Введіть новий пароль"
                            dense
                            @blur="v$.password.$touch()">
                          </v-text-field>
                        </v-col>
                        <v-col class="pb-0" cols="12" sm="12" md="4">
                          <span class="label">Підтвердіть новий пароль</span>
                          <v-text-field
                            v-model="password_confirmation"
                            :error-messages="password_confirmationErr"
                            type="password"
                            placeholder="Введіть новий пароль"
                            dense
                            @blur="v$.password_confirmation.$touch()">
                          </v-text-field>
                        </v-col>
                        <v-col v-show="!v$.$invalid" cols="12" sm="12" md="4">
                          <v-btn
                            size="small"
                            style="background: #fc7247"
                            class="text-white no-transform"
                            :loading="passwordLoading"
                            @click="acceptChangePassword">
                            Змінити
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                </profile-section>
              </template>
            </profile-section>
          </v-col>
        </v-row>
        <v-card-actions class="pt-0">
          <ActionButtons
            :confirm="registerUser"
            :confirmLoading="loading"
            :confirmBtnText="routeEmployeeCreate ? 'Створити' : 'Оновити'"
            :cancel="cancelEdit"
            :cancelDisable="userCache === cache || loading"
            :confirmDisable="userCache === cache">
          </ActionButtons>
        </v-card-actions>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'
import SectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import { phoneMask, maxNumLength } from '@/utils/masks'
import { getImgName } from '@/utils/formatFunc'
import {
  setErrHandler,
  v$Notify,
  setCache,
  getContactInitials,
  toFormatDate,
} from '@/utils/helperFunc'
import { required, minLength, sameAs, email } from '@vuelidate/validators'
import {
  urlPostCreateUser,
  urlPostUpdateUser,
  urlGetAdminSingleUser,
  urlPostUploadImage,
  urlPostDeleteImage,
  urlAdminUserChangePassword,
  urlUserDisconnectTelegram,
} from '@/pages/request'
import { mapState } from 'vuex'
/* eslint-disable */
import {
  ADMIN,
  FRONT_MANAGER,
  SUPERVISOR,
  SALES_DIRECTOR,
  MIDLE_MANAGER,
  MIDLE_HEAD,
  RISK_MANAGER,
  RISK_HEAD,
  SECURITY_SERVICE,
  LEGAL_MANAGER,
  LEGAL_HEAD,
  FINANCIAL_MONITORING,
  CEO,
} from '@/roles'
import ProfileSection from '@/pages/profile/components/profile-section.vue'
import CheckIcon from '@/assets/svg/checkIcon.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { setSnackbar } from 'best-modules/plugins'
import Telegram from '@/assets/svg/telegram.vue'
import TempImage from '@/components/temp-image.vue'

export default {
  components: {
    TempImage,
    Telegram,
    CheckIcon,
    ProfileSection,
    DatePicker,
    SectionLoader,
    ActionButtons,
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    const password = this.changePassword
      ? { required, minLength: minLength(8) }
      : {}
    const password_confirmation = this.changePassword
      ? { required, sameAsPassword: sameAs(this.password) }
      : {}
    const userPassword = this.routeEmployeeCreate
      ? { required, minLength: minLength(8) }
      : {}

    return {
      user: {
        surname: { required, minLength: minLength(2) },
        name: { required, minLength: minLength(2) },
        patronymic: { required, minLength: minLength(2) },
        phone: { required, minLength: minLength(23) },
        email: { required, email },
        roleId: { required },
        genderId: { required },
        birthDate: { required, minLength: minLength(10) },
        inn: { required, minLength: minLength(10) },
        departmentId: { required },
        post: { required },
        password: userPassword,
      },
      password: password,
      password_confirmation: password_confirmation,
      tempPhoto: { required },
    }
  },
  data: () => ({
    changePassword: false,
    password: null,
    password_confirmation: null,
    passwordLoading: false,
    loading: false,
    user: {
      leadAccess: false,
      surname: null,
      name: null,
      patronymic: null,
      phone: null,
      email: null,
      genderId: null,
      id: null,
      birthDate: null,
      roleId: null,
      inn: null,
      departmentId: null,
      post: null,
      photo: null,
      canSighting: false,
      canCreateDealerPoint: false,
      profileDWH: null,
      allApplicationsView: false,
      password: null,
    },
    tempPhoto: null,
    photoName: null,
    materialImgPreview: null,
    cache: null,
    maxImageSize: 2000000,
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    salesDirector() {
      return this.user.roleId === SALES_DIRECTOR
    },
    sysAdmin() {
      return this.user.roleId === ADMIN
    },
    surnameErr() {
      const errors = []
      if (!this.v$.user.surname.$error) return errors
      if (this.v$.user.surname.required.$invalid) {
        errors.push('Поле обов`язкове для заповнення')
      }
      if (this.v$.user.surname.minLength.$invalid) {
        errors.push('Мiнiмально довжина 2 лiтери')
      }
      return errors
    },
    nameErr() {
      const errors = []
      if (!this.v$.user.name.$error) return errors
      if (this.v$.user.name.required.$invalid) {
        errors.push('Поле обов`язкове для заповнення')
      }
      if (this.v$.user.name.minLength.$invalid) {
        errors.push('Мiнiмально довжина 2 лiтери')
      }
      return errors
    },
    patronymicErr() {
      const errors = []
      if (!this.v$.user.patronymic.$error) return errors
      if (this.v$.user.patronymic.required.$invalid) {
        errors.push('Поле обов`язкове для заповнення')
      }
      if (this.v$.user.patronymic.minLength.$invalid) {
        errors.push('Мiнiмально довжина 2 лiтери')
      }
      return errors
    },
    phoneErr() {
      const errors = []
      if (!this.v$.user.phone.$error) return errors
      if (this.v$.user.phone.required.$invalid) {
        errors.push('Поле обов`язкове для заповнення')
      }
      if (this.v$.user.phone.minLength.$invalid) {
        errors.push('Невiрний номер моб. телефону')
      }
      return errors
    },
    emailErr() {
      const errors = []
      if (!this.v$.user.email.$error) return errors
      if (this.v$.user.email.required.$invalid) {
        errors.push('Поле обов`язкове для заповнення')
      }
      if (this.v$.user.email.email.$invalid) {
        errors.push('Невірний email')
        return errors
      }
    },
    innErr() {
      const errors = []
      if (!this.v$.user.inn.$error) return errors
      if (this.v$.user.inn.required.$invalid) {
        errors.push('Поле обов`язкове для заповнення')
      }
      if (this.v$.user.inn.minLength.$invalid) {
        errors.push('Невірний ідентифікаційний код')
      }
      return errors
    },
    passwordErr() {
      const errors = []
      if (!this.v$.password.$error) return errors
      if (this.v$.password.required.$invalid) {
        errors.push('Поле обов`язкове для заповнення')
      }
      if (this.v$.password.minLength.$invalid) {
        errors.push('Мінімальна довжина 8 символів')
      }
      return errors
    },
    password_confirmationErr() {
      const errors = []
      if (!this.v$.password_confirmation.$error) return errors

      if (this.v$.password_confirmation.required.$invalid) {
        errors.push('Поле обов`язкове для заповнення')
      }
      if (this.v$.password_confirmation.sameAsPassword.$invalid) {
        errors.push('Паролі не співпадають')
      }

      return errors
    },
    roleIdErr() {
      return setErrHandler(this.v$.user.roleId)
    },
    genderIdErr() {
      return setErrHandler(this.v$.user.genderId)
    },
    birthDateErr() {
      return setErrHandler(this.v$.user.birthDate)
    },
    departmentIdErr() {
      return setErrHandler(this.v$.user.departmentId)
    },
    positionErr() {
      return setErrHandler(this.v$.user.post)
    },
    tempPhotoErr() {
      return setErrHandler(this.v$.tempPhoto)
    },
    userCache() {
      return setCache([this.user])
    },
    rules() {
      return [
        value =>
          !value ||
          value.size < this.maxImageSize ||
          'Розмiр зображення повинен бути меньше 2 MB!',
      ]
    },
    routeEmployeeCreate() {
      return this.$route.name === 'employee-create'
    },
  },
  methods: {
    toFormatDate,
    getContactInitials,
    urlUserDisconnectTelegram,
    phoneMask,
    maxNumLength,
    changeTelegramBot(action) {
      if (action === 'disconnect') {
        return this.disconnectTelegram()
      } else {
        return window.open('https://t.me/BestLeasingNotificationBot', '_blank')
      }
    },
    disconnectTelegram() {
      return this.$axios
        .get(this.urlUserDisconnectTelegram(this.user.id))
        .then(res => {
          if (res.data.message) {
            setSnackbar({ text: res.data.message })
          }
          this.$store.commit('refreshPage')
        })
    },
    triggerFileInput() {
      this.$refs.fileInput.$el.querySelector('input').click()
    },
    setUserRights(roleId) {
      const enableCreateDealerPoints = () =>
        (this.user.canCreateDealerPoint = true)
      const setDefaultRights = () => {
        this.user.canSighting = false
        this.user.canCreateDealerPoint = false
      }

      switch (roleId) {
        case ADMIN:
          return enableCreateDealerPoints()
        case FRONT_MANAGER:
          return setDefaultRights()
        case SUPERVISOR:
          return setDefaultRights()
        case SALES_DIRECTOR:
          return enableCreateDealerPoints()
        case MIDLE_MANAGER:
          return setDefaultRights()
        case MIDLE_HEAD:
          return setDefaultRights()
        case RISK_MANAGER:
          return setDefaultRights()
        case RISK_HEAD:
          return setDefaultRights()
        case SECURITY_SERVICE:
          return setDefaultRights()
        case LEGAL_MANAGER:
          return setDefaultRights()
        case LEGAL_HEAD:
          return setDefaultRights()
        case FINANCIAL_MONITORING:
          return setDefaultRights()
        case CEO:
          return setDefaultRights()
      }
    },
    acceptChangePassword() {
      this.passwordLoading = !this.passwordLoading
      if (this.password && this.password_confirmation) {
        if (this.password === this.password_confirmation) {
          if (!this.v$.$anyError) {
            return this.$axios
              .post(urlAdminUserChangePassword(this.$route.params.id), {
                password: this.password,
              })
              .then(() => {
                return (
                  this.$setSnackbar({ text: 'Пароль змінено' }),
                  this.$store.commit('refreshPage')
                )
              })
              .catch(this.$err)
          } else {
            this.$setSnackbar({
              text: 'Форма має помилки. Виправте їх',
              color: 'warning',
            })
          }
        } else {
          this.$setSnackbar({ text: 'Паролі не співпадають', color: 'warning' })
        }
      } else {
        this.$setSnackbar({
          text: 'Обидва поля мають бути заповненими',
          color: 'warning',
        })
      }
    },

    async updateNotification(notification) {
      if (
        notification === 'telegram' &&
        !this.user.telegramChatId &&
        this.user.isNotificationTelegram
      ) {
        this.user.isNotificationTelegram = false
        return setSnackbar({
          text: 'Підключіть Telegram щоб приймати сповіщення від Бота,',
          color: 'warning',
        })
      }
      this.loading = true
      this.user.password = null
      const { data } = await this.$axios.post(
        urlPostUpdateUser(this.user.id),
        this.user
      )
      this.$store.commit('setUser', data)
      this.loading = false
    },

    clearPreviewImg() {
      if (this.photoName) {
        return this.$axios
          .post(urlPostDeleteImage(), { image: this.photoName })
          .then(res => {
            this.photoName = null
            this.user.photo = null
            this.materialImgPreview = null
            this.tempPhoto = null
            return res
          })
          .catch(err => this.$err(err, () => (this.loading = false)))
      }
    },
    previewImage(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0]
        if (file.size > this.maxImageSize) {
          this.clearPreviewImg()
          return
        } else {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = data => {
            this.materialImgPreview = data.currentTarget.result
          }

          this.uploadPhoto()
        }
      } else {
        this.clearPreviewImg()
      }
    },
    getCurrentUser() {
      if (!this.$route.params.id) return

      this.loading = true
      return this.$axios
        .get(urlGetAdminSingleUser(this.$route.params.id))
        .then(res => {
          Object.assign(this.user, res.data)
          this.loading = false

          this.getImageType(res.data.photo).then(imgType => {
            if (imgType !== 'text/html') {
              this.tempPhoto = { value: res.data.photo }
              this.photoName = getImgName(res.data.photo)
            } else {
              this.user.photo = null
            }
          })

          this.setBreadScrumb()
          this.cache = setCache([this.user])
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    setBreadScrumb() {
      const usr = this.user
      this.$store.commit('setBreadScrumbPart', [
        null,
        null,
        `${usr.surname} ${usr.name} ${usr.patronymic}`,
      ])
    },
    getImageType(url) {
      return fetch(url)
        .then(res => res.blob())
        .then(img => img.type)
        .catch(this.$err)
    },
    cancelEdit() {
      this.$store.commit('refreshPage')
    },
    uploadPhoto() {
      let formData = new FormData()
      formData.append('image', this.tempPhoto)

      return this.$axios
        .post(urlPostUploadImage(), formData)
        .then(res => {
          Object.assign(this.user, { photo: res?.data?.url })
          this.photoName = getImgName(res?.data?.url)
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    registerUser() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(
          { tempPhoto: this.v$.tempPhoto, ...this.v$.user },
          'administrationEmployee'
        )
        return Promise.reject().catch(err => err)
      }

      return this.sendRequest()
    },
    sendRequest() {
      this.loading = true
      const url = this.routeEmployeeCreate
        ? urlPostCreateUser()
        : urlPostUpdateUser(this.$route.params.id)

      return this.$axios
        .post(url, this.user)
        .then(res => {
          Object.assign(this.user, res.data)
          this.$emit('getAllUsers')
          this.loading = false

          if (this.routeEmployeeCreate) {
            this.$setSnackbar({ text: 'Створено співробiтника' })
            this.$router.push({
              name: 'employee-edit',
              params: { id: this.user.id },
            })
          } else {
            this.$setSnackbar({ text: 'Співробiтника оновлено' })
          }
          if (res.data.id === this.$store.state.user.id) {
            this.$store.commit('setUser', res.data)
          }

          this.setBreadScrumb()
          this.cache = setCache([this.user])

          return res
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
  created() {
    this.getCurrentUser()
  },
}
</script>

<style scoped>
.profile-block {
  background: #fff;
  border-radius: 11px;
  padding: 10px;
  transition: 0.3s all;
  box-shadow: 0 0 15px -4px rgba(0, 0, 0, 0.11);
}
</style>
