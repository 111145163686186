<template>
  <div>
    <div v-if="application.lessee">
      <div class="d-flex align-center gap-2">
        <PersonalIcon />
        <span class="font-weight-bold" style="color: #08487a">
          Основні дані
        </span>
      </div>
      <div v-if="application.lesseeTypeId === 2" style="display: contents">
        <v-row class="mb-3">
          <v-col :cols="12" :sm="4">
            <span class="subtitle">Тип клієнта</span>
            <v-select
              :model-value="application.lesseeTypeId"
              :items="$directory.get('lesseeTypes', application.lesseeType) as any[]"
              item-title="name"
              item-value="id"
              readonly
              placeholder="Не вказано"
              hide-details
              dence></v-select>
          </v-col>
          <v-col :cols="12" :sm="4">
            <span class="subtitle">ЄДРПОУ</span>
            <v-text-field
              :model-value="application.lessee.edrpou"
              readonly
              placeholder="Не вказано"
              hide-details
              dence></v-text-field>
          </v-col>
          <v-col :cols="12" :sm="4">
            <span class="subtitle">Назва компанії</span>
            <v-text-field
              :model-value="application.lessee.shortName"
              readonly
              placeholder="Не вказано"
              hide-details
              dence></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="[1, 3].includes(application.lesseeTypeId)"
        style="display: contents">
        <v-row class="mb-3">
          <v-col :cols="12" :sm="4">
            <span class="subtitle">Тип клієнта</span>
            <v-select
              v-model="application.lesseeTypeId"
              :items="$directory.get('lesseeTypes', application.lesseeType).filter(t => t.id !== 2) as any[]"
              item-title="name"
              item-value="id"
              :readonly="application.readOnly"
              placeholder="Не вказано"
              hide-details
              dence></v-select>
          </v-col>
          <v-col :cols="12" :sm="4">
            <span class="subtitle">Ідентифікаційний номер</span>
            <v-text-field
              :model-value="application.lessee.inn"
              readonly
              placeholder="Не вказано"
              hide-details
              dence></v-text-field>
          </v-col>
        </v-row>
        <div class="d-flex align-center gap-2">
          <GuarantorIcon />
          <span class="font-weight-bold" style="color: #08487a">
            Контактна особа
          </span>
        </div>
        <v-row>
          <v-col :cols="12" :sm="4">
            <span class="subtitle">Прізвище</span>
            <v-text-field
              :model-value="application.lessee.surname"
              readonly
              placeholder="Не вказано"
              hide-details
              dence></v-text-field>
          </v-col>
          <v-col :cols="12" :sm="4">
            <span class="subtitle">Імʼя</span>
            <v-text-field
              :model-value="application.lessee.name"
              readonly
              placeholder="Не вказано"
              hide-details
              dence></v-text-field>
          </v-col>
          <v-col :cols="12" :sm="4">
            <span class="subtitle">По батькові</span>
            <v-text-field
              :model-value="application.lessee.patronymic"
              readonly
              placeholder="Не вказано"
              hide-details
              dence></v-text-field>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col :cols="12" :sm="4">
          <span class="subtitle">Телефон</span>
          <v-text-field
            v-for="phone in phones"
            :key="phone.id"
            :model-value="phone.phone"
            disabled
            placeholder="Не вказано"></v-text-field>
        </v-col>
        <v-col :cols="12" :sm="4">
          <span class="subtitle">Email</span>
          <v-text-field
            v-for="email in emails"
            :key="email.id"
            :model-value="email.email"
            disabled
            placeholder="Не вказано"></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div v-else class="text-disabled text-center mt-5">Оберіть клієнта</div>
  </div>
</template>

<script lang="ts">
import { computed, inject } from 'vue'
import { applicationKey, updateApplicationObjectKey } from '../../injectionKeys'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { Application } from '@/utils/types/application'
import PersonalIcon from '@/assets/svg/personal-icon.vue'
import GuarantorIcon from '@/assets/svg/guarantor-icon.vue'

export default {
  name: 'LesseeRetreatment',
  components: { GuarantorIcon, PersonalIcon },
  setup() {
    const updateApplicationObject = inject(updateApplicationObjectKey)
    const application = computed<CachedObjectProxy<Application>>({
      get: () => inject(applicationKey),
      set: updateApplicationObject,
    })

    const phones = computed(() => {
      if (application.value.lessee.phones?.length) {
        return application.value.lessee.phones
      } else {
        return [{ id: 0, phone: null }]
      }
    })
    const emails = computed(() => {
      if (application.value.lessee.emails?.length) {
        return application.value.lessee.emails
      } else {
        return [{ id: 0, email: null }]
      }
    })

    return { application, phones, emails }
  },
}
</script>

<style scoped>
.subtitle {
  color: #838282;
  font-size: 13px;
  margin-bottom: 2px;
}
</style>
