<template>
  <div>
    <Contacts
      style="margin-bottom: -35px !important"
      type="phone"
      :items="contactData.phones"
      :readonly="readonly"
      :entityId="contactData.id"
      :entityTypeId="1"
      :checkFunc="checkPhone" />
    <Contacts
      style="margin-bottom: -30px !important"
      type="email"
      :items="contactData.emails"
      :readonly="readonly"
      :entityId="contactData.id"
      :entityTypeId="1"
      :checkFunc="checkEmail" />
    <address-table
      v-if="rerenderAddresses"
      style="margin-top: 15px !important"
      :addresses="contactData.addresses"
      :readonly="readonly"
      :fop="contactData.fop"
      :entityId="contactData.id"
      :entityTypeId="1">
    </address-table>

    <list-section
      :title="'Банківські реквiзити'"
      :addActionDisabled="readonly"
      :addActionTitle="'Додати рахунок'"
      :addAction="addBankAccount">
      <template #body>
        <bank-account-table
          class="mt-2"
          :readonly="readonly"
          :bankAccounts="contactData.currentAccounts || []"
          @open-dialog="$emit('openDialog', $event)"
          @item-deleted="$emit('tableChange')">
        </bank-account-table>
      </template>
    </list-section>
  </div>
</template>
<script>
import Contacts from '@/components/Contacts/Contacts.vue'
import bankAccountTable from '@/components/bank-account-table.vue'
import listSection from '@/components/list-section.vue'
import addressTable from '@/components/address-table.vue'
import { mapState } from 'vuex'
import { contactFilter } from '@/utils/helperFunc'
import {
  urlDeleteContactAddress,
  urlCreateContactAddress,
} from '@/pages/request'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: {
    bankAccountTable,
    listSection,
    addressTable,
    Contacts,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    contact: { type: Object },
    checkPhone: { type: Function },
    checkEmail: { type: Function },
    readonly: { type: Boolean },
  },
  data: () => ({
    rerenderAddresses: true,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    contactData() {
      return this.contact
    },
  },
  methods: {
    urlDeleteContactAddress,
    urlCreateContactAddress,
    contactFilter,
    addBankAccount() {
      const accounts = this.contactData.currentAccounts
        ? this.contactData.currentAccounts.map(account => account?.type?.id)
        : []
      this.$emit('openDialog', ['addBankAccount', { accounts }])
    },
  },
  watch: {
    'contactData.familyStatusId': function (val) {
      if (val === 2) this.contactData.marriedId = null
    },
    'contactData.resident': {
      handler(val) {
        val &&
          ((this.contactData.nationalityId = 222),
          (this.contactData.nationality = { name: 'Україна', id: 222 }))
      },
    },
  },
}
</script>
