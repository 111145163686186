<template>
  <v-col :cols="12" :md="3" class="pr-1 pl-0 pt-0 pb-0 common-data">
    <div class="mb-3 left-block">
      <span
        class="mb-3 mt-2 d-block"
        style="color: #08487a; font-weight: 700; font-size: 16px"
        >Загальні дані</span
      >
      <div class="pt-0">
        <div
          v-if="
            contactData?.types?.some(type => type.id === 2) &&
            contactData?.status
          ">
          <span class="area-label">Клієнт:</span>
          <v-text-field
            :value="contactData?.status?.name"
            readonly
            dense
            hide-details>
          </v-text-field>
        </div>
        <span class="area-label">Прізвище</span>
        <v-text-field
          v-model="contactData.surname"
          dense
          hide-details
          placeholder="Введіть текст"
          :readonly="readonly">
        </v-text-field>
        <span class="area-label">Ім’я</span>
        <v-text-field
          v-model="contactData.name"
          dense
          hide-details
          placeholder="Введіть текст"
          :readonly="readonly">
        </v-text-field>
        <span class="area-label">По-батькові</span>
        <v-text-field
          v-model="contactData.patronymic"
          dense
          :disabled="!contactData.isPatronymic"
          hide-details
          :placeholder="contactData.isPatronymic ? 'Введіть текст' : 'Відсутнє'"
          :readonly="readonly">
        </v-text-field>
        <v-checkbox
          v-model="contactData.isPatronymic"
          :true-value="false"
          :false-value="true"
          hide-details
          :readonly="readonly"
          color="red"
          class="mt-2"
          density="comfortable"
          dense
          @update:model-value="handleIsPatronymic">
          <template #label>
            <span style="font-size: 12px">Відсутнє по батькові</span>
          </template>
        </v-checkbox>
        <v-checkbox
          v-model="contactData.resident"
          :readonly="readonly"
          color="red"
          class="mt-0"
          hide-details
          density="comfortable"
          dense>
          <template #label>
            <span style="font-size: 12px"> Резидент </span>
          </template>
        </v-checkbox>
        <v-tooltip
          text="Для зміни параметрів перейдіть на сторінку 'Юридичні дані'">
          <template #activator="{ props }">
            <v-checkbox
              :model-value="!!contactData.fop"
              class="mt-0"
              dense
              v-bind="props"
              hide-details
              color="red"
              density="comfortable"
              readonly>
              <template #label>
                <span style="font-size: 12px"> ФОП </span>
              </template>
            </v-checkbox>
          </template>
        </v-tooltip>
      </div>
    </div>
    <div class="left-block">
      <div v-if="contactData.fop">
        <span class="area-label">Сегмент</span>
        <v-autocomplete
          v-model="contactData.segmentId"
          :items="filteredSegmentTypes"
          item-title="name"
          item-value="id"
          hide-details
          placeholder="Оберіть зi списку"
          dense
          :readonly="readonly"
          @focus="$directory.fill('segmentTypes')">
          <template #append-item>
            <loader v-if="$loading.isLoading('segmentTypes')"></loader>
          </template>
        </v-autocomplete>
      </div>
      <div>
        <span class="area-label">Громадянство</span>
        <v-autocomplete
          v-model="contactData.nationalityId"
          :items="$directory.get('nationalityList', contactData.nationality)"
          item-title="name"
          item-value="id"
          hide-details
          placeholder="Оберіть зi списку"
          dense
          :readonly="readonly"
          @focus="$directory.fill('nationalityList')">
          <template #append-item>
            <loader v-if="$loading.isLoading('nationalityList')"></loader>
          </template>
        </v-autocomplete>
      </div>
      <v-row>
        <v-col :cols="12" :md="12">
          <span class="area-label">Стать</span>
          <v-select
            v-model="contactData.genderId"
            :readonly="readonly"
            hide-details
            :items="$directory.get('genderList', contactData.gender)"
            item-value="id"
            placeholder="Оберіть стать"
            item-title="name"
            dense
            @update:model-value="() => (contactData.familyStatusId = null)"
            @focus="$directory.fill('genderList')">
            <template #append-item>
              <loader v-if="$loading.isLoading('genderList')"></loader>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </div>
    <div class="left-block mt-3">
      <FamilyStatus
        :data="contactData"
        :readonly="readonly"
        :v$="v$"
        @change-family="changeFamily" />
    </div>
    <div class="left-block mt-3">
      <!-- change responsible -->
      <ChangeResponsible
        v-model:responsibleId="contactData.responsibleId"
        :read-only="contactData.readOnly"
        :responsible="contactData.responsible" />
    </div>
  </v-col>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { email, phone } from '@/type-ids'
import { contactFilter } from '@/utils/helperFunc'
import {
  getContactInitials,
  getResponsibleShortName,
} from '@/utils/helperFunc.js'
import { contact as C } from '@/type-ids.js'
import Loader from '@/components/Loader.vue'
import ChangeResponsible from '@/components/ChangeResponsible.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { getDirectory } from '@/plugins/directory/index.ts'
import { urlContactsGetAll, urlSetFamilyStatus } from '@/pages/request'
import FamilyStatus from '@/components/FamilyStatus.vue'

export default {
  components: {
    FamilyStatus,
    ChangeResponsible,
    Loader,
  },
  props: {
    contact: { type: Object },
    callback: { type: Function },
    v: { type: Object },
    readonly: { type: Boolean },
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    return {
      contact: {},
    }
  },
  data: () => ({
    C: C, // contact
    email,
    phone,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    filteredSegmentTypes() {
      if (this.contactData.fop) {
        return getDirectory('segmentTypes', this.contactData.segment).filter(
          item => item.id === 1 || item.id === 2
        )
      } else {
        return getDirectory('segmentTypes', this.contactData.segment).filter(
          item => item.id === 5
        )
      }
    },
    contactData() {
      return this.contact
    },
    mainPhone() {
      if (!this.contactData.phones.length) return
      return (
        this.contactData.phones.find(item => item.typeId === phone.type.MAIN)
          ?.phone || null
      )
    },
    mainEmail() {
      if (!this.contactData.emails.length) return
      return (
        this.contactData.emails.find(item => item.typeId === email.type.WORK)
          ?.email || null
      )
    },
    $v() {
      return this.v
    },
  },
  methods: {
    urlContactsGetAll,
    getContactInitials,
    getResponsibleShortName,
    contactFilter,
    handleIsPatronymic(val) {
      if (!val) {
        this.contactData.patronymic = null
      }
    },
    changeFamily(event) {
      return this.$axios
        .post(urlSetFamilyStatus(event.id), {
          familyStatusId: event?.familyStatusId,
          marriedId: event?.marriedId,
        })
        .then(res => {
          if (res) {
            this.$setSnackbar({ text: 'Дані оновлено' })
            this.$store.commit('refreshPage')
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.left-block {
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 10px;
}
.res-post {
  display: block;
  width: 270px;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.res-block {
  margin-bottom: 15px;
  &:hover .res-change {
    display: block !important;
  }
  .res-change {
    display: none;
    cursor: pointer;
    color: #09487a;
    font-weight: 500;
    font-size: 13px;
  }
}
.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
  display: block;
  margin-bottom: 5px;
}
</style>
