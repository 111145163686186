const contact = {
  state: () => ({
    passport: {
      typeId: 2,
      unzr: 2,
      serial: null,
      number: null,
      issueDate: null,
      issuedBy: null,
      sellBy: null,
      files: [],
    },
  }),
  mutations: {
    assignContact(state, data) {
      if (!data.passport) delete data.passport
      Object.assign(state, data)
    },
  },
  /* eslint-enable no-unused-vars */
  actions: {
    //...
  },
}

export { contact }
