<template>
  <div>
    <v-tooltip text="Учасники групи">
      <template #activator="{ props: tooltipProps }">
        <v-menu>
          <template #activator="{ props: menuProps }">
            <v-btn
              v-bind="{ ...tooltipProps, ...menuProps }"
              icon
              size="small"
              @click="showCompanyMembers = true">
              <v-icon>{{ 'mdi-account-group-outline' }}</v-icon>
            </v-btn>
          </template>
          <v-card min-width="500px" class="bg-white cursor-default">
            <v-card-text class="bg-white">
              <v-data-table
                :headers="tHead"
                :items="members || []"
                class="calc-table --cust-cell-pd"
                mobileBreakpoint="750"
                dense
                :items-per-page="50"
                hide-default-footer>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    members: { type: Array },
  },
  data: () => ({
    showCompanyMembers: false,
    tHead: [
      {
        title: 'Компанія',
        key: 'contractor.shortName',
        align: 'start',
        sortable: false,
      },
      {
        title: 'Роль',
        key: 'role.name',
        sortable: false,
        align: 'end',
      },
    ],
  }),
}
</script>

<style></style>
