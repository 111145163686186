import axios from 'axios'
import { urlLoginFirst, urlLoginSecond, urlLogout } from '@/pages/request'
import { Module } from 'vuex'

export interface Credentials {
  phone: string
  password: string
}

const state = {
  qrCode: localStorage.getItem('qrCode') || null,
  token: localStorage.getItem('token') || null,
  authUser: {
    id: localStorage.getItem('userId') || null,
    name: localStorage.getItem('userName') || null,
  },
  newYearBanner: localStorage.getItem('newYearBanner') || false,
  newYearTopBanner: localStorage.getItem('newYearTopBanner') || false,
}

const auth: Module<typeof state, any> = {
  state() {
    return state
  },
  mutations: {
    setNewYearBanner(state, value) {
      state.newYearBanner = value
      localStorage.setItem('newYearBanner', value ? 'true' : 'false')
    },
    setNewYearTopBanner(state, value) {
      state.newYearTopBanner = value
      localStorage.setItem('newYearTopBanner', value ? 'true' : 'false')
    },
    setQrCode(state, qrCode) {
      state.qrCode = qrCode
      localStorage.setItem('qrCode', qrCode)
    },
    removeQrCode(state) {
      state.qrCode = null
      localStorage.removeItem('qrCode')
    },
    setToken(state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    removeToken(state) {
      state.token = null
      localStorage.removeItem('token')
    },
    setAuthUser(state, authUser: { id: number; name: string }) {
      Object.assign(state.authUser, authUser)
      localStorage.setItem('userId', String(authUser.id))
      localStorage.setItem('userName', authUser.name)
    },
    removeAuthUser(state) {
      state.authUser.id = null
      state.authUser.name = null
      localStorage.removeItem('userId')
      localStorage.removeItem('userName')
    },
  },
  actions: {
    loginFirst({ commit }, credentials: { phone: string; password: string }) {
      return axios.post(urlLoginFirst(), credentials).then(res => {
        commit('setAuthUser', res.data.user)
        if (res.data.url) {
          commit('setQrCode', res.data.url)
        }
      })
    },
    loginSecond({ commit, state }, code) {
      return axios
        .post(urlLoginSecond(), {
          userId: state.authUser.id,
          code,
        })
        .then(res => {
          commit('setToken', res.data.token)
          commit('removeQrCode')
          commit('setNewYearBanner', true)
          commit('setNewYearTopBanner', true)
        })
    },
    logout({ commit }) {
      return axios.post(urlLogout()).then(() => {
        commit('removeToken')
        commit('removeQrCode')
        commit('removeAuthUser')
        commit('setNewYearBanner', false)
        commit('setNewYearTopBanner', false)
      })
    },
  },
}

export { auth }
