<template>
  <div>
    <SectionLoader v-if="!points.length" />
    <div v-else class="points" style="font-size: 13px">
      <v-dialog v-model="addManualEditorship" max-width="1100" persistent>
        <template #default="{ isActive }">
          <v-card title="Ручне введення" class="cursor-default">
            <v-card-text>
              <v-textarea
                v-model="manualEditorship"
                hide-details
                placeholder="Введіть текст"></v-textarea>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text="Зберегти"
                variant="flat"
                color="rgb(8, 72, 122)"
                @click="addManualEditorshipHandler"></v-btn>
            </v-card-actions>
            <template #append>
              <v-btn
                icon
                size="small"
                variant="text"
                @click="addManualEditorship = false">
                <v-icon>{{ 'mdi-close' }}</v-icon>
              </v-btn>
            </template>
          </v-card>
        </template>
      </v-dialog>
      <ChangeDetails
        v-model="currentAccountId"
        :changeDetailsDialog="changeDetailsDialog"
        :currentAccounts="dialog.dialogItem.currentAccounts || []"
        @close-dialog="changeDetailsDialog = false" />
      <h4 class="mb-3 cursor-default">Оберіть один або декілька пунктів</h4>
      <div
        v-for="point in visiblePoints"
        :key="point.id"
        class="point"
        :class="{ 'point-selected': isSelected(point) }"
        @click="() => addPoint(point)">
        <span class="point__title">
          <v-icon v-show="isSelected(point)" size="small" color="red">{{
            'mdi-check'
          }}</v-icon>
          <v-tooltip
            v-if="point.name && point.name.length > 80"
            right
            :text="point?.name">
            <template #activator="{ props }">
              <span v-bind="props">
                {{ point?.name }}
              </span>
            </template>
          </v-tooltip>
          <span v-else>{{ point?.name }} </span>
        </span>
        <v-btn
          v-show="isSelected(point)"
          size="x-small"
          icon
          @click.stop="deletePoint(point.id)">
          <v-icon small>{{ 'mdi-close' }}</v-icon>
        </v-btn>
      </div>
      <div v-if="remainingPoints.length > 0">
        <v-btn
          class="mt-2"
          size="small"
          color="#fc7247"
          variant="flat"
          @click="showMorePoints">
          Відобразити ще
        </v-btn>
      </div>
      <div
        v-if="!points.length"
        class="disabled text-center"
        style="font-size: 16px">
        Список порожній
      </div>
    </div>
  </div>
</template>

<script>
import { useSelect } from '@/utils/mixins/useSelect'
import { urlCreateExtraAgreementEditorShip } from '@/pages/request'
import SectionLoader from '@/components/section-loader.vue'
import { minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { fillDirectory, getDirectory } from '@/plugins/directory'
import ChangeDetails from '@/dialogs/components/editorship/components/ChangeDetails.vue'

export default {
  components: { ChangeDetails, SectionLoader },
  props: {
    dialog: { type: Object },
  },
  validations() {
    return {
      manualEditorship: this.addManualEditorship
        ? { required, minLength: minLength(1) }
        : {},
      currentAccountId: this.changeDetailsDialog ? { required } : {},
      selectedPoints: { required, minLength: minLength(1) },
    }
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  data() {
    return {
      selectedPoints: [],
      points: [],
      manualEditorship: null,
      currentAccountId: null,
      addManualEditorship: false,
      changeDetailsDialog: false,
      visibleCount: 10,
    }
  },
  computed: {
    validation() {
      return { v$: this.v$, name: 'editorship' }
    },
    visiblePoints() {
      return this.points
        .slice(0, this.visibleCount)
        .filter(
          point =>
            !this.dialog.dialogItem.exitedPoints.some(
              exited => exited.editorshipId === point.id
            )
        )
    },
    remainingPoints() {
      return this.points.slice(this.visibleCount)
    },
  },
  methods: {
    submit() {
      const editorships = this.selectedPoints.map(point => ({
        editorshipId: point.id,
        manualEditorship: point.id === 17 ? this.manualEditorship : null,
        currentAccountId: point.id === 18 ? this.currentAccountId : null,
      }))

      return this.$axios
        .post(urlCreateExtraAgreementEditorShip(), {
          extraAgreementId: +this.$route.params.id,
          editorships,
        })
        .then(res => {
          this.$setSnackbar({ text: 'Пункти договору оновлено' })
          this.$store.commit('refreshPage')
          return res
        })
    },
    addPoint(point) {
      if (this.isSelected(point)) return
      if (point && point.id === 17) {
        this.addManualEditorship = true
      }
      if (point && point.id === 18) {
        this.changeDetailsDialog = true
      }
      this.selectedPoints = [...this.selectedPoints, point]
    },
    deletePoint(pointId) {
      if (pointId === 17) {
        this.manualEditorship = null
        this.addManualEditorship = false
      }
      if (pointId === 18) {
        this.changeDetailsDialog = false
      }
      this.selectedPoints = this.selectedPoints.filter(q => q.id !== pointId)
    },
    isSelected(point) {
      return this.selectedPoints.map(q => q.id).includes(point.id)
    },
    showMorePoints() {
      this.visibleCount += 10
    },
    addManualEditorshipHandler() {
      if (!this.manualEditorship) {
        this.$setSnackbar({
          text: 'Пункт договору: ручне введення',
          color: 'error',
        })
      } else {
        this.addManualEditorship = false
      }
    },
  },
  watch: {},
  created() {
    fillDirectory('editorshipTypes').then(() => {
      this.points = getDirectory('editorshipTypes')
    })
  },
}
</script>

<style lang="scss" scoped>
.point {
  padding: 2px 5px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: 0.3s;
  border-bottom: 1px solid #838282;
  color: #838282;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__title {
    transition: 0.3s;
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &:not(.point-selected):hover {
    color: #000;
    background: #efefef;
    & .point__title {
      transform: translateX(4px);
    }
  }
  &-selected {
    color: #000;
    cursor: default;
  }
}
</style>
