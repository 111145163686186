/* To server */
const toInt = [
  'equipmentDiscount',
  'addEquipmentSum',
  'removalRegistration',
  'annualExpenses',
  'registration',
  'requestSource',
  'placeOfRegistration',
  'paymentPf',
  'individPaymentPf',
  'leasedAssertEngine',
  'leasingProgram',
  'advance',
  'leasingTerm',
  'leasingRest',
  'assistService',
  'vehicleOwnerTax',
  'gpsTrackerQuantity',
  'arto',
  'gpsTracker',
  'patrolCardsSupport',
  'leasingAmount',
  'leasingQuantity',
  'leasingCurrencyCourse',
  'leasingAmountDkp',
  'gainEvenGraphicMonths',
  'gainEvenGraphicPercent',
  'unsprMonth',
  'unpga',
  'unpga2Y',
  'unpga3Y',
  'preFinancingDays',
  'preFinancingAmount',
  'prenumerando',
  'holidays',
  'stock',
  'customGraphicType',
  'customUniversalOption',
  'customStepOptionFirst',
  'customStepOptionMiddle',
  'rateReduction',
  'rateManipulate',
  'commisionManipulate',
  'hiddenCommisionGr',
  'annualCommission',
  'hiddenCommision',
  'additionalAb',
  'additionalAbPr',
  'commissionLk',
  'commissionLkPr',
  'agentCommision',
  'insuranceProgram',
  'insuranceFranchise',
  'insuranceCascoManipulate',
  'insuranceTerm',
  'maintenanceRequest.scheduledMileage',
  'maintenanceRequest.additionalCosts',
]

const toDate = []

/* To client */
const toClientInt = [
  'addEquipmentSum',
  'registration',
  'annualExpenses',
  'removalRegistration',
  'advance',
  'leasingTerm',
  'leasingRest',
  'gpsTrackerQuantity',
  { name: 'leasingAmount', float: 3 },
  'leasingQuantity',
  { name: 'leasingAmountDkp', float: 3 },
  { name: 'leasingCurrencyCourse', float: 4 },
  'gainEvenGraphicMonths',
  'gainEvenGraphicPercent',
  'unsprMonth',
  'unpga',
  'unpga2Y',
  'unpga3Y',
  'preFinancingDays',
  'preFinancingAmount',
  'customUniversalOption',
  'customStepOptionFirst',
  'customStepOptionMiddle',
  'rateReduction',
  'infoDiscount',
  'rateManipulate',
  'commisionManipulate',
  'hiddenCommisionGr',
  'annualCommission',
  'hiddenCommision',
  'additionalAb',
  'additionalAbPr',
  'commissionLk',
  'commissionLkPr',
  'agentCommision',
  'insuranceCascoManipulate',
  'maintenanceRequest.scheduledMileage',
  'maintenanceRequest.additionalCosts',
]

export { toInt, toDate, toClientInt }
