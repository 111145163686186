<template>
  <div>
    <div>
      <div class="label">Назва заявки</div>
      <div class="mb-4">{{ application.applicationName }}</div>
    </div>
    <div>
      <div class="label">Потреба клієнта</div>
      <div class="mb-4">{{ application.clientNeed?.name || 'Не вказано' }}</div>
    </div>
    <div v-if="application.sourceTypeId === 2">
      <div class="label">Джерело</div>
      <div class="mb-4">
        {{ application.source?.name || 'Не вказано' }}
      </div>
    </div>
    <div v-else>
      <div class="label">Джерело</div>
      <div class="mb-4">
        <span>{{ application.sourceType?.name }}</span>
        <span v-if="application.sourceTypeId === 1">
          (
          <a-link
            class="font-weight-bold"
            :text="application.agent?.contact?.fullName"
            :route="{
              name: 'agent-form',
              params: { id: application.agent.id },
            }">
          </a-link>
          )
        </span>
        <span v-if="application.sourceTypeId === 9">
          (
          <a-link
            class="font-weight-bold"
            :text="application.dealerPoint?.name"
            :route="{
              name: 'dealer-form',
              params: { id: application.dealerPointId },
            }">
          </a-link>
          )
        </span>
      </div>
    </div>
    <div v-if="application.agent?.dealerPoint">
      <div class="label">Торгова точка</div>
      <div class="mb-4">
        {{ application.agent?.dealerPoint.name || 'Не вказано' }}
      </div>
    </div>
    <change-responsible
      v-model:responsibleId="application.responsibleId"
      :read-only="application.readOnly"
      :responsible="application.responsible" />
    <div>
      <div class="label">Дата створення заявки</div>
      <div v-if="application.created_at">
        {{
          new CustomDate(
            application.created_at.slice(0, 19).replace('T', ' ')
          ).toString({ time: true })
        }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, inject } from 'vue'
import { applicationKey, updateApplicationObjectKey } from '../injectionKeys'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { Application } from '@/utils/types/application'
import ALink from '@/components/Link.vue'
import ChangeResponsible from '@/components/ChangeResponsible.vue'
import { CustomDate } from 'best-modules/utils'

export default {
  name: 'SidebarInfo',
  components: { ChangeResponsible, ALink },
  setup() {
    const updateApplicationObject = inject(updateApplicationObjectKey)
    const application = computed<CachedObjectProxy<Application>>({
      get: () => inject(applicationKey),
      set: updateApplicationObject,
    })

    return { application }
  },
  methods: { CustomDate },
}
</script>

<style scoped></style>
