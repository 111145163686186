<template>
  <div
    style="background: #fff; border-radius: 4px;     padding-left: 10px;
    padding-top: 5px;
}">
    <list-section title="Зв`язки">
      <template #body>
        <v-row v-if="agentData.contact.contractor">
          <v-col :cols="12" :sm="8" class="pt-1 pb-2">
            <div class="d-flex" style="flex-direction: column">
              <span class="area-label d-block mb-2">Контрагент</span>
              <div class="d-flex align-center">
                <LinkIcon class="mr-1" />
                <ALink
                  style="font-size: 14px !important"
                  :route="{
                    name: 'contractors-form',
                    params: { id: agentData.contact.contractor.id },
                  }"
                  :text="agentData.contact.contractor.shortName">
                </ALink>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="agentData.dealerPoint"
            :cols="12"
            :sm="12"
            class="pb-0 pr-5 pt-0">
            <span class="area-label">Дилерська точка</span>
            <div class="d-flex align-center">
              <LinkIcon class="mr-1" />
              <ALink
                style="font-size: 14px !important"
                :route="{
                  name: 'dealer-form',
                  params: { id: agentData.dealerPoint.id },
                }"
                :text="agentData.dealerPoint.name">
              </ALink>
            </div>
          </v-col>
        </v-row>
      </template>
    </list-section>
    <list-section
      v-if="agentData.contact && agentData.contact.careers"
      title="Місце роботи">
      <template #body>
        <v-data-table
          :headers="tHeadCareers"
          :items="
            getSafe(() => agentData.contact.careers.filter(c => !!c.isMain))
          "
          hide-default-footer
          class="pb-2 pt-1 custom-table head-line"
          dense>
          <!-- eslint-disable-next-line -->
          <template #item.contractor="{ item }">
            <ALink
              v-if="item.contractor"
              :route="{
                name: 'contractors-form',
                params: { id: item.contractor.id },
              }"
              :text="item.contractor.shortName">
            </ALink>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.startDate="{ item }">
            {{ item.startDate }}
          </template>
        </v-data-table>
      </template>
    </list-section>
    <list-section
      v-if="getSafe(() => agentData.contact.identityDocument.typeId, false)"
      :title="getPassport(agentData.contact, { label: true })">
      <template #body>
        <div
          class="pt-2"
          style="color: rgba(0, 0, 0, 0.87); font-size: 0.77rem">
          {{ getPassport(agentData.contact) }}
        </div>
      </template>
    </list-section>
    <list-section title="Контактна інформація">
      <template #body>
        <div
          v-if="getSafe(() => agentData.contact.addresses)"
          style="font-size: 0.82rem">
          Адреса:
        </div>
        <v-data-table
          v-if="getSafe(() => agentData.contact.addresses)"
          :headers="tHeadAddresses"
          :items="getSafe(() => agentData.contact.addresses)"
          hide-default-footer
          hide-default-header
          class="pb-2 pt-1 custom-table head-line"
          dense>
          <!-- eslint-disable-next-line -->
          <template #item.item="{ item }">
            {{ getAddress(item) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.type.name="{ item }">
            <span style="color: dimgrey">
              {{ item.type.name }}
            </span>
          </template>
        </v-data-table>
        <v-row class="pl-1">
          <v-col
            v-if="getSafe(() => agentData.contact.phones.length)"
            :cols="12"
            :sm="6">
            <div style="font-size: 0.82rem">Телефон:</div>
            <div
              v-for="(p, key) in agentData.contact.phones"
              :key="key"
              style="color: rgba(0, 0, 0, 0.87); font-size: 0.77rem">
              {{ p.phone }}
            </div>
          </v-col>
          <v-col
            v-if="getSafe(() => agentData.contact.emails.length)"
            :cols="12"
            :sm="6">
            <div style="font-size: 0.82rem">Email:</div>
            <div
              v-for="(e, key) in agentData.contact.emails"
              :key="key"
              style="color: rgba(0, 0, 0, 0.87); font-size: 0.77rem">
              {{ e.email }}
            </div>
          </v-col>
        </v-row>
      </template>
    </list-section>
    <list-section title="Банківські реквізити">
      <template #body>
        <v-data-table
          v-if="getSafe(() => agentData.contact.current_accounts.length)"
          :headers="tHeadBankAccounts"
          :items="getSafe(() => agentData.contact.current_accounts, [])"
          hide-default-footer
          :hide-default-header="
            getSafe(() => !agentData.contact.current_accounts.length)
          "
          class="pb-2 pt-1 custom-table head-line"
          dense>
          <!-- eslint-disable-next-line -->
          <template #no-data>
            <NoTableData />
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.currentAccount="{ item }">
            <span>
              {{
                item.isBasic
                  ? item.type && item.type.name
                    ? item.type.name + ' (Основний)'
                    : '---'
                  : item.type && item.type.name
                  ? item.type.name
                  : '---'
              }}
            </span>
          </template>
        </v-data-table>
      </template>
    </list-section>
  </div>
</template>
<script>
import LinkIcon from '@/assets/svg/link-icon.vue'
import ALink from '@/components/Link.vue'
import listSection from '@/components/list-section.vue'
import { phoneMask } from '@/utils/masks'
import {
  isEmail,
  contactFilter,
  getBeautyNum,
  getSafe,
} from '@/utils/helperFunc'
import { mapState } from 'vuex'
import { getPassport, getAddress } from '@/utils/helperFunc'
import { useSelect } from '@/utils/mixins/useSelect'
import NoTableData from '@/components/no-table-data.vue'
import { urlGetDealerOutlets } from '@/pages/request'

export default {
  components: {
    NoTableData,
    listSection,
    ALink,
    LinkIcon,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    v: { type: Object },
    agent: { type: Object },
    pageLoad: { type: Boolean },
    readOnly: { type: Boolean },
  },
  data: () => ({
    phone: null,
    email: null,
    phonesClone: [],
    emailsClone: [],
    tHeadCareers: [
      { text: 'Компанія', key: 'contractor', sortable: false },
      { text: 'Посада', key: 'post.name', sortable: false },
      { text: 'Департамент', key: 'department.name', sortable: false },
      { text: 'Роль', key: 'role.name', sortable: false },
      {
        text: 'Дата початку',
        key: 'startDate',
        sortable: false,
        align: 'center',
      },
    ],
    tHeadAddresses: [
      { text: 'Тип', key: 'type.name', sortable: false },
      { text: 'Адреса', key: 'item', sortable: false, align: 'center' },
    ],
    tHeadBankAccounts: [
      { text: 'МФО', key: 'mfo', sortable: false },
      { text: 'Банк', key: 'bankName', sortable: false },
      { text: '№ рахунку', key: 'accountNumber', sortable: false },
      { text: 'Валюта', key: 'currency.name', sortable: false },
      { text: 'Тип р/р', key: 'currentAccount', sortable: false },
    ],
  }),
  computed: {
    $v() {
      return this.v
    },
    agentData() {
      return this.agent
    },
    ...mapState({ selectItems: state => state.selectItems }),
    phoneValid() {
      return !!(this.phone && this.phone.length === 23)
    },
    agentPostId() {
      const career = (this.agent.contact?.careers || []).find(
        career => career.isMain
      )
      return career ? career.postId : null
    },
  },
  methods: {
    urlGetDealerOutlets,
    getSafe,
    getBeautyNum,
    isEmail,
    phoneMask,
    getAddress,
    getPassport,
    contactFilter,
  },
}
</script>
