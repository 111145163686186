<template>
  <v-row>
    <v-col :cols="12" :md="12">
      <span class="area-label">Сімейний статус</span>
      <!-- TODO contactData.familystatus rename to contactData.familyStatus -->
      <v-select
        v-model="localData.familyStatusId"
        :items="$directory.get('familyStatuses', localData.familystatus)"
        :readonly="readonly"
        hide-details
        placeholder="Оберіть"
        item-title="name"
        item-value="id"
        @update:model-value="
          event => {
            if (localData.familyStatusId === 2) {
              localData.marriedId = null
            }
          }
        "
        @focus="$directory.fill('familyStatuses')">
        <template #append-item>
          <loader v-if="$loading.isLoading('familyStatuses')"></loader>
        </template>
      </v-select>
    </v-col>
    <v-col :cols="12" :md="12" class="pt-0">
      <span v-if="localData.familyStatusId !== 2" class="area-label">
        {{
          localData.familyStatusId !== null
            ? localData.familyStatusId === 1
              ? 'Дружина'
              : 'Чоловік'
            : ''
        }}
      </span>
      <b-server-autocomplete
        v-if="localData.familyStatusId === 1"
        v-model="localData.marriedId"
        :url="urlContactsGetAll()"
        :readonly="readonly"
        :default-item="localData.marriedTo"
        item-value="id"
        hide-details
        item-title="fullName"
        placeholder="Введіть текст (мін. 3 символи)"
        :filter="contactFilter"
        :required="localData.familyStatusId === 1"
        dense>
        <template #prepend-inner>
          <v-scroll-x-transition hide-on-leave class="d-none">
            <InputPusher
              v-if="localData.marriedId"
              size="small"
              :routeObject="{
                name: 'individuals-form',
                params: { id: localData.marriedId },
              }"
              text="Перейти до контакту">
            </InputPusher>
          </v-scroll-x-transition>
        </template>
      </b-server-autocomplete>
    </v-col>
    <v-col
      v-if="hasChanges"
      :cols="12"
      :md="12"
      class="pt-0 d-flex justify-end">
      <v-btn variant="tonal" size="small" @click="changeFamily">
        <v-icon class="mr-1">{{ 'mdi-check' }}</v-icon>
        Підтвердити
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import InputPusher from '@/components/InputPusher.vue'
import Loader from '@/components/Loader.vue'
import { computed, ref, watch } from 'vue'
import { urlContactsGetAll } from '@/pages/request'
import { contactFilter } from '@/utils/helperFunc'
import { useSelect } from '@/utils/mixins/useSelect'
import { BServerAutocomplete } from 'best-modules/components/index'

export default {
  emits: ['change-family'],
  components: { InputPusher, Loader, BServerAutocomplete },
  props: {
    data: { type: Object, required: true },
    readonly: { type: Boolean },
    v$: { type: Object },
  },
  methods: { contactFilter, urlContactsGetAll },
  setup(props, { emit }) {
    const localData = ref({ ...props.data })

    const hasChanges = computed(() => {
      return JSON.stringify(localData.value) !== JSON.stringify(props.data)
    })

    const changeFamily = () => {
      return emit('change-family', localData.value)
    }

    watch(
      () => props.data,
      newValue => {
        localData.value = { ...newValue }
      }
    )

    return {
      ...useSelect(),
      hasChanges,
      localData,
      changeFamily,
    }
  },
}
</script>

<style scoped></style>
