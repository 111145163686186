<template>
  <v-tooltip bottom text="Завантажити всі документи">
    <template #activator="{ props }">
      <v-btn
        data-readonly="hide"
        icon
        density="comfortable"
        v-bind="props"
        @click="downloadAllDocs">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill="none">
          <path
            d="M5.41659 13.6666C4.1527 13.6666 3.07297 13.2291 2.17742 12.3541C1.28131 11.4791 0.833252 10.4097 0.833252 9.14581C0.833252 8.06248 1.15964 7.0972 1.81242 6.24998C2.4652 5.40276 3.31936 4.86109 4.37492 4.62498C4.69436 3.49998 5.28825 2.55554 6.15659 1.79165C7.02436 1.02776 8.0277 0.56248 9.16659 0.395813V7.12498L8.41659 6.39581C8.26381 6.24304 8.07297 6.16665 7.84409 6.16665C7.61464 6.16665 7.41659 6.24998 7.24992 6.41665C7.09714 6.56942 7.02075 6.76387 7.02075 6.99998C7.02075 7.23609 7.09714 7.43054 7.24992 7.58331L9.41659 9.74998C9.58325 9.91665 9.7777 9.99998 9.99992 9.99998C10.2221 9.99998 10.4166 9.91665 10.5833 9.74998L12.7499 7.58331C12.9027 7.43054 12.9827 7.23942 12.9899 7.00998C12.9966 6.78109 12.9166 6.58331 12.7499 6.41665C12.5971 6.26387 12.4063 6.18387 12.1774 6.17665C11.948 6.16998 11.7499 6.24304 11.5833 6.39581L10.8333 7.12498V0.395813C12.2638 0.590257 13.4549 1.23248 14.4066 2.32248C15.3577 3.41304 15.8333 4.69442 15.8333 6.16665C16.7916 6.27776 17.5869 6.69081 18.2191 7.40581C18.8508 8.12137 19.1666 8.95831 19.1666 9.91665C19.1666 10.9583 18.8021 11.8439 18.0733 12.5733C17.3438 13.3022 16.4583 13.6666 15.4166 13.6666H5.41659Z"
            fill="#FC7247" />
        </svg>
      </v-btn>
    </template>
  </v-tooltip>
</template>

<script>
import {
  urlGetContactLegalDocuments,
  urlGetContactFinDocuments,
  urlGetContractorLegalDocuments,
  urlGetContractorFinDocuments,
  urlAllProjectDocuments,
  urlAllClientContactLegalDocuments,
  urlAllClientContactFinDocuments,
  urlAllClientContractorLegalDocuments,
  urlAllClientContractorFinDocuments,
} from '@/pages/request'
import { download } from '@/utils/helperFunc'

export default {
  props: {
    project: { type: Boolean, default: false },
    legal: { type: Boolean, default: false },
    fin: { type: Boolean, default: false },
    contact: { type: Boolean, default: false },
    contractor: { type: Boolean, default: false },
    id: { type: Number },
  },
  methods: {
    urlGetContactLegalDocuments,
    urlGetContactFinDocuments,
    urlGetContractorLegalDocuments,
    urlGetContractorFinDocuments,
    urlAllProjectDocuments,
    urlAllClientContactLegalDocuments,
    urlAllClientContactFinDocuments,
    urlAllClientContractorLegalDocuments,
    urlAllClientContractorFinDocuments,
    download,
    async downloadAllDocs() {
      const { url } = await this.getDocsUrl()
      if (!url) return

      console.log('url', url)

      const fileName = url.split('/')[2]
      this.download(url, fileName)
    },
    getDocsUrl() {
      let url = () => false

      switch (true) {
        case !this.contact &&
          !this.contractor &&
          !this.legal &&
          !this.fin &&
          this.project:
          url = this.urlAllProjectDocuments
          break
        case this.contact && this.legal && this.project:
          url = this.urlAllClientContactLegalDocuments
          break
        case this.contact && this.fin && this.project:
          url = this.urlAllClientContactFinDocuments
          break
        case this.contractor && this.legal && this.project:
          url = this.urlAllClientContractorLegalDocuments
          break
        case this.contractor && this.fin && this.project:
          url = this.urlAllClientContractorFinDocuments
          break
        case this.contact && this.legal && !this.project:
          url = this.urlGetContactLegalDocuments
          break
        case this.contact && this.fin && !this.project:
          url = this.urlGetContactFinDocuments
          break
        case this.contractor && this.legal && !this.project:
          url = this.urlGetContractorLegalDocuments
          break
        case this.contractor && this.fin && !this.project:
          url = this.urlGetContractorFinDocuments
          break
      }

      return this.$axios.get(url(this.id)).then(res => {
        if (res.data.message) {
          this.$setSnackbar({ text: res.data.message, color: 'warning' })
        }
        if (res.data.error) {
          this.$setSnackbar({
            text: 'Помилка при створенні архіву, спробуйте ще раз',
            color: 'error',
          })
        }

        return res.data.url ? res.data : { url: null }
      })
    },
  },
}
</script>

<style scoped></style>
