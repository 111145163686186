import { defineDirectory } from 'best-modules/plugins/'
import { DirectoryName, GetterName } from './types'
import { yesOrNo, klpReworkTypes, meetStatuses } from './local'
import { filterUsers } from '@/utils/helperFunc.js'

const {
  DirectoryPlugin,
  fillDirectory,
  getDirectory,
  pushDirectoryItem,
  updateDirectoryItem,
  removeDirectoryItem,
  clearDirectory,
} = (() => {
  return defineDirectory<DirectoryName, GetterName>({
    carForSaleStatuses: '/json/list/carForSaleStatus', // done
    carForSaleList: '/json/carForSale/list', // done
    calcTargets: '/json/list/target', // done
    calcWorkplace: '/json/list/workplace', // done
    planTypes: '/json/list/planType', // done
    planCategories: '/json/list/carCategory', // TODO mayme not used
    planLeasingPrograms: '/json/list/leasingProgram', // TODO mayme not used
    planSourceTypes: '/json/list/getSources', // TODO mayme not used
    planActivityTypes: '/json/list/activityType', // done
    planValueTypes: '/json/list/planValueType', // done
    apartmentTypes: '/json/list/apartmentType', // done
    contactTypes: '/json/list/contactType', // done
    gpsBeacons: '/json/list/gpsBeaconType', // done
    contractorTypes: '/json/list/contractorType', // done
    nationalityList: '/json/list/nationality', // done
    segmentTypes: '/json/list/segment', // done
    influenceList: '/json/list/typeInfluence', // done
    signKbvList: '/json/list/signCBV', // done
    genderList: '/json/list/gender', // done
    careerPosts: '/json/list/contactCareerPost', // done
    careerDepartments: '/json/list/contactCareerDepartment', // done
    careerRoles: '/json/list/contactCareerRole', // done
    passportTypes: '/json/list/identityDocumentType', // done
    currencies: '/json/list/currency', // done
    contractorAddressTypes: '/json/list/getContractorAddressTypes', // done
    contactAddressTypes: '/json/list/getContactAddressTypes', // done
    fopAddressTypes: '/json/list/getFopAddressTypes',
    clientCategories: '/json/list/clientCategory', // done
    educationTypes: '/json/list/contactEducationType', // done
    bankAccountTypes: '/json/list/currentAccountType', // done
    analysisTypes: '/json/list/analysisType', // done
    dkpTypes: '/json/list/dkpType', // TODO maybe not used
    dkpOrderCalculations: '/json/list/dkpOrderCalculation', // done
    nbuCurrencyCourses: '/json/getRate', // done
    financialRiskLevels: '/json/list/financialRiskLevel', // done
    activityStatuses: '/json/list/activityStatus', // TODO the latest via axios usages
    projectStatuses: '/json/list/projectStatus', // done
    applicationStatuses: '/json/list/applicationStatus', // done
    securityStatuses: '/json/list/serviceStatus', // done
    lesseeTypes: '/json/list/lesseeType', // done
    clientNeeds: '/json/list/applicationClientNeed', // done
    contactStatuses: '/json/list/clientStatus', // done
    dflStatuses: '/json/list/contractStatus', // done
    serviceContractStatuses: '/json/list/serviceContractStatus', // TODO the latest in filters
    dkpStatuses: '/json/list/dkpStatus', // done
    gcStatuses: '/json/list/pledgeContractStatus', // done TODO maybe rename
    guaranteeStatuses: '/json/list/guaranteeContractStatus', // done TODO maybe rename
    bookKeepingStatus: '/json/list/agentPaymentStatus', // done
    businessRegions: '/json/list/businessRegion', // done TODO maybe rename
    pledgeTypes: '/json/list/pledgeContractType', // done TODO maybe rename
    applicationComunicationMethods: '/json/list/applicationCommunicationMethod', // done TODO rename
    companyGroupMemberRole: '/json/list/companyGroupMemberRole',
    companyGroupList: '/json/contractor/companyGroup',
    allUsers: {
      url: '/json/list/userList',
      getters: {
        activeUsers: items => {
          return filterUsers(items as unknown as any[])
        },
        leadAccessUsers: items => {
          return filterUsers(items as unknown as any[]).filter(
            u => u.leadAccess
          )
        },
      },
    }, // TODO need to check all cases
    leasingPrograms: '/json/list/leasingProgram', // done
    executiveAuthorities: '/json/list/executiveAuthority', // done
    contractorDirectorPosts: '/json/list/contractorDirectorPost', // done TODO maybe rename
    klpTypes: '/json/list/klpType', // done TODO maybe rename
    departments: '/json/department/getAll', // done
    allUserRoles: '/json/list/role', // done TODO maybe rename
    salesPlanTypes: '/json/list/planSalesType', // done
    loyaltyPrograms: '/json/list/dealerLoyalty', // done
    dealerPointDirections: '/json/list/dealerPointOfSaleMainDirection', // done
    dealerTypes: '/json/list/dealerPointOfSaleType', // done
    otherLk: '/json/list/otherLeasingCompany', // done TODO maybe rename
    directoryTableNames: '/json/history/directory/getTables', // done
    ticketThemes: '/json/list/supportTheme', // done
    ticketStatuses: '/json/list/supportStatus', // done TODO maybe not used
    clientStatuses: '/json/list/clientStatus', // done
    leasingObjectStatuses: '/json/list/leasingObjectStatus', // done
    dealerCategories: '/json/list/dealerPointOfSaleCategory', // done
    applicationRejectStatuses: '/json/list/getApplicationRejections', // done
    ownershipsList: '/json/list/formOwnershipCompany', // done
    familyStatuses: '/json/list/familyStatus', // done
    projectRefusalStatuses: '/json/list/getProjectRejections', // done
    callTypes: '/json/list/typeCall', // done
    callStatus: '/json/list/statusCall', // done
    callTelephony: '/json/list/telephony', // done
    logsTableName: '/json/getTables', // done
    logsType: '/json/list/logActionType', // done
    campaignTypes: '/json/list/advertisingCampaignType', // done
    klpSolutions: '/json/list/klpSolution', // done
    serviceApplicationStatuses: '/json/list/serviceApplicationStatus', // done
    serviceApplicationTypes: '/json/list/serviceApplicationType', // done
    leadStatuses: '/json/list/leadStatus', // done
    insurancePrograms: '/json/list/insuranceProgramType', // done
    promotions: '/json/list/stockType', // done TODO maybe rename
    replacementCarClasses: '/json/list/replacementCarClass', // done TODO maybe rename
    insuranceFranchise: {
      url: '/json/list/insuranceFranchiseType',
      indexedKey: 'value',
    }, // done
    placeOfRegistration: '/json/list/placeOfRegistrationType', // done TODO maybe rename
    servicePackages: '/json/list/servicePackage', // done
    chartTypes: {
      url: '/json/list/graphType',
      indexedKey: 'value',
    }, // done
    dpRegions: '/json/list/dpRegion', // TODO
    gpsTrackerSelect: '/json/list/gpsTrackerType', // done `TODO maybe rename
    months: '/json/list/month', // done TODO maybe rename
    clientTypesFSN: '/json/list/clientFSNType', // done TODO maybe rename
    customGraphicTypes: '/json/list/customGraphicType', // done TODO maybe rename
    basisDocuments: '/json/list/basisDocument', // done TODO maybe rename
    yesOrNo: { items: yesOrNo }, // done
    klpReworkTypes: { items: klpReworkTypes }, // done TODO maybe move to backend
    meetStatuses: { items: meetStatuses }, // done TODO maybe rename
    countryList: '/json/list/country', // done TODO maybe rename
    employeePosts: '/json/list/employeePost', // done
    employeeRoles: '/json/list/employeeRole', // done
    employeeDepartments: '/json/list/employeeDepartment', // done
    applicationSourceTypes: '/json/list/getSources', // done
    applicationOnlineSourceTypes: '/json/list/getSources?source=2', // done
    signingTypes: '/json/list/signingType', // done
    averagePriceStates: '/json/averagePrice/list/states', // done
    averagePriceFuels: '/json/averagePrice/list/fuels', // done
    quedList: '/json/list/qued', // done
    editorshipTypes: '/json/list/extraAgreementEditorship', // done
    dpStatuses: '/json/list/dealerPointOfSaleStatus', // TODO
    eventResultStatuses: '/json/list/activityResult', // TODO
  })
})()

export default DirectoryPlugin

export {
  fillDirectory,
  getDirectory,
  pushDirectoryItem,
  updateDirectoryItem,
  removeDirectoryItem,
  clearDirectory,
}
