<template>
  <v-dialog v-model="dialog" max-width="450" persistent>
    <template #default="{ isActive }">
      <v-card title="Зміна реквізитів" class="cursor-default">
        <v-card-text>
          <v-autocomplete
            v-model="changeObj.currentAccountId"
            hide-details
            placeholder="Оберіть зі списку"
            :item-title="formatAccount"
            item-value="id"
            :error-messages="currentAccountIdErr"
            :items="currentAccounts || []"
            @blur="v$.currentAccountId.$touch()">
          </v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text="Зберегти"
            variant="flat"
            color="rgb(8, 72, 122)"
            @click="submit"></v-btn>
        </v-card-actions>
        <template #append>
          <v-btn icon size="small" variant="text" @click="closeDialog">
            <v-icon>{{ 'mdi-close' }}</v-icon>
          </v-btn>
        </template>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { computed, reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'

export default {
  emits: ['closeDialog', 'update:modelValue'],
  props: {
    currentAccounts: { type: Array },
    changeDetailsDialog: { type: Boolean },
    modelValue: { type: Number },
  },
  setup(props, { emit }) {
    const dialog = computed({
      get: () => props.changeDetailsDialog,
      set: value => {
        emit('closeDialog', value)
      },
    })

    const changeObj = reactive({
      currentAccountId: null,
    })

    const rules = {
      currentAccountId: {
        required: requiredIf(() => !!props.changeDetailsDialog),
      },
    }
    const v$ = useVuelidate(rules, changeObj)
    const currentAccountIdErr = computed(() => {
      return setErrHandler(v$.value.currentAccountId)
    })

    const formatAccount = item => {
      return `${item.bankName} (${item.accountNumber})`
    }

    const closeDialog = () => {
      dialog.value = false
    }

    const submit = () => {
      v$.value.$validate()
      if (v$.value.$error) {
        return
      }

      emit('update:modelValue', changeObj.currentAccountId)

      closeDialog()
    }

    return {
      currentAccountIdErr,
      formatAccount,
      closeDialog,
      changeObj,
      submit,
      dialog,
      v$,
    }
  },
}
</script>

<style scoped></style>
