<template>
  <div>
    <div>
      <div class="label">Назва заявки</div>
      <v-text-field
        v-model="application.applicationName"
        class="mt-1"
        placeholder="Вкажіть назву заявки"
        dense>
      </v-text-field>
    </div>
    <div>
      <div class="label">Потреба клієнта</div>
      <v-select
        v-model="application.clientNeedId"
        :items="$directory.get('clientNeeds') as any[]"
        :loading="$loading.isLoading('clientNeeds')"
        item-title="name"
        item-value="id"
        class="mt-1"
        placeholder="оберіть опцію"
        dense>
      </v-select>
    </div>
    <div>
      <div class="label">Тип джерела</div>
      <v-select
        v-model="application.sourceTypeId"
        :items="$directory.get('applicationSourceTypes') as any[]"
        :loading="$loading.isLoading('applicationSourceTypes')"
        item-title="name"
        item-value="id"
        class="mt-1"
        placeholder="оберіть опцію"
        dense>
      </v-select>
    </div>
    <div v-if="application.sourceTypeId === 1">
      <div>
        <span class="label">Агент</span>
        <b-server-autocomplete
          v-model="application.agentId"
          :url="urlGetAllAgents()"
          :search-min-length="3"
          item-title="fullName"
          item-value="id"
          placeholder="Введіть текст (мін. 3 символи)"
          dense>
        </b-server-autocomplete>
      </div>
    </div>
    <div v-if="application.sourceTypeId === 9">
      <div>
        <span class="label">Торгова точка</span>
        <b-server-autocomplete
          v-model="application.dealerPointId"
          :url="urlGetDealerOutlets()"
          :search-min-length="3"
          item-title="name"
          item-value="id"
          placeholder="Введіть текст (мін. 3 символи)"
          dense>
        </b-server-autocomplete>
      </div>
    </div>
    <div v-if="application.sourceTypeId === 2">
      <div class="label">Джерело</div>
      <v-select
        v-model="application.sourceId"
        :items="$directory.get('applicationOnlineSourceTypes') as any[]"
        :loading="$loading.isLoading('applicationOnlineSourceTypes')"
        item-title="name"
        item-value="id"
        class="mt-1"
        placeholder="оберіть опцію"
        dense
        @focus="$directory.fill('applicationOnlineSourceTypes')">
      </v-select>
    </div>
    <ChangeResponsible
      v-model:responsibleId="application.responsibleId"
      :responsible="application.responsible" />
  </div>
</template>

<script lang="ts">
import { computed, inject } from 'vue'
import { applicationKey, updateApplicationObjectKey } from '../injectionKeys'
import { Application } from '@/utils/types/application'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { urlGetAllAgents, urlGetDealerOutlets } from '@/pages/request'
import ChangeResponsible from '@/components/ChangeResponsible.vue'
import { BServerAutocomplete } from 'best-modules/components'

export default {
  name: 'SidebarForm',
  components: { ChangeResponsible, BServerAutocomplete },
  setup() {
    const updateApplicationObject = inject(updateApplicationObjectKey)
    const application = computed<CachedObjectProxy<Application>>({
      get: () => inject(applicationKey),
      set: updateApplicationObject,
    })

    return { application }
  },
  methods: { urlGetDealerOutlets, urlGetAllAgents },
}
</script>

<style scoped></style>
