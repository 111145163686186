<template>
  <v-app :data-readonly-wrapper="user.roleId === 13">
    <b-section-loader :loading="$loading.isLoading('getUserData')">
      <div
        v-if="showNewYearBanner"
        class="top-0 left-0 position-absolute w-100 h-100">
        <NewYearBanner @close-banner="handleCloseBanner" />
      </div>
      <div :class="{ glass: showNewYearBanner }">
        <div class="z-50">
          <b-snackbar></b-snackbar>
          <b-confirm></b-confirm>
        </div>
        <router-view v-if="showTree"></router-view>
      </div>
    </b-section-loader>
  </v-app>
</template>

<script>
import { getAddressApiToken } from '@/utils/addressApi.ts'
import { urlGetUser } from '@/pages/request.js'
import { mapState } from 'vuex'
import { BSnackbar, handleAsync, BConfirm } from 'best-modules/plugins'
import { BSectionLoader } from 'best-modules/components'
import NewYearBanner from '@/components/newYear/new-year-banner.vue'

export default {
  components: { NewYearBanner, BSnackbar, BSectionLoader, BConfirm },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    showNewYearBanner() {
      return this.$store.state.auth.newYearBanner === true
    },
    verificationRoutes() {
      const authRoutes = [
        'login',
        'authLayout',
        'initUser',
        'recovery-password',
        'auth-confirm',
      ]
      return authRoutes.includes(this.$route.name)
    },
    showTree() {
      return this.$store.state.user.id || this.verificationRoutes
    },
  },
  methods: {
    urlGetUser,
    getUserData() {
      return handleAsync('getUserData', () => {
        return this.$axios
          .get(this.urlGetUser())
          .then(res => {
            if (
              Object.keys(res.data).length === 0 &&
              this.$route.name !== 'authLayout'
            ) {
              this.$router.push({ name: 'authLayout' })
            } else {
              this.$store.commit('setUser', res.data)
              if (this.$route.matched[0].name === 'initUser') {
                this.$router.push({ name: 'crm' })
              }
            }
          })
          .catch(err => {
            if (err && err.response && err.response.status !== 401) {
              this.$setSnackbar({ text: err, color: 'error' })
            }
            throw err
          })
      })
    },
    async initializeTokenAndUser() {
      try {
        // const res = await getToken(user, password)
        await getAddressApiToken()
      } catch (error) {
        console.error('Error fetching token:', error)
      }

      if (this.$store.state.auth.token) {
        this.getUserData()
      } else {
        this.$router.push({ name: 'authLayout' })
      }
    },
    handleCloseBanner() {
      this.$store.commit('setNewYearBanner', false)
      localStorage.setItem('newYearBanner', 'false')
    },
  },
  created() {
    /* eslint-disable */
    this.initializeTokenAndUser()
  },
  watch: {
    '$store.state.auth.token': function (newToken) {
      if (newToken) {
        this.getUserData()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.glass {
  background: white;
  filter: blur(25px);
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
</style>
