import { DefaultItem } from 'best-modules/plugins/directory/types'
import { getDirectory } from '@/plugins/directory'
import { Address } from '@/utils/types'

const removeExistAddressTypes = (
  addressTypes: DefaultItem[],
  existAddresses: Array<Address>
) => {
  const addressesIds = existAddresses.map(a => a.type.id)
  return addressTypes.filter(addressType => {
    return !addressesIds.includes(addressType.id) || addressType.id === 3
  })
}

const getAddressTypes = (entityTypeId: number, fop: boolean): DefaultItem[] => {
  if (entityTypeId === 1 && fop) {
    return getDirectory('fopAddressTypes') as DefaultItem[]
  }

  switch (entityTypeId) {
    case 1:
      return getDirectory('contactAddressTypes') as DefaultItem[]
    case 2:
      return getDirectory('contractorAddressTypes') as DefaultItem[]
    case 5:
      return getDirectory('contractorAddressTypes').filter(
        a => a.id === 2
      ) as DefaultItem[]
    default:
      return []
  }
}

const getAddressPartName = (
  name?: string,
  oldName?: string,
  useOld?: boolean
) => {
  if (!name) {
    return ''
  }
  if (oldName && name !== oldName && useOld) {
    return oldName
  }

  return name
}

export { getAddressTypes, removeExistAddressTypes, getAddressPartName }
