<template>
  <div>
    <div class="mb-5">
      <div class="text-disabled">Від кого:</div>
      <div class="font-weight-bold text-indigo-darken-4">
        {{ user.email }}
      </div>
    </div>

    <v-select
      v-model="offer.emails"
      :items="emails"
      :item-title="e => `${e.email} (${e.owner})`"
      item-value="email"
      item-props
      class="mb-3"
      multiple
      dense
      placeholder="Оберіть одержувача"
      hide-details
      chips
      :error-messages="getErrorMessages(v$.emails)"
      @blur="v$.emails.$touch()">
      <template #prepend>
        <span class="text-disabled">Кому:</span>
      </template>
    </v-select>
    <v-text-field
      v-model="offer.messageSubject"
      class="mb-3"
      dense
      placeholder="Введіть тему повідомлення"
      hide-details
      :error-messages="getErrorMessages(v$.messageSubject)"
      @blur="v$.messageSubject.$touch()">
      <template #prepend>
        <span class="text-disabled">Тема:</span>
      </template>
    </v-text-field>

    <div class="mb-3">
      <editor
        :id="TINYMCE_EDITOR_ID"
        v-model="offer.messageText"
        :api-key="TINYMCE_KEY"
        :init="editorConfig(TINYMCE_EDITOR_ID, userSigns)" />
    </div>

    <v-file-input
      accept="*"
      class="mb-5"
      label="Файли до листа"
      dense
      hide-details
      small-chips
      multiple
      @update:model-value="uploadExtraFiles($event)">
    </v-file-input>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { computed, PropType, ref } from 'vue'
import { OfferCredentials } from '@/utils/types/application'
import Editor from '@tinymce/tinymce-vue'
import { editorConfig } from '@/utils/tinymce-config.js'
import { handleAsync } from 'best-modules/plugins'
import axios from '@/plugins/axios.js'
import { urlGetClientOfferTemplates, urlUploadFiles } from '@/pages/request.js'
import { getErrorMessages, keepArray } from 'best-modules/utils'
import { Validation } from '@vuelidate/core'

export default {
  name: 'SendOfferEmail',
  components: { Editor },
  emits: ['update:offer-credentials', 'update:advance'],
  props: {
    offerCredentials: {
      type: Object as PropType<OfferCredentials>,
    },
    calculation: {
      type: Object,
    },
    advance: {
      type: Boolean,
    },
    v$: {
      type: Object as PropType<Validation>,
    },
    emails: {
      type: Array as PropType<Array<{ email: string; owner: string }>>,
    },
  },
  methods: {
    getErrorMessages,
    editorConfig,
  },
  setup(props, { emit }) {
    const TINYMCE_KEY = import.meta.env.VITE_API_KEY_TINYMCE
    const TINYMCE_EDITOR_ID = 'tiny-send-offer-id'
    const store = useStore()
    const user = computed(() => store.state.user)

    const offer = computed<OfferCredentials>({
      get() {
        return props.offerCredentials
      },
      set(v) {
        emit('update:offer-credentials', v)
      },
    })

    const userSigns = ref([])
    const getUserSings = () => {
      return handleAsync('getUserSings', () => {
        return axios
          .get(urlGetClientOfferTemplates(user.value.id))
          .then(res => {
            const modifiedSigns =
              res.data?.map(sign => {
                return {
                  title: sign.name,
                  description: '',
                  content: sign.sign,
                }
              }) || []
            userSigns.value.push(...modifiedSigns)
          })
      })
    }
    getUserSings()

    const uploadExtraFiles = (files: File[] | File) => {
      const formData = new FormData()
      keepArray(files).forEach(f => formData.append('file[]', f))
      return axios
        .post(urlUploadFiles(), formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(res => {
          offer.value.extraFiles = res.data.url
        })
        .catch(e => console.log(e))
    }

    return {
      user,
      offer,
      TINYMCE_KEY,
      TINYMCE_EDITOR_ID,
      userSigns,
      uploadExtraFiles,
    }
  },
}
</script>

<style scoped></style>
