<template>
  <div>
    <SectionLoader v-if="loading"></SectionLoader>
    <v-fade-transition hide-on-leave>
      <Chat
        v-if="!loading"
        :ticket="ticket"
        :user="user"
        :addCommentAction="
          params => $axios.post(urlTicketAddAdminComment(), params)
        "
        @data-changed="
          () => {
            getTicket()
            $emit('dataChanged')
          }
        ">
      </Chat>
    </v-fade-transition>
  </div>
</template>
<script>
import Chat from '@/components/chat.vue'
import SectionLoader from '@/components/section-loader.vue'
import { urlGetTicketHistory, urlTicketAddAdminComment } from '@/pages/request'
import { mapState } from 'vuex'
export default {
  emits: ['dataChanged'],
  components: { SectionLoader, Chat },
  data: () => ({
    ticket: {},
    loading: false,
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
  methods: {
    urlTicketAddAdminComment,
    getTicket() {
      this.loading = true
      return this.$axios
        .get(urlGetTicketHistory(this.$route.params.id))
        .then(res => {
          this.loading = false
          Object.assign(this.ticket, res.data)
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
  },
  created() {
    this.getTicket()
  },
}
</script>
