<template>
  <div class="banner-container">
    <v-btn
      icon
      color="red-darken-2"
      variant="flat"
      size="small"
      @click="closeBanner">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="text-overlay">
      <h3>❝ {{ user?.motivating_quote?.quote }} ❞</h3>
      <h3 class="mt-1 text-end">ー {{ user?.motivating_quote?.author }}</h3>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  emits: ['closeBanner'],
  setup(_, { emit }) {
    const store = useStore()
    const user = computed(() => store.state.user)

    const closeBanner = () => {
      emit('closeBanner')
    }

    return { user, closeBanner }
  },
}
</script>
<style scoped>
.banner-container {
  cursor: default;
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('@/assets/images/newYearBg.png');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1000000000;

  button {
    z-index: 10000000;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.text-overlay {
  font-size: 27px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: start;
  z-index: 10;
  font-weight: 900;
  max-width: 45%;
}
</style>
