<template>
  <v-row>
    <v-col cols="12" md="12" class="pb-0 pt-0">
      <span class="label">Заплановано на</span>
      <DatePicker
        v-model="task.date"
        time-picker
        single
        placeholder="Оберіть дату" />
    </v-col>
    <v-col cols="12" md="12" class="pt-0 pb-0">
      <span class="label">Нагадування</span>
      <v-textarea
        v-model="task.taskMessage"
        placeholder="Введіть текст"
        :error-messages="taskPlanErr"
        :rows="4"
        hide-details
        dense>
      </v-textarea>
    </v-col>
    <v-col cols="12" md="12" class="pt-0 pb-0">
      <span class="label">Дата створення</span>
      <v-text-field
        placeholder="Дата створення"
        :model-value="toFormatDate(task.created_at)"
        readonly>
      </v-text-field>
    </v-col>
    <v-col cols="12" md="12" class="pt-0 pb-0">
      <v-checkbox
        v-model="task.isDone"
        :true-value="true"
        :false-value="false"
        dense>
        <template #label>
          <span style="font-size: 12px">Завершене</span>
        </template>
      </v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue'
import { toFormatDate, backDate } from '@/utils/helperFunc'
import { urlTaskPlanTaskUpdate } from '@/pages/request'
import { mapState } from 'vuex'
import { minLength, required } from '@vuelidate/validators'
import { setErrHandler } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: {
    DatePicker,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    dialog: { type: Object },
  },
  validations() {
    return {
      task: {
        taskMessage: { required, minLength: minLength(3) },
        date: { required },
      },
    }
  },
  data: () => {
    return {
      task: {
        date: null,
        taskMessage: null,
        isDone: null,
      },
      createErrorMsg: {
        taskMessage: null,
      },
    }
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    validation() {
      return { v$: this.v$.task, name: 'planTask' }
    },
    taskPlanErr() {
      return setErrHandler(this.v$.task?.taskMessage)
    },
  },
  methods: {
    toFormatDate,
    backDate,
    submit() {
      const res = { ...this.task }
      res.date = backDate(this.task.date)
      return this.$axios
        .post(urlTaskPlanTaskUpdate(this.task.id), res)
        .then(() => {
          this.$setSnackbar({ text: 'Нагадування відредаговане' })
        })
        .catch(this.$err)
    },
  },
  mounted() {
    Object.assign(this.task, this.dialog.dialogItem)
  },
}
</script>

<style></style>
