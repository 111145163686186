<template>
  <div>
    <component
      :is="'ContractorCard'"
      v-if="typeContractor"
      :isNew="FDAnalyze.status?.id === 1 && roleAccess"
      :contractor="provider.contractor" />

    <component
      :is="'ContactCard'"
      v-if="typeContact"
      :contact="provider.contact"
      :isNew="FDAnalyze.status?.id === 1 && roleAccess" />
  </div>
</template>

<script>
import ContractorCard from '@/components/project-varification/ContractorCard.vue'
import ContactCard from '@/components/project-varification/ContactCard.vue'
import { mapState } from 'vuex'
export default {
  components: {
    ContractorCard,
    ContactCard,
  },
  props: {
    providers: { type: Array },
    FDAnalyze: { type: Object },
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    roleAccess() {
      return [2, 12, 19].includes(this.user?.role?.id)
    },
    provider() {
      return this.providers[this.$route.params.idx - 1]
    },
    typeContractor() {
      return this.provider.providerTypeId === 2
    },
    typeContact() {
      return [1, 3].includes(this.provider.providerTypeId)
    },
  },
}
</script>

<style scoped></style>
