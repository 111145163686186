<template>
  <v-col v-if="region" cols="12" md="12" sm="12" class="pt-0">
    <span class="label">Область</span>
    <v-text-field :value="region" dense readonly hide-details>
      <template v-if="city.RegionOld && city.RegionOld !== city.Region" #append>
        <toggle-old-name
          :model-value="!!city.useOldRegion"
          :new-name="city.Region"
          :old-name="city.RegionOld"
          @update:model-value="
            $emit('update:city', { ...city, useOldRegion: $event })
          "></toggle-old-name>
      </template>
    </v-text-field>
  </v-col>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { City } from '@/utils/types'
import ToggleOldName from './ToggleOldName.vue'
import { getAddressPartName } from '../utils'

export default {
  name: 'Region',
  emits: ['update:city'],
  components: { ToggleOldName },
  props: {
    city: {
      type: Object as PropType<City>,
      required: true,
    },
  },
  setup(props) {
    const region = computed<string>(() => {
      return getAddressPartName(
        props.city.Region,
        props.city.RegionOld,
        props.city.useOldRegion
      )
    })

    return { region }
  },
}
</script>

<style scoped></style>
