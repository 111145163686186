<template>
  <div>
    <component
      :is="subtenant.subtenantTypeId === 2 ? 'ContractorCard' : 'ContactCard'"
      v-if="subtenant"
      :contact="subtenant.contact"
      :contractor="subtenant.contractor"
      :isNew="FDAnalyze.status?.id === 1"
      :showEmployees="false"
      :showAuthorizedCapitalFull="false"
      finDocs
      legalDocs>
      <template #before-docs>
        <FilesUploader
          class="mt-6"
          label="Заявка анкета поручителя"
          :documents="subtenant.subtenantApplicationForm"
          :list="false"
          readonly />
      </template>
    </component>
  </div>
</template>

<script>
import ContactCard from '@/components/project-varification/ContactCard.vue'
import ContractorCard from '@/components/project-varification/ContractorCard.vue'
import FilesUploader from '@/components/FilesUploader.vue'
export default {
  components: {
    ContactCard,
    ContractorCard,
    FilesUploader,
  },
  props: {
    subtenants: { type: Array },
    FDAnalyze: { type: Object },
  },
  computed: {
    subtenant() {
      return this.subtenants[this.$route.params.idx - 1]
    },
  },
}
</script>

<style scoped></style>
