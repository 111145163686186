<template>
  <div>
    <v-img
      :height="height"
      :width="width"
      cover
      style="border-radius: 50%"
      :alt="alt"
      :src="tempSourceLink"></v-img>
  </div>
</template>

<script>
import { getPhotoWithToken } from '@/utils/helperFunc'
import { ref } from 'vue'

export default {
  props: {
    src: { type: String },
    height: { type: [Number, String], default: 'auto' },
    width: { type: [Number, String], default: 'auto' },
    alt: { type: [Number, String] },
  },
  setup(props) {
    const tempSourceLink = ref(null)

    const getPhoto = () => {
      getPhotoWithToken(props.src, true).then(res => {
        tempSourceLink.value = res
      })
    }

    getPhoto()

    return {
      tempSourceLink,
    }
  },
}
</script>

<style scoped></style>
