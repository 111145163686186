<template>
  <div>
    <div class="d-flex align-center gap-2">
      <PersonalIcon />
      <span class="font-weight-bold" style="color: #08487a">
        Основні дані
      </span>
    </div>
    <div v-if="application.lesseeTypeId === 2" style="display: contents">
      <v-row class="mb-3">
        <v-col :cols="12" :sm="4">
          <span class="subtitle">Тип клієнта</span>
          <v-select
            v-model="application.lesseeTypeId"
            :items="$directory.get('lesseeTypes', application.lesseeType) as any[]"
            item-title="name"
            item-value="id"
            :readonly="application.readOnly"
            placeholder="Не вказано"
            hide-details
            dence></v-select>
        </v-col>
        <v-col :cols="12" :sm="4">
          <span class="subtitle">ЄДРПОУ</span>
          <b-input
            v-model="application.edrpou"
            placeholder="Не вказано"
            dence
            :readonly="application.readOnly"
            hide-details
            :mask="'#'.repeat(8)"
            :loading="$loading.isLoading('searchDuplicatesByCode')"
            @blur="searchDuplicatesByCode()"></b-input>
        </v-col>
        <v-col :cols="12" :sm="4">
          <span class="subtitle">Назва компанії</span>
          <v-text-field
            v-model="application.companyName"
            placeholder="Не вказано"
            :readonly="application.readOnly"
            hide-details
            dence></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-row v-if="[1, 3].includes(application.lesseeTypeId)">
      <v-col :cols="12" :sm="4">
        <span class="subtitle">Тип клієнта</span>
        <v-select
          v-model="application.lesseeTypeId"
          :items="$directory.get('lesseeTypes', application.lesseeType) as any[]"
          item-title="name"
          item-value="id"
          :readonly="application.readOnly"
          placeholder="Не вказано"
          hide-details
          dence></v-select>
      </v-col>
      <v-col :cols="12" :sm="4">
        <span class="subtitle">Ідентифікаційний код</span>
        <b-input
          v-model="application.inn"
          :readonly="application.readOnly"
          placeholder="Не вказано"
          hide-details
          dence
          :mask="'#'.repeat(10)"
          :loading="$loading.isLoading('searchDuplicatesByCode')"
          @blur="searchDuplicatesByCode()"></b-input>
      </v-col>
    </v-row>

    <div class="d-flex align-center gap-2">
      <GuarantorIcon />
      <span class="font-weight-bold" style="color: #08487a">
        Контактна особа
      </span>
    </div>
    <v-row align="start" class="mb-3">
      <v-col :cols="12" :sm="4">
        <span class="subtitle">Прізвище</span>
        <v-text-field
          v-model="application.surname"
          :readonly="application.readOnly"
          placeholder="Не вказано"
          hide-details
          dence></v-text-field>
      </v-col>
      <v-col :cols="12" :sm="4">
        <span class="subtitle">Імʼя</span>
        <v-text-field
          v-model="application.name"
          :readonly="application.readOnly"
          placeholder="Не вказано"
          hide-details
          dence></v-text-field>
      </v-col>
      <v-col :cols="12" :sm="4">
        <span class="subtitle">По батькові</span>
        <v-text-field
          v-model="application.patronymic"
          :readonly="application.readOnly"
          placeholder="Не вказано"
          dence
          :disabled="!application.isPatronymic"
          hide-details
          class="mb-2"></v-text-field>
        <v-checkbox
          v-model="application.isPatronymic"
          :readonly="application.readOnly"
          :true-value="false"
          :false-value="true"
          hide-details
          color="red"
          dense
          @update:model-value="application.patronymic = null">
          <template #label>
            <span style="font-size: 12px">Відсутнє по батькові</span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12" :sm="4">
        <span class="subtitle">Телефон</span>
        <Contact
          v-model="application.phones"
          title="Телефон"
          placeholder="Вкажіть номер"
          itemKey="phone"
          :readonly="application.readOnly"
          :isValidItem="isValidPhone"
          :mask="phoneMask"
          :deleteItem="contact => deleteContact(contact, 'phone')"
          :handleItem="
            (contact, isCreate) => handleContact(contact, isCreate, 'phone')
          ">
        </Contact>
      </v-col>
      <v-col :cols="12" :sm="4">
        <span class="subtitle">Email</span>
        <Contact
          v-model="application.emails"
          title="Email"
          placeholder="Вкажіть email"
          itemKey="email"
          :readonly="application.readOnly"
          :isValidItem="isEmail"
          :mask="emailMask"
          :deleteItem="contact => deleteContact(contact, 'email')"
          :handleItem="
            (contact, isCreate) => handleContact(contact, isCreate, 'email')
          ">
        </Contact>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { computed, inject } from 'vue'
import {
  applicationKey,
  createApplicationKey,
  updateApplicationObjectKey,
} from '../../injectionKeys'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { Application } from '@/utils/types/application'
import PersonalIcon from '@/assets/svg/personal-icon.vue'
import GuarantorIcon from '@/assets/svg/guarantor-icon.vue'
import Contact from '@/components/Contact.vue'
import { phoneMask, emailMask } from '@/utils/masks'
import { getContactInitials, isEmail } from '@/utils/helperFunc'
import {
  urlApplicationCreateEmail,
  urlApplicationCreatePhone,
  urlApplicationDeleteEmail,
  urlApplicationDeletePhone,
  urlApplicationUpdateEmail,
  urlApplicationUpdatePhone,
  urlSearchDuplicatesByCode,
} from '@/pages/request'
import axios from '@/plugins/axios.js'
import { setSnackbar, handleAsync } from 'best-modules/plugins'

export default {
  name: 'LesseeDefault',
  components: { Contact, GuarantorIcon, PersonalIcon },
  data: () => {
    return {
      phoneMask,
      emailMask,
    }
  },
  methods: {
    isEmail,
    isValidPhone(phone: string): boolean {
      return phone?.length === 23
    },
  },
  setup() {
    const createApplication = inject(createApplicationKey)

    const updateApplicationObject = inject(updateApplicationObjectKey)
    const application = computed<CachedObjectProxy<Application>>({
      get: () => inject(applicationKey),
      set: updateApplicationObject,
    })

    const getItem = (value, key: 'phone' | 'email') => {
      switch (key) {
        case 'email':
          return (
            application.value.emails.find(item => item.email === value) || value
          )
        case 'phone':
          return (
            application.value.phones.find(item => item.phone === value) || value
          )
      }
    }

    const deleteContact = (contact, type: 'email' | 'phone') => {
      if (createApplication.value) {
        const item = getItem(contact, 'phone')

        const url =
          type === 'email'
            ? urlApplicationDeleteEmail
            : urlApplicationDeletePhone
        return axios.delete(url(item.id))
      }
    }

    const handleContact = async (
      contact,
      isCreate: boolean,
      type: 'email' | 'phone'
    ) => {
      if (createApplication.value) {
        return contact
      }
      const getUrl = (): string => {
        switch (true) {
          case isCreate && type === 'email':
            return urlApplicationCreateEmail()
          case !isCreate && type === 'email':
            return urlApplicationUpdateEmail(contact.id)
          case isCreate && type === 'phone':
            return urlApplicationCreatePhone()
          case !isCreate && type === 'phone':
            return urlApplicationUpdatePhone(contact.id)
        }
      }

      const requestObj = {
        applicationId: application.value.id,
      }
      requestObj[type] = contact[type]

      const { data: newContact } = await axios.post(getUrl(), requestObj)

      return newContact
    }

    const searchDuplicatesByCode = () => {
      const isContractor = application.value.lesseeTypeId === 2
      const code = isContractor
        ? application.value.edrpou
        : application.value.inn
      const codeName = isContractor ? 'ЄДРПОУ' : 'ІПН'
      const codeLength = isContractor ? 8 : 10

      if (code?.length === codeLength) {
        return handleAsync('searchDuplicatesByCode', () => {
          return axios(urlSearchDuplicatesByCode(code)).then(res => {
            if (res.data.length) {
              const msg = [
                {
                  tag: 'div',
                  text: `Знайдені збіги по ${codeName} - ${code}`,
                  class: 'mb-3',
                },
                {
                  tag: 'ol',
                  children: res.data
                    .map(item => {
                      const listItem = [
                        {
                          tag: 'li',
                          text: `Назва: ${
                            item.inn
                              ? getContactInitials(item)
                              : item.name ||
                                item.shortName ||
                                'Назву не знайдено'
                          }`,
                        },
                        {
                          tag: 'li',
                          text: `Відповідальний: ${getContactInitials(
                            item.responsible
                          )}`,
                        },
                      ]

                      if (item.isClient) {
                        listItem.push({
                          tag: 'li',
                          text: `Статус клієнта: ${item?.clientStatus?.name}`,
                        })
                      }

                      return listItem
                    })
                    .flat(),
                },
              ]

              setSnackbar({ text: msg, color: 'warning' })
            }
          })
        })
      }
    }

    return { application, deleteContact, handleContact, searchDuplicatesByCode }
  },
}
</script>

<style scoped>
.subtitle {
  color: #838282;
  font-size: 13px;
  margin-bottom: 2px;
}
</style>
