<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div
        v-show="expandDrawer"
        class="bonus-wrapper position-relative w-100 h-100 overflow-hidden">
        <Snowflakes snowflake="snowflake" />
        <bl-logo
          :width="143"
          :height="45"
          addProps="margin: 3px 0px 3px;"
          color="white"
          redColor="rgba(247, 45, 45, 0.925)">
        </bl-logo>
        <div class="app-subheader">
          <v-fade-transition hide-on-leave>
            <div
              v-if="Object.keys(bonus).length && [2, 3].includes(user.roleId)"
              class="side-bar-bonus">
              <div class="d-flex justify-space-between align-center">
                <span class="bonus-msg d-block"
                  >Бонус за {{ bonus.monthName }}</span
                >
                <v-btn
                  icon
                  size="x-small"
                  color="white"
                  @click="openBonusDialog">
                  <v-icon>{{ 'mdi-calendar-month' }}</v-icon>
                </v-btn>
              </div>
              <v-tooltip
                bottom
                color="#000000e8"
                :text="formutSum(bonus.done || 0)">
                <template #activator="{ props }">
                  <div class="bonus-sum-wrapper" v-bind="props">
                    <span class="bonus-sum"
                      ><income />&nbsp;&nbsp;{{
                        formutSum(bonus.done || 0)
                      }}
                      грн</span
                    >
                  </div>
                </template>
                <span>Вже в карманi</span>
              </v-tooltip>
              <v-tooltip
                bottom
                color="#000000e8"
                :text="formutSum(bonus.inWork || 0)">
                <template #activator="{ props }">
                  <div class="bonus-sum-wrapper" v-bind="props">
                    <span class="bonus-sum"
                      ><income-plus />&nbsp;&nbsp;{{
                        formutSum(bonus.inWork || 0)
                      }}
                      грн</span
                    >
                  </div>
                </template>
                <span>Можна отримати ще</span>
              </v-tooltip>
            </div>
          </v-fade-transition>
        </div>
      </div>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <!-- Desktop menu items -->
      <div v-show="expandDrawer" class="desktop-menu">
        <div
          v-for="(item, i) in filterAccess(menu)"
          :key="i"
          class="nav-item-wrapper"
          :class="{
            active: activeHandler(item),
            last: i === menu.length - 1,
            collapsed: deskMenuCollapsed,
            nested: item.nested,
          }">
          <div
            :id="`nav-item-${i}`"
            class="nav-item"
            :class="{
              first: i === 0,
              deskMenuCollapsed: deskMenuCollapsed,
            }"
            @click="navTo(item.to)">
            <router-link :to="item.to" class="nav-title">
              {{ item.title }}
            </router-link>
            <!-- list of titles thats present in category -->
            <div
              v-if="
                item.nested && !activeNestedHandler(item) && !deskMenuCollapsed
              "
              class="nested-desc">
              {{
                filterAccess(item.nested)
                  .map(v => v.title)
                  .join(', ')
              }}
            </div>
            <greater-icon
              v-show="item.nested"
              class="greater-icon"
              :class="{
                active: $route.name === item.to.name && !deskMenuCollapsed,
              }"
              :width="10"
              :height="10">
            </greater-icon>
          </div>
          <!-- nested menu items -->
          <div v-if="activeNestedHandler(item)">
            <div
              v-for="(nestedItem, idx) in filterAccess(item.nested)"
              :key="idx">
              <div
                :id="`nav${i}`"
                class="nested-list"
                :class="{
                  active: !deskMenuCollapsed,
                }">
                <div
                  class="nested-item"
                  :class="{ active: matchPathArr.includes(nestedItem.to.name) }"
                  @click="navTo(nestedItem.to, true)">
                  <div>
                    <div>
                      <router-link :to="nestedItem.to" class="nested-item-txt">
                        &nbsp;
                        {{ nestedItem.title }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-fade-transition>
    <!-- Mobile menu items -->
    <v-fade-transition hide-on-leave>
      <div
        v-show="!expandDrawer"
        class="mobile-nav-bar"
        style="position: relative; z-index: 15">
        <div
          class="nav-bar d-flex justify-center"
          style="position: relative; flex-wrap: wrap">
          <div
            v-show="showMobileMenu"
            class="menu-card"
            :class="{ active: showMobileMenu }"
            @mouseleave="$emit('changeMobileMenu', false)">
            <div
              v-for="(item, i) in filterAccess(menu)"
              :key="i"
              class="nav-item"
              :class="{
                active: routeIsActive(item.to.name, i),
                last: i === menu.length - 1,
              }"
              @click="
                () => {
                  navTo(item.to)
                  $emit('changeMobileMenu', !showMobileMenu)
                }
              ">
              {{ item.title }}
            </div>
          </div>
          <m-hr color="#ffff" height="2px" padding="8px 8px 19px 8px" />
          <v-fade-transition hide-on-leave>
            <div v-if="menu">
              <div
                v-for="(item, key) in filterAccess(menu)"
                :key="key"
                class="mobile-nest"
                style="color: white"
                @mouseenter="$emit('openDrawer', filteredNested(item))">
                <v-btn
                  class="mobile-nest__nav-btn"
                  :color="
                    $route.name.includes(item.to.name) ? 'white' : '#ffffff7e'
                  "
                  icon
                  link
                  large
                  @click="navTo(item.to)">
                  <v-icon>
                    {{ item.icon }}
                  </v-icon>
                </v-btn>
                <div v-if="!item.nested" class="nav-arrow">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </v-fade-transition>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { urlGetFrontManagerBonus } from '@/pages/request.js'
import { formutSum } from '@/utils/formatFunc.js'
import blLogo from '@/assets/svg/best-leasing-logo.vue'
import income from '@/assets/svg/income.vue'
import incomePlus from '@/assets/svg/income-plus.vue'
import mHr from '@/components/m-hr.vue'
import GreaterIcon from '@/assets/svg/greater-icon.vue'
import Snowflakes from '@/components/newYear/Snowflakes.vue'
export default {
  emits: ['changeMobileMenu', 'changeDeskMenu', 'openDrawer'],
  components: {
    Snowflakes,
    GreaterIcon,
    blLogo,
    income,
    incomePlus,
    mHr,
  },
  props: {
    expandDrawer: { type: Boolean, default: false },
    menu: { type: Array, default: () => [] },
    deskMenuCollapsed: { type: Boolean, default: false },
    showMobileMenu: { type: Boolean, default: false },
  },
  data: () => ({
    bonus: {
      done: null,
      inWork: null,
      monthName: null,
    },
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    matchPathArr() {
      const matchPath = this.$route?.matched?.map(n => {
        return n.name
      })

      return matchPath
    },
    navIndex() {
      let index
      this.menu.forEach((v, i) => {
        if (this.routeIsActive(v.to.name)) index = i
      })
      return index || 0
    },
    userRoleId() {
      return this.user?.roleId
    },
  },
  methods: {
    formutSum,
    urlGetFrontManagerBonus,
    navTo(obj, nested) {
      if (this.$route.name !== obj.name && !this.routeIsActive(obj.name)) {
        this.$router.push(obj)
        this.$emit('changeDeskMenu', false)
        return
      } else if (!nested) {
        this.$emit('changeDeskMenu', !this.deskMenuCollapsed)
        this.$route.name !== obj.name ? this.$router.push(obj) : false
        return
      } else if (nested && this.$route.name !== obj.name) {
        this.$router.push(obj)
        return
      }
    },
    activeNestedHandler(item) {
      if (item.nested && item.nested.length) {
        return item.nested.reduce((acc, n) => {
          return acc || this.matchPathArr.includes(n.to.name)
        }, false)
      } else {
        return false
      }
    },
    activeHandler(item) {
      return (
        this.matchPathArr.includes(item.to.name) ||
        item.to.name === this.$route.name ||
        item.nested?.map(n => n.to.name).includes(this.$route.name)
      )
    },
    openBonusDialog() {
      this.$store.commit('setDialog', {
        title: 'Річна статистика по бонусам',
        dialogItem: {
          userId: this.user?.id,
          bonus: this.bonus,
        },
        params: {
          hideActions: true,
          dialogWidth: 700,
        },
        action: 'frontBonusStats',
      })
    },
    routeIsActive(name) {
      return this.$route.name === name
    },
    getFrontBonus() {
      const curYear = new Date().getFullYear()
      if (!this.user.id) return
      return this.$axios
        .get(this.urlGetFrontManagerBonus(this.user.id, curYear))
        .then(res => {
          Object.assign(this.bonus, res.data)
          return res
        })
        .catch(this.$err)
    },
    filterAccess(pages) {
      if (!Array.isArray(pages)) {
        throw new TypeError("Expected an array for 'pages'")
      }

      const filteredPages = pages.filter(page => {
        if (page.title === 'Лiди' && !this.user.leadAccess) return false

        const userHasAccessByRole =
          !page?.roleId?.length || page?.roleId?.includes(this.userRoleId)
        if (userHasAccessByRole) return true

        const pageUserKeys = Object.keys(page?.user || {})
        if (!pageUserKeys.length) return false

        const userKey = Object.keys(this.user || {})
          .filter(key => pageUserKeys.includes(key))
          .pop()
        if (userKey && this.user[userKey] === page?.user?.[userKey]) {
          return true
        }

        return false
      })

      return filteredPages
    },
    filteredNested(item) {
      if (item?.nested) {
        item.nested = this.filterAccess(item.nested)
      }

      return item
    },
    redirectByRole(route) {
      const redirectToNested = name => {
        const accessed = this.filterAccess(this.menu).find(
          route => route.to.name === name
        )
        const nested = this.filterAccess(accessed?.nested)
        const first = nested?.[0]?.to
        this.$router.push(first)
      }

      switch (route.name) {
        case 'verification':
          return redirectToNested('/crm/verification')
        case 'administrIndex':
          return redirectToNested('/crm/administration')
        default:
          return
      }
    },
  },
  watch: {
    $route: function (route) {
      this.redirectByRole(route)
    },
    'user.id': function () {
      this.getFrontBonus()
    },
  },
  mounted() {
    this.getFrontBonus()
    this.redirectByRole(this.$route)
  },
}
</script>
