<template>
  <div>
    <CreateNewGroup
      :v$="v$"
      :companyGroup="companyGroup"
      :companyGroupDialog="companyGroupDialog"
      @close-dialog="companyGroupDialog = false" />
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Група компаній</span>
        <v-select
          v-model="companyGroup.companyGroup"
          placeholder="Оберіть зі списку"
          :error-messages="companyGroupIdErr"
          :items="computedItems"
          item-title="name"
          item-value="id"
          hide-details
          dense
          :loading="$loading.isLoading('companyGroupList')"
          return-object
          @focus="$directory.fill('companyGroupList')"
          @blur="v$.companyGroup.companyGroupId.$touch()"
          @update:model-value="onSelectCompanyGroup">
          <template #item="{ item, props }">
            <v-list-item
              :title="item.name"
              :value="item.id"
              :prepend-icon="item.raw.icon"
              v-bind="props"></v-list-item>
          </template>
          <template v-if="companyGroup.companyGroup?.members?.length" #append>
            <CompanyGroupMembers
              :members="companyGroup.companyGroup?.members" />
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Роль</span>
        <v-select
          v-model="companyGroup.roleId"
          placeholder="Оберіть зі списку"
          :error-messages="roleIdErr"
          :items="$directory.get('companyGroupMemberRole', companyGroup.role)"
          item-title="name"
          item-value="id"
          hide-details
          dense
          :loading="$loading.isLoading('companyGroupMemberRole')"
          @focus="$directory.fill('companyGroupMemberRole')"
          @blur="v$.companyGroup.roleId.$touch()">
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { setErrHandler } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { required } from '@vuelidate/validators'
import {
  urlCompanyGroupMemberCreate,
  urlCompanyGroupMemberUpdate,
} from '@/pages/request'
import CreateNewGroup from '@/dialogs/components/contractor/companyGroup/CreateNewGroup.vue'
import { getDirectory } from '@/plugins/directory'
import CompanyGroupMembers from '@/dialogs/components/contractor/companyGroup/CompanyGroupMembers.vue'
import { mapState } from 'vuex'

export default {
  components: { CompanyGroupMembers, CreateNewGroup },
  props: {
    dialog: { type: Object },
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    return {
      companyGroup: {
        roleId: !this.companyGroupDialog ? { required } : {},
        companyGroupId: !this.companyGroupDialog ? { required } : {},
        name: this.companyGroupDialog ? { required } : {},
      },
    }
  },
  data: () => ({
    companyGroup: {
      roleId: null,
      companyGroupId: null,
      name: null,
    },
    companyGroupDialog: false,
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    validation() {
      return { v$: this.v$.companyGroup, name: 'companyGroup' }
    },
    roleIdErr() {
      return setErrHandler(this.v$.roleId)
    },
    companyGroupIdErr() {
      return setErrHandler(this.v$.companyGroupId)
    },
    computedItems() {
      const items = [...getDirectory('companyGroupList')]
      if (this.user.roleId === 1) {
        items.push({
          name: 'Створити нову',
          icon: 'mdi-plus',
          id: 'create_new',
          isAction: true,
        })
      }
      return items
    },
  },
  methods: {
    onSelectCompanyGroup(selectedItem) {
      const selected = this.computedItems.find(
        item => item.id === selectedItem?.id
      )
      this.companyGroup.companyGroupId = selectedItem?.id

      if (selected?.isAction) {
        this.companyGroup.companyGroupId = null
        this.companyGroup.companyGroup = null
        this.companyGroupDialog = true
      }
    },
    submit() {
      const url =
        this.dialog.dialogItem.action === 'editCompanyGroup'
          ? urlCompanyGroupMemberUpdate(this.companyGroup?.id)
          : urlCompanyGroupMemberCreate()
      return this.$axios
        .post(url, {
          roleId: this.companyGroup?.roleId,
          companyGroupId: this.companyGroup?.companyGroupId,
          contractorId: +this.$route.params.id,
        })
        .then(res => {
          this.$setSnackbar({ text: 'Дані оновлено' })
          this.$store.commit('refreshPage')
          return res
        })
    },
  },
  created() {
    Object.assign(this.companyGroup, this.dialog.dialogItem)
  },
}
</script>

<style scoped></style>
