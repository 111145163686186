<template>
  <div class="dfl-contract">
    <SectionLoader v-if="loading"> </SectionLoader>
    <v-row v-if="!loading">
      <v-col cols="12" md="3" sm="12">
        <FieldsColumnBlock title="Основні дані">
          <template #general-fields>
            <Fields :fields="fields" :type="2" direction="column" />
          </template>
          <template #default>
            <div class="mb-2">
              <span class="label">Вид підпису</span>
              <v-select
                v-model="contract.signingTypeId"
                :items="$directory.get('signingTypes', contract.signingType)"
                item-title="name"
                item-value="id"
                hide-details
                :readonly="readonly"
                :disabled="readonly"
                placeholder="Оберіть вид"
                :error-messages="getErrorMessages(v$.contract.signingTypeId)"
                :loading="$loading.isLoading('signingTypes')"
                @focus="$directory.fill('signingTypes')"
                @blur="v$.contract.signingTypeId.$touch()">
              </v-select>
            </div>
          </template>
        </FieldsColumnBlock>
        <FieldsColumnBlock title="Візування" :fullHeight="!mobile">
          <template #agreements>
            <Agreements :agreements="contract.agreements" />
          </template>
        </FieldsColumnBlock>
      </v-col>
      <v-col cols="12" md="9" sm="12" :class="`${mobile ? '' : 'pl-0'}`">
        <div class="white-block" style="padding-left: 7px">
          <ContractStatusBar
            class="mb-3"
            :contract="contract"
            :mobile="mobile"
            :isCancelContract="contract.isCancelContract"
            :isTerminated="contract.isTerminated"
            :currentStatus="contract.statusId"
            :submit="
              statusId => sendDfl(statusId).then(() => setDflStatus(statusId))
            "
            :statuses="filteredStatuses"
            spreadItems
            size="small">
            <template #actions>
              <div class="d-flex align-center">
                <v-tooltip bottom text="Інформація з 1с">
                  <template #activator="{ props }">
                    <v-btn
                      icon
                      density="comfortable"
                      class="rounded-circle"
                      v-bind="props"
                      @click="setDialog">
                      <oneCIcon />
                    </v-btn>
                  </template>
                  <span>Інформація з 1с</span>
                </v-tooltip>
                <v-btn
                  v-if="contract.registrationApplicationId"
                  v-tooltip:bottom="'Заявка на реєстрацію'"
                  icon="mdi-file-compare"
                  color="red"
                  density="comfortable"
                  class="rounded-circle"
                  @click="
                    $router.push({
                      name: 'registration-application-single',
                      params: {
                        id: contract.registrationApplicationId,
                      },
                    })
                  ">
                </v-btn>
                <div v-if="showloadingSub" class="background-loader">
                  <div class="loader">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                  </div>
                </div>
                <v-dialog v-if="oneCDialog" v-model="oneCDialog" width="450px">
                  <div style="height: 85vh; overflow: scroll">
                    <div style="padding: 10px; background: #fff">
                      <div class="d-flex justify-end">
                        <v-btn icon size="small" @click="oneCDialog = false">
                          <CloseIcon />
                        </v-btn>
                      </div>
                      <div class="pa-2" style="background: #fff">
                        <ModalInfo :info="oneCInfo" />
                      </div>
                    </div>
                  </div>
                </v-dialog>
                <ContractActionButton :actions="btnActions" />
              </div>
            </template>
          </ContractStatusBar>
          <v-tabs
            v-model="currentTab"
            show-arrows
            :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
            :height="$vuetify.breakpoint?.xs ? null : 38"
            color="white"
            dark
            slider-color="#fc7247"
            dense>
            <v-tab
              replace
              :to="{
                name: 'dfl-parties-details',
                params: { id: $route.params.id },
              }">
              Реквізити
            </v-tab>
            <v-tab
              :to="{
                name: 'leasing-object',
                params: {
                  id: $route.params.id,
                },
              }">
              Предмет лізингу
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'dfl-payment-history',
                params: {
                  id: $route.params.id,
                },
              }">
              Графік платежів
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'dfl-agreements',
                params: { id: $route.params.id },
              }">
              Договори
            </v-tab>
            <v-tab
              replace
              :to="{ name: 'connections', params: { id: $route.params.id } }">
              Зв'язки
            </v-tab>
            <v-tab
              replace
              :to="{ name: 'dfl-history', params: { id: $route.params.id } }">
              Історія
            </v-tab>
          </v-tabs>
        </div>
        <RouterView
          :contract="contract"
          :endorsement="endorsement"
          :readonly="readonly"
          :mobile="mobile"
          :agreements="extraAgreements">
        </RouterView>
        <v-card-actions v-show="!loading">
          <ActionButtons
            class="mt-10"
            :confirm="() => submit()"
            :cancel="getContract"
            :confirmLoading="submitLoading"
            :cancelDisable="submitLoading || contractState === cache"
            :confirmDisable="contractState === cache">
          </ActionButtons>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ActionButtons from '@/components/action-buttons.vue'
import SectionLoader from '@/components/section-loader.vue'
import Fields from '@/components/Fields.vue'
import { mapState } from 'vuex'
import { required, minLength } from '@vuelidate/validators'
import {
  urlUpdateDflContract,
  urlCreateExtraAgreementDfl,
  urlGetAdvancePrintForm,
  urlGetDFLPrintForm,
  urlSetDflStatus,
  urlSetCancelContractDfl,
  urlDownLoadDflDocs,
  urlGetOneCDflInfo,
  urlCreateServiceContract,
} from '@/pages/request'
import {
  LODescription as getLoDescription,
  setCache,
  getAddress,
  v$Notify,
  backDate,
  toFormatDate,
  openDocument,
} from '@/utils/helperFunc'
import { formutSum } from '@/utils/formatFunc'
import { generateDFLPdf } from './pdf/dfl-form'
import { generateAdditionalPdf } from './pdf/dflAdditionalPage'
import { dataURLtoFile } from '@/components/print-forms'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { setSnackbar, confirm } from 'best-modules/plugins'
import { fillDirectory, getDirectory } from '@/plugins/directory/index.ts'
import FieldsColumnBlock from '@/pages/contracts/components/FieldsColumnBlock.vue'
import Agreements from '@/components/Agreements.vue'
import ContractStatusBar from '@/components/contracts/ContractStatusBar.vue'
import ContractActionButton from '@/pages/contracts/components/ContractActionButton.vue'
import CloseIcon from '@/assets/svg/close-icon.vue'
import ModalInfo from '@/components/OneC/Modal-info.vue'
import OneCIcon from '@/assets/svg/oneCIcon.vue'
import { useDisplay } from 'vuetify'
import { computed } from 'vue'
import { getErrorMessages } from 'best-modules/utils'

export default {
  components: {
    OneCIcon,
    ModalInfo,
    CloseIcon,
    ContractActionButton,
    ContractStatusBar,
    Agreements,
    FieldsColumnBlock,
    SectionLoader,
    ActionButtons,
    Fields,
  },
  setup() {
    const { xs, sm } = useDisplay()
    const mobile = computed(() => xs.value || sm.value)

    return { v$: useVuelidate(), ...useSelect(), mobile }
  },
  validations() {
    return {
      contract: {
        number: { required },
        agreementDate: { required, minLength: minLength(10) },
        signatoryId: { required },
        lessorBasisId: { required },
        lesseeSignatories: {
          required,
          minLength: minLength(1),
          $each: {
            contactId: { required },
            basisId: { required },
          },
        },
        signingTypeId: { required },
      },
    }
  },
  data: () => ({
    submitLoading: false,
    advancePrintData: {},
    DFLPrintData: {},
    currentTab: 0,
    dflPrintedForms: [],
    oneCInfo: null,
    oneCDialog: false,
    loadingSub: false,
    showloadingSub: false,
    loadingRequest: false,
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      contract: state => state.contracts.dflContract,
      loading: state => state.contracts.dflContractLoading,
      selectItems: state => state.selectItems,
      calculation: state => state.contracts.dflContract.calculation,
      cache: state => state.contracts.dflCache,
      lessee: state => state.contracts.dflContract.lessee,
      lessor: state => state.contracts.dflContract.lessor,
    }),
    filteredStatuses() {
      const filteredStatuses = getDirectory('dflStatuses').filter(item => {
        if (item.id === 6) {
          return this.contract.isCancelContract
        }
        if (item.id === 7) {
          return this.contract.isTerminated
        }
        return true
      })
      return filteredStatuses.map(s => {
        let showCheckIcon

        if ([6, 7].includes(this.contract.statusId)) {
          showCheckIcon = s.id === this.contract.statusId
        }

        return { ...s, showCheckIcon }
      })
    },
    btnActions() {
      const extraActions = [
        {
          show: true,
          action: () => this.createServiceContract(),
          label: 'Створити Договір сервіcу',
          icon: 'mdi-plus',
          disabled: this.contract.serviceContracts?.length >= 1,
        },
        {
          show: true,
          label: 'Створити Додаткову угоду',
          action: () => this.createExtraAgreement(),
          icon: 'mdi-plus',
          disabled: false,
        },
      ]

      return {
        main: { label: 'Дії', disabled: false },
        list: [
          {
            show:
              !this.contract.isTerminated && !this.contract.isCancelContract,
            label: 'Анулювати договір',
            icon: 'mdi-file-document-remove-outline',
            action: () => this.setCancel(),
            disabled:
              (!this.admin && this.readonly) || this.contract.isCancelContract,
          },
          {
            show:
              !this.contract.isTerminated && !this.contract.isCancelContract,
            label: 'Розірвати договір',
            icon: 'mdi-file-remove',
            action: () => this.terminateContract(),
            disabled: false,
          },
          {
            show: true,
            label: 'Друковані форми',
            icon: 'mdi-printer',
            actions: this.printedFormActions,
            disabled: false,
          },
          ...extraActions,
        ],
      }
    },
    readonly() {
      return this.contract?.readOnly
    },
    extraAgreements() {
      return this.contract.extraAgreements
    },
    dflPdfParams() {
      return [
        this.DFLPrintData,
        {
          lessee: this.lessee,
          calculation: this.calculation,
        },
      ]
    },
    admin() {
      return this.user.roleId === 1
    },
    endorsement() {
      return this.contract.statusId === 3
    },
    contractId() {
      return this.$route?.params?.id
    },
    LO() {
      return this.getLoDescription(this.calculation)
    },
    contractState() {
      return this.setCache([this.contract])
    },
    route() {
      return this.$route.name
    },
    /* validations */
    lesseeName() {
      const contactLesee = this.lessee?.contact?.fullName
      const contractorLesee = this.lessee?.contractor?.shortName
      return (
        (contactLesee || contractorLesee || '---') +
        ' - ' +
        this.contract.number
      )
    },
    printedFormActions() {
      const forms = this.dflPrintedForms.map(pf => {
        return {
          action: () => this.downloadAnyPrintedForm(pf),
          label: pf.name,
          icon: 'mdi-file',
        }
      })
      return [...this.pfForms, ...forms]
    },
    pfForms() {
      return [
        {
          action: () => this.getAdvanceFormData(),
          label: 'Рахунок на аванс',
          icon: 'mdi-file-pdf-box',
        },
        {
          action: () => this.openDFLPdf(),
          label: 'Договір Фінансового лізингу',
          icon: 'mdi-file-pdf-box',
        },
      ]
    },
    fields() {
      return [
        { label: '№ Договору', value: this.contract.number },
        {
          label: 'Дата договору',
          value: toFormatDate(this.contract.agreementDate, true),
        },
        { label: 'Валюта', value: this.LO.financingCurrency },
        {
          label: 'Проект',
          value: `${this.contract?.project?.number} - ${this.contract?.project?.lesseeName}`,
          class: { link: true },
          showHover: true,
          click: () => {
            this.$router.push({
              name: 'project',
              params: { projectId: this.contract.project.id },
            })
          },
        },
      ]
    },
  },
  methods: {
    getErrorMessages,
    setCache,
    getAddress,
    getLoDescription,
    urlUpdateDflContract,
    urlCreateExtraAgreementDfl,
    urlSetCancelContractDfl,
    urlGetAdvancePrintForm,
    formutSum,
    backDate,
    urlGetDFLPrintForm,
    urlSetDflStatus,
    generateDFLPdf,
    generateAdditionalPdf,
    urlCreateServiceContract,
    toFormatDate,
    terminateContract() {
      if (
        this.contract.statusId !== 5 ||
        !this.contract.extraAgreements?.some(a => {
          return a.statusId === 5
        })
      ) {
        return this.$setSnackbar({
          text: 'Для розривання договір та додаткова угода мають бути в статусі "Підписаний"',
          color: 'warning',
        })
      }
      this.$store.commit('setDialog', {
        title: 'Розірвання договору',
        dialogItem: {
          contractId: this.contract.id,
          agreements: this.contract.extraAgreements.filter(a => {
            return a.statusId === 5
          }),
        },
        params: {
          cb: this.getContract,
        },
        action: 'terminateDfl',
      })
    },
    formatObject(obj, indent = 0) {
      if (!obj) {
        return ''
      }

      const indentString = '  '.repeat(indent)
      const lines = []

      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null) {
          const nestedObj = this.formatObject(value, indent + 1)
          lines.push(`${indentString}${key}:`)
          lines.push(nestedObj)
        } else {
          lines.push(`${indentString}${key}: ${value}`)
        }
      }

      return lines.join('\n')
    },
    setDialog() {
      const id = this.contract.oneCId

      if (!id) {
        this.$setSnackbar({ text: "Зв'язку з 1с не знайдено", color: 'error' })
        return
      }

      this.showloadingSub = true

      setTimeout(() => {
        this.getOneCInfo(id).then(() => {
          this.oneCDialog = true
          this.showloadingSub = false
        })
      }, 700)
    },
    getOneCInfo(id) {
      if (!id) {
        return this.$setSnackbar({
          text: "Зв'язку з 1с не знайдено",
          color: 'error',
        })
      }

      this.loadingSub = true

      return this.$axios.post(urlGetOneCDflInfo(), { oneCId: id }).then(res => {
        this.oneCInfo = res.data
        this.loadingSub = false
      })
    },
    downloadAnyPrintedForm(pf) {
      return this.$axios
        .get(urlDownLoadDflDocs(this.$route.params.id, pf.key))
        .then(res => {
          if (res.data.message) {
            return setSnackbar({ text: res.data.message, color: 'error' })
          }
          openDocument({
            url: res.data.url,
            text: pf.name,
          })
        })
    },
    async openDFLPdf() {
      if (this.v$.$invalid) {
        return this.v$.$touch()
      }
      await this.getDFLFormData()
      if (this.DFLPrintData.url) {
        const url = this.DFLPrintData.url
        const ext = url.split('.').pop().toLowerCase()
        const text = `${this.contract.project.number} - ${this.contract.project.lesseeName}.${ext}`

        return openDocument({ url, text })
      }
      this.generateDFLPdf(...this.dflPdfParams).open()
      this.lessee.lesseeTypeId === 1 &&
        this.generateAdditionalPdf(this.DFLPrintData).open()
    },
    setCancel() {
      confirm({
        text: 'Ви дійсно бажаєте анулювати договір?',
      }).then(() => {
        this.loadingRequest = true
        return this.$axios
          .post(urlSetCancelContractDfl(this.contract.id), {
            isCancelContract: true,
          })
          .then(res => {
            this.$setSnackbar({ text: 'Оновлено' })
            Object.assign(this.contract, res.data)
            this.setCache([this.contract])
            this.setStoreCache()
            this.loadingRequest = false

            return res
          })
      })
    },
    async setDflStatus(statusId) {
      const currentStatus = this.contract.statusId

      if (currentStatus === 5) {
        return this.$setSnackbar({
          text: 'Договір з статусу підписаного не може бути змiнено',
          color: 'warning',
        })
      }
      if (
        [4, 5].includes(statusId) &&
        this.contract.agreements?.some(a => !a.isAgreed)
      ) {
        return this.$setSnackbar({
          text: 'Договір повинен бути завізований всiма учасниками',
          color: 'warning',
        })
      }
      if (statusId < currentStatus || statusId > currentStatus + 1) {
        return this.$setSnackbar({ text: 'Дія неможлива', color: 'error' })
      }

      if ([2, 3].includes(statusId)) {
        try {
          const statusNames = {
            2: 'Підписано клієнтом',
            3: 'Візування',
          }
          await confirm({
            text: `Ви впевнені що хочете перевести договір в статус "${statusNames[statusId]}"?`,
          })
        } catch (e) {
          return
        }
      }

      return this.$axios
        .post(this.urlSetDflStatus(this.contract.id), {
          statusId: statusId,
        })
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else this.$setSnackbar({ text: 'Статус успішно змiнено' })
          this.getContract()
          return res
        })
        .catch(this.$err)
    },
    async sendDfl(id) {
      if (this.readonly) return
      try {
        if (this.v$.$invalid) {
          return Promise.reject(v$Notify(this.v$.contract, 'contract'))
        }

        if (id === 3 /* endorsement */) {
          if (!Object.keys(this.DFLPrintData).length) {
            await this.getDFLFormData()
          }
          if (this.DFLPrintData.url) return

          const base64Pdf = await generateDFLPdf(
            ...this.dflPdfParams
          ).getAsBase64()

          return dataURLtoFile(
            'application/pdf',
            base64Pdf,
            `КП ${this.calculation.requestId}}`
          )
        }

        return Promise.resolve()
      } catch (err) {
        this.$err(err)
        throw err
      }
    },
    submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(this.v$.contract, 'contract')
        return Promise.resolve()
      }

      const requestObj = JSON.parse(JSON.stringify(this.contract))
      if (requestObj.lesseeSignatories)
        requestObj.lesseeSignatories = requestObj.lesseeSignatories.map(s => ({
          contactId: s.contactId,
          basisId: s.basisId,
        }))

      this.submitLoading = true
      return this.$axios
        .post(this.urlUpdateDflContract(this.contractId), requestObj)
        .then(res => {
          if (res.data.message) throw new Error(res.data.message)
          this.$setSnackbar({ text: 'Данi збереженi' })
          this.submitLoading = false
          this.setCache([this.contract])
          this.setStoreCache()
        })
        .catch(err => this.$err(err, () => (this.submitLoading = false)))
    },
    openAgreementSingle(id) {
      this.$router.push({ name: 'du-dfl-agreement', params: { id } })
    },
    openServiceContractSingle(id) {
      this.$router.push({ name: 'service-contract', params: { id } })
    },
    setBreadScrumb(res) {
      this.$store.commit('setBreadScrumbPart', [null, null, this.lesseeName])
      return res
    },
    setDflContractLesseeSignatoryId(res) {
      if ([1, 3].includes(this.contract.lessee.lesseeTypeId)) {
        const DflContractLesseeSignatoryId = res?.data?.lessee?.contact?.id
        this.$store.commit(
          'setDflContractLesseeSignatoryId',
          DflContractLesseeSignatoryId
        )
      }
      return res
    },
    createExtraAgreement() {
      this.asyncAction('createExtraAgreement', null, () => {
        return this.$axios
          .post(this.urlCreateExtraAgreementDfl(), {
            contractId: this.contract.id,
          })
          .then(res => {
            this.$setSnackbar({ text: 'Додаткову угоду успішно створено' })
            this.$router.push({
              name: 'du-dfl-agreement',
              params: { id: res.data.id },
            })

            return res
          })
          .catch(this.$err)
      })
    },
    createServiceContract() {
      return this.$axios
        .post(urlCreateServiceContract(), {
          contractId: this.contract.id,
        })
        .then(res => {
          this.$setSnackbar({ text: 'Сервісний договір успішно створено' })
          this.$router.push({
            name: 'service-contract',
            params: { id: res.data.id },
          })

          return res
        })
        .catch(this.$err)
    },
    setStoreCache() {
      this.$store.commit('setDflContractCache', this.setCache([this.contract]))
    },
    getAdvanceFormData() {
      if (this.v$.$invalid) {
        return this.v$.$touch()
      }
      return this.$axios
        .get(this.urlGetAdvancePrintForm(this.$route.params.id))
        .then(res => {
          if (res.data.message) {
            return setSnackbar({ text: res.data.message, color: 'error' })
          }
          openDocument({
            url: res.data.url,
            text: 'Рахунок на аванс',
          })
        })
        .catch(this.$err)
    },
    getDFLFormData() {
      return this.$axios
        .get(this.urlGetDFLPrintForm(this.$route.params.id))
        .then(res => {
          if (res.data.message)
            return this.$setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          Object.assign(this.DFLPrintData, res.data)
        })
        .catch(this.$err)
    },
    getContract() {
      if (!this.contractId) return
      if (this.v$.$reset) {
        this.v$.$reset()
      }
      fillDirectory('dflStatuses')
      this.$store
        .dispatch('getDflContract', this.contractId)
        .then(() => {
          if (!this.contract.message) {
            this.setDflContractLesseeSignatoryId()
            this.setStoreCache()
            this.setBreadScrumb()
          }
        })
        .catch(err => {
          this.$setSnackbar({ text: err, color: 'error' })
          this.$store.commit('setDflContractState', false)
          throw err
        })
    },
  },
  watch: {
    route() {
      this.setBreadScrumb()
    },
    v$: {
      deep: true,
      handler(v) {
        this.$store.commit('setDflContractValidation', v)
      },
    },
    'contract.otherDocuments': function (v) {
      if (v !== null && v.length === 0) this.contract.otherDocuments = null
    },
  },
  created() {
    this.getContract()
  },
}
</script>

<style scoped lang="scss">
a.v-tab-item--selected.v-tab {
  color: #08487a !important;
  font-weight: 700 !important;
  font-size: 14px;
  letter-spacing: normal;
  padding-left: 0;
  padding-right: 0;
}
a.v-tab {
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
  color: #838282 !important;
  letter-spacing: normal !important;
}
.v-slide-group__wrapper {
  background: #fff !important;
}
.background-loader {
  background: #ffffff8f;
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100000;
}
.loader {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(50%, 50%);
  width: 48px;
  height: 13px;
  font-size: 10px;
  > div {
    height: 100%;
    width: 9px;
    display: inline-block;
    background-color: #3498db;
    animation: load 1.2s infinite ease-in-out;
  }
  .rect2 {
    animation-delay: -1s;
  }
  .rect3 {
    animation-delay: -0.8s;
  }
}

@keyframes load {
  0%,
  40%,
  100% {
    transform: scaleX(0.8);
  }
  20% {
    transform: scaleX(1);
    background-color: #0870b2;
  }
}
</style>
