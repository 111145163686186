<template>
  <div>
    <application-list v-if="$route.name === 'applications'"></application-list>
    <router-view v-else :key="$route.params.applicationId"></router-view>
  </div>
</template>

<script>
import ApplicationList from './ApplicationList.vue'

export default {
  name: 'ApplicationIndex',
  components: { ApplicationList },
}
</script>

<style scoped></style>
