<template>
  <div>
    <v-autocomplete
      v-model="currentAccountId"
      hide-details
      placeholder="Оберіть зі списку"
      :item-title="formatAccount"
      item-value="id"
      :error-messages="currentAccountIdErr"
      :items="dialog.dialogItem.currentAccounts || []"
      @blur="v$.currentAccountId.$touch()">
    </v-autocomplete>
  </div>
</template>

<script>
import { urlUpdateExtraAgreementEditorShip } from '@/pages/request'
import { setErrHandler } from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { required } from '@vuelidate/validators'

export default {
  props: {
    dialog: { type: Object },
  },
  validations() {
    return {
      currentAccountId: { required },
    }
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  data() {
    return {
      currentAccountId: null,
    }
  },
  computed: {
    validation() {
      return { v$: this.v$, name: 'editorship' }
    },
    currentAccountIdErr() {
      return setErrHandler(this.v$.currentAccountId)
    },
  },
  methods: {
    formatAccount(item) {
      return `${item.bankName} (${item.accountNumber})`
    },
    submit() {
      return this.$axios.post(
        urlUpdateExtraAgreementEditorShip(this.dialog.dialogItem.id),
        {
          editorshipId: this.dialog.dialogItem.editorshipId,
          currentAccountId: this.currentAccountId,
        }
      )
    },
  },
  created() {
    if (this.dialog.dialogItem) {
      this.currentAccountId = this.dialog.dialogItem.currentAccountId
    }
  },
}
</script>

<style scoped></style>
