<template>
  <v-col v-if="area" cols="12" md="12" sm="12" class="pt-0">
    <span class="label">Район</span>
    <v-text-field :value="area" dense readonly hide-details>
      <template v-if="city.AreaOld && city.AreaOld !== city.Area" #append>
        <toggle-old-name
          :model-value="city.useOldArea"
          :new-name="city.Area"
          :old-name="city.AreaOld"
          @update:model-value="
            $emit('update:city', { ...city, useOldArea: $event })
          "></toggle-old-name>
      </template>
    </v-text-field>
  </v-col>
</template>

<script lang="ts">
import ToggleOldName from './ToggleOldName.vue'
import { computed, PropType } from 'vue'
import { City } from '@/utils/types'
import { getAddressPartName } from '../utils'

export default {
  name: 'Area',
  emits: ['update:city'],
  components: { ToggleOldName },
  props: {
    city: {
      type: Object as PropType<City>,
      required: true,
    },
  },
  setup(props) {
    const area = computed<string>(() => {
      return getAddressPartName(
        props.city.Area,
        props.city.AreaOld,
        props.city.useOldArea
      )
    })

    return { area }
  },
}
</script>

<style scoped></style>
