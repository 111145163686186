<template>
  <div>
    <b-section-loader
      v-if="$loading.isLoading('getCalculationList')"></b-section-loader>
    <div v-else>
      <v-btn
        color="#fc7247"
        variant="flat"
        class="text-white no-transform no-border mb-4"
        :disabled="!!application.readOnly"
        @click="
          $router.push({
            name: 'application-create-calculation',
            params: { applicationId: application.id },
          })
        ">
        <v-icon class="mr-2">mdi-plus</v-icon>
        ДОДАТИ РОЗРАХУНОК
      </v-btn>

      <v-data-table
        :headers="applicationCalculationHeaders"
        :items="calculationList"
        :mobileBreakpoint="1230"
        :hide-default-header="!calculationList.length"
        hide-default-footer
        :items-per-page="-1">
        <template #item.requestId="{ item }">
          <div :class="[item.isAgreed ? '' : '']">
            <v-tooltip
              v-if="item.isAgreed || item.isSendOffer"
              bottom
              :text="
                item.isAgreed ? 'Погоджена калькуляція' : 'Відправлено КП'
              ">
              <template #activator="{ props }">
                <v-icon
                  class="mr-1"
                  :size="20"
                  :color="
                    item.isAgreed ? '#3cb371' : item.isSendOffer ? 'orange' : ''
                  "
                  v-bind="props">
                  {{
                    item.isAgreed
                      ? 'mdi-check-circle'
                      : item.isSendOffer
                      ? 'mdi-email-check'
                      : ''
                  }}
                </v-icon>
              </template>
              <span v-if="item.isAgreed">Погоджена калькуляція</span>
              <span v-if="item.isSendOffer">Відправлено КП</span>
            </v-tooltip>
            <ALink
              :text="item.requestId"
              :route="{
                name: 'application-edit-calculation',
                params: {
                  calculationId: item.id,
                  applicationId: item.applicationId,
                },
              }">
            </ALink>
          </div>
        </template>
        <template #item.created_at="{ item }">
          <span v-if="item.isAgreed">
            {{ new CustomDate(item.agreedDate).toString({ time: true }) }}
          </span>
          <span v-else>
            {{ new CustomDate(item.created_at).toString({ time: true }) }}
          </span>
        </template>
        <template #item.advance="{ item }">
          <span>
            {{
              getAdvanceSum(
                item.requestData.advance,
                item.requestData.leasingAmount
              )
            }}
          </span>
        </template>
        <template #item.requestData.leasingAmount="{ item }">
          <span>
            {{
              rankSpacing(item.requestData.leasingAmount) +
              ' ' +
              getCurrencySign(item.requestData.leasingCurrency)
            }}
          </span>
        </template>
        <template #item.actions="{ item }">
          <TableCrud
            data-readonly="hide"
            style="
              padding-right: 0 !important;
              margin-right: -15px !important;
              padding-left: 15px !important;
            "
            :color="
              item.isAgreed
                ? 'green darken-2'
                : item.isSendOffer
                ? 'orange darken-1'
                : ''
            "
            :actionsArray="[
              {
                action: () =>
                  $router.push({
                    name: 'application-payment-plan',
                    params: {
                      calculationId: item.id,
                      applicationId: application.id,
                    },
                  }),
                text: 'Переглянути',
                icon: 'mdi-eye',
              },
              {
                action: () =>
                  item.isAgreed
                    ? rejectCalculation(item)
                    : approveCalculation(item),
                text: item.isAgreed ? 'Відмiнити погодження' : 'Погодити',
                icon: item.isAgreed
                  ? 'mdi-close-circle-outline'
                  : 'mdi-checkbox-marked-circle-outline',
                color: item.isAgreed ? 'red lighten-2' : '',
                disabled: !!application.readOnly,
              },
              {
                action: () =>
                  $router.push({
                    name: 'application-edit-calculation',
                    params: {
                      calculationId: item.id,
                      applicationId: application.id,
                    },
                  }),
                text: 'Редагувати',
                icon: 'mdi-pencil',
                disabled: !!application.readOnly && !!item.readOnly,
              },
              {
                action: () => downloadOffer(item),
                text: 'Зберегти',
                icon: 'mdi-download',
                disabled: !isAdmin,
              },
              {
                action: () => createCalculationCopy(item),
                text: 'Створити копію',
                icon: 'mdi-content-save',
                disabled: !!application.readOnly,
              },
              {
                action: () => sendOffer(item),
                text: item.isSendOffer ? 'Відправлено' : 'Відправити',
                icon: 'mdi-email-fast',
                iconColor: item.isSendOffer ? 'orange darken-1' : '',
                disabled: !!application.readOnly,
              },
              {
                action: () => deleteCalculation(item.id),
                text: 'Видалити',
                icon: 'mdi-delete',
                statement: !item.isAgreed && !item.isSendOffer,
                disabled: !!application.readOnly,
                loading: $loading.isLoading(`deleteCalculation_${item.id}`),
              },
            ]">
          </TableCrud>
        </template>
      </v-data-table>
    </div>
    <send-offer
      v-model:sendOfferDialog="downloadOfferDialog"
      :clientOffer="{
        ...downloadOfferDialogData,
        application: application,
      }"
      dialogAction="downloadOffer"
      :cb="updateCalculationList">
    </send-offer>
  </div>
</template>

<script lang="ts">
import { BSectionLoader } from 'best-modules/components'
import { computed, inject, ref } from 'vue'
import {
  applicationKey,
  calculationListKey,
  updateApplicationObjectKey,
  updateCalculationListKey,
} from '../injectionKeys'
import { CachedObjectProxy, CustomDate, rankSpacing } from 'best-modules/utils'
import { Application } from '@/utils/types/application'
import TableCrud from '@/components/table-crud.vue'
import ALink from '@/components/Link.vue'
import { applicationCalculationHeaders } from '@/utils/tableHeaders'
import { getCurrencySign } from '@/utils/helperFunc'
import { useStore } from 'vuex'
import { urlCopyCalculation, urlDeleteCalculation } from '@/pages/request'
import { setSnackbar, confirm, handleAsync } from 'best-modules/plugins'
import axios from '@/plugins/axios.js'
import sendOffer from '@/components/send-offer.vue'

export default {
  name: 'Calculations',
  components: { sendOffer, ALink, TableCrud, BSectionLoader },
  data: () => {
    return {
      applicationCalculationHeaders,
      CustomDate,
    }
  },
  methods: { getCurrencySign, rankSpacing },
  setup() {
    const store = useStore()
    const isAdmin = computed(() => store.state.user.roleId === 1)
    const updateApplicationObject = inject(updateApplicationObjectKey)
    const application = computed<CachedObjectProxy<Application>>({
      get: () => inject(applicationKey),
      set: updateApplicationObject,
    })

    const calculationList = inject(calculationListKey)
    const updateCalculationList = inject(updateCalculationListKey)

    const getAdvanceSum = (advPer: string, amount: string) => {
      if (!advPer || !amount) return 0
      const am = parseInt(amount)
      const adv = parseInt(advPer)
      return rankSpacing((am / 100) * adv)
    }

    const createCalculationCopy = item => {
      if (!item.isAgreed) {
        axios.get(urlCopyCalculation(item.id)).then(res => {
          updateCalculationList()
          setSnackbar({ text: 'Калькуляція успішно скопійована' })
          return res
        })
      }
    }

    const approveCalculation = calculation => {
      store.commit('setDialog', {
        title: 'Погодити розрахунок',
        dialogItem: calculation,
        action: 'approveCalculation',
        params: {
          cb: updateCalculationList,
          hideActions: true,
        },
      })
    }
    const rejectCalculation = calculation => {
      store.commit('setDialog', {
        title: 'Відмінити погодження',
        dialogItem: calculation,
        action: 'rejectCalculation',
        params: { cb: updateCalculationList },
      })
    }

    const deleteCalculation = (calculationId: number) => {
      return confirm({ text: 'Розрахунок буде видалено на завжди!' }).then(
        () => {
          return handleAsync(`deleteCalculation_${calculationId}`, () => {
            return axios
              .delete(urlDeleteCalculation(calculationId))
              .then(() => {
                setSnackbar({ text: 'Рохрахунок видалено' })
                updateCalculationList()
              })
          })
        }
      )
    }

    const sendOffer = calculation => {
      store.commit('setDialog', {
        title: 'Відправити КП',
        action: 'sendOffer',
        dialogItem: {
          calculation,
          application: application.value.$object,
        },
        params: {
          hideToolbar: true,
          hideActions: true,
          dialogWidth: 850,
        },
      })
    }

    const downloadOfferDialog = ref<boolean>(false)
    const downloadOfferDialogData = ref({})

    const downloadOffer = calculation => {
      downloadOfferDialogData.value = calculation
      downloadOfferDialog.value = true
    }

    return {
      application,
      calculationList,
      getAdvanceSum,
      isAdmin,
      createCalculationCopy,
      approveCalculation,
      rejectCalculation,
      deleteCalculation,
      sendOffer,
      downloadOffer,
      updateCalculationList,
      downloadOfferDialog,
      downloadOfferDialogData,
    }
  },
}
</script>

<style scoped></style>
