<template>
  <div>
    <SectionLoader v-if="loading"></SectionLoader>
    <div v-if="!loading">
      <v-dialog
        v-model="dialogData.calculation"
        fullscreen
        :scrim="false"
        transition="dialog-bottom-transition">
        <v-toolbar
          style="
            color: #08487a;
            font-weight: 700 !important;
            font-size: 16px;
            background-color: #fff;
            box-shadow: none;
          "
          :height="60"
          dark>
          <v-toolbar-title style="font-size: 1rem">
            {{ 'Зміна калькуляції' }}:
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn size="small" icon @click="() => closeDialog('calculation')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none">
              <path
                d="M25 13C25 6.375 19.625 1 13 1C6.375 1 1 6.375 1 13C1 19.625 6.375 25 13 25C19.625 25 25 19.625 25 13Z"
                stroke="#C0C1C7"
                stroke-width="2"
                stroke-miterlimit="10" />
              <path
                d="M17 17L9 9M9 17L17 9"
                stroke="#C0C1C7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </v-btn>
        </v-toolbar>
        <div style="background: #f6f6f6; min-height: 100vh; overflow: auto">
          <RouterView
            v-if="$route.matched.some(r => r.name === `du-dfl-calculation`)"
            :calculationData="calculation"
            :calculateUrl="urlDuChangeCalculation(calculation.id)"
            @calculate="e => closeDialog('calculation', e)" />
        </div>
      </v-dialog>
      <v-dialog v-model="dialogData.editorship" max-width="700" persistent>
        <div style="background: white; padding: 10px">
          <div
            style="
              color: #08487a;
              font-weight: 700 !important;
              font-size: 16px;
              background-color: #fff;
              box-shadow: none;
              padding-left: 0;
              height: 40px;
              align-items: center;
              display: flex;
            ">
            <div
              style="
                display: flex;
                justify-content: space-between;
                width: 100%;
              ">
              <v-toolbar-title style="font-size: 1rem" class="pl-0">
                {{ 'Редакція' }}:
              </v-toolbar-title>
              <v-btn
                :readonly="readonly"
                size="small"
                icon
                @click="() => closeDialog('editorship')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none">
                  <path
                    d="M25 13C25 6.375 19.625 1 13 1C6.375 1 1 6.375 1 13C1 19.625 6.375 25 13 25C19.625 25 25 19.625 25 13Z"
                    stroke="#C0C1C7"
                    stroke-width="2"
                    stroke-miterlimit="10" />
                  <path
                    d="M17 17L9 9M9 17L17 9"
                    stroke="#C0C1C7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </v-btn>
            </div>
          </div>

          <div style="background: white">
            <v-textarea
              v-model="dialogData.editorshipText"
              placeholder="Введіть текст"
              hide-details
              :disabled="readonly"
              :row="4" />
            <ActionButtons
              :readonly="readonly"
              style="justify-content: end"
              confirm-btn-text="Підтвердити"
              cancel-btn-text="Скасувати"
              :confirm="addEditorship"
              :cancel="() => closeDialog('editorship')" />
          </div>
        </div>
      </v-dialog>
    </div>
    <v-row v-if="!loading">
      <v-col cols="12" md="3" sm="12">
        <FieldsColumnBlock title="Основні дані">
          <template #general-fields>
            <Fields :fields="fields" :type="2" direction="column" />
          </template>
          <template #default>
            <div class="mb-2">
              <span class="label">Вид підпису</span>
              <v-select
                v-model="agreement.signingTypeId"
                :items="$directory.get('signingTypes', agreement.signingType)"
                item-title="name"
                item-value="id"
                hide-details
                :readonly="readonly"
                :disabled="readonly"
                placeholder="Оберіть вид"
                :error-messages="getErrorMessages(v$.agreement.signingTypeId)"
                :loading="$loading.isLoading('signingTypes')"
                @focus="$directory.fill('signingTypes')"
                @blur="v$.agreement.signingTypeId.$touch()">
              </v-select>
            </div>
          </template>
        </FieldsColumnBlock>
        <FieldsColumnBlock title="Візування" :fullHeight="!mobile">
          <template #agreements>
            <Agreements :agreements="agreement.agreements" class="mb-2" />
          </template>
        </FieldsColumnBlock>
      </v-col>
      <v-col cols="12" md="9" sm="12" :class="`${mobile ? '' : 'pl-0'}`">
        <div class="white-block" style="padding-left: 7px">
          <ContractStatusBar
            class="mb-3"
            :contract="agreement"
            :mobile="mobile"
            :currentStatus="agreement.statusId"
            :isCancelContract="agreement.isCancelContract"
            :isTerminated="agreement.isTerminated"
            :submit="setExtraAgreementStatus"
            :statuses="filteredStatuses"
            spreadItems
            small>
            <template #actions>
              <ContractActionButton :actions="btnActions" />
            </template>
          </ContractStatusBar>
          <v-tabs
            v-model="currentTab"
            class="dkpTabs"
            show-arrows
            color="white"
            dark
            dense
            slider-color="#fc7247"
            :height="$vuetify.breakpoint?.xs ? null : 38">
            <v-tab
              replace
              :to="{
                name: `du-dfl-parties-requisites`,
                params: { id: $route.params.id },
              }">
              Реквізити
            </v-tab>
            <v-tab
              replace
              :to="{
                name: `du-dfl-calculation-info`,
                params: { id: $route.params.id },
              }">
              Калькуляція
            </v-tab>
            <v-tab
              v-if="agreement.isChangeCalculation"
              replace
              :to="{
                name: `du-dfl-payment-history`,
                params: { id: $route.params.id },
              }">
              Графік платежів
            </v-tab>
            <v-tab
              replace
              :to="{
                name: `du-dfl-agreements`,
                params: { id: $route.params.id },
              }">
              Договори
            </v-tab>
            <v-tab
              replace
              :to="{
                name: `du-dfl-editorships`,
                params: { id: $route.params.id },
              }">
              Редакція договору
            </v-tab>
            <v-tab
              replace
              :to="{
                name: `du-dfl-history`,
                params: { id: $route.params.id },
              }">
              Історія
            </v-tab>
          </v-tabs>
        </div>
        <router-view
          v-if="!routeCalculation"
          :agreement="agreement"
          :currency="currency"
          :calculation="calculation"
          :lo="LO"
          :mobile="mobile"
          :isAdmin="isAdmin"
          :readonly="readonly"
          @open-dialog="openDialog($event)"></router-view>
        <ActionButtons
          class="mt-10"
          :confirm="submit"
          :cancel="getExtraAgreement"
          :confirmLoading="submitLoading"
          :cancelDisable="submitLoading || agreementState === cache"
          :confirmDisable="agreementState === cache">
        </ActionButtons>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'
import {
  LODescription as getLoDescription,
  setCache,
  setErrHandler,
  v$Notify,
  getBeautyNum,
  downloadPrintedForm,
  toFormatDate,
  getEntityName,
} from '@/utils/helperFunc'
import {
  urlUpdateExtraAgreementDfl,
  urlGetExtraAgreementPrintForm,
  urlSetCancelContractExtra,
  urlSetExtraAgreementStatus,
  urlDuChangeCalculation,
  urlExtraAgreementPrintedFormAdvance,
} from '@/pages/request'
import { mapState } from 'vuex'
import { required, minLength } from '@vuelidate/validators'
import Fields from '@/components/Fields.vue'
import { useVuelidate } from '@vuelidate/core'
import { confirm } from 'best-modules/plugins'
import { duTypeKey } from '../injectionKeys.ts'
import { fillDirectory, getDirectory } from '@/plugins/directory/index.ts'
import FieldsColumnBlock from '@/pages/contracts/components/FieldsColumnBlock.vue'
import ContractStatusBar from '@/components/contracts/ContractStatusBar.vue'
import Agreements from '@/components/Agreements.vue'
import ContractActionButton from '@/pages/contracts/components/ContractActionButton.vue'
import { useDisplay } from 'vuetify'
import { useSelect } from '@/utils/mixins/useSelect'
import { computed } from 'vue'
import { getErrorMessages } from 'best-modules/utils'

export default {
  components: {
    ContractActionButton,
    Agreements,
    ContractStatusBar,
    FieldsColumnBlock,
    Fields,
    SectionLoader,
    ActionButtons,
  },
  setup() {
    const { xs, sm } = useDisplay()
    const mobile = computed(() => xs.value || sm.value)

    return { v$: useVuelidate(), ...useSelect(), mobile }
  },
  inject: {
    duType: {
      from: duTypeKey,
    },
  },
  validations() {
    return {
      agreement: {
        date: { required, minLength: minLength(10) },
        typeId: { required },
        lessorSignatoryId: { required },
        lessorBasisId: { required },
        signingTypeId: { required },
        lesseeSignatories: {
          required,
          minLength: minLength(1),
          $each: {
            contactId: { required },
            basisId: { required },
          },
        },
      },
    }
  },
  data: () => ({
    submitLoading: false,
    agreedCalculations: [],
    currentTab: 0,
    dialogData: {
      editorshipText: null,
      editorship: null,
      calculation: null,
    },
  }),
  computed: {
    ...mapState({
      agreement: state => state.contracts.extraAgreement,
      loading: state => state.contracts.extraAgreementLoading,
      selectItems: state => state.selectItems,
      cache: state => state.contracts.extraAgreementCache,
      calculation: state => state.contracts.extraAgreement?.calculation,
      dfl: state => state.contracts?.extraAgreement?.contract,
      user: state => state.user,
      lessee: state => state.contracts.dflContract.lessee,
    }),
    pfForms() {
      return [
        {
          action: () =>
            downloadPrintedForm(
              urlGetExtraAgreementPrintForm(this.agreement.id),
              this.agreement.number
            ),
          label: 'Додаткова угода',
          icon: 'mdi-file-pdf-box',
        },
        {
          action: () =>
            downloadPrintedForm(
              urlExtraAgreementPrintedFormAdvance(this.agreement.id),
              `Рахунок на аванс ${this.agreement.number}`
            ),
          label: 'Рахунок на аванс',
          icon: 'mdi-file-pdf-box',
          disabled: !this.agreement.isChangeCalculation,
        },
      ]
    },
    btnActions() {
      return {
        main: { label: 'Дії', disabled: false },
        list: [
          {
            show: this.agreement.statusId !== 5,
            label: 'Анулювати договір',
            icon: 'mdi-file-document-remove-outline',
            action: () => this.setCancel(),
            disabled:
              (!this.isAdmin && this.readonly) ||
              this.agreement.isCancelContract,
          },
          {
            show: true,
            label: 'Друковані форми',
            icon: 'mdi-printer',
            actions: this.pfForms,
            disabled: false,
          },
        ],
      }
    },
    filteredStatuses() {
      const filteredStatuses = getDirectory('dflStatuses').filter(item => {
        if (item.id === 6) {
          return this.agreement.isCancelContract
        }
        if (item.id === 7) {
          return false
        }
        return true
      })
      return filteredStatuses.map(s => {
        let showCheckIcon
        if (this.agreement.statusId === 6) {
          showCheckIcon = s.id === 6
        }

        return { ...s, showCheckIcon }
      })
    },
    fields() {
      return [
        { label: 'Тип договору', value: this.agreement.type?.name },
        {
          label: 'Дата договору',
          value: toFormatDate(this.agreement.date, true),
        },
        { label: 'Валюта', value: this.LO?.financingCurrency },
        { label: '№ Договору', value: this.agreement.number },
        {
          label: 'ДФЛ',
          value: this.agreement.contract?.number,
          class: { link: true },
          click: () => {
            this.$router.push({
              name: 'dfl-contract',
              params: { id: this.agreement.contract?.id },
            })
          },
        },
      ]
    },
    currency() {
      const symbol = (() => {
        switch (this.calculation?.requestData?.leasingCurrency) {
          case 'UAH':
            return 'грн'
          case 'USD':
            return '$'
          case 'EUR':
            return '€'
        }
      })()
      return {
        uah: this.calculation?.requestData?.leasingCurrency === 'UAH',
        symbol: symbol,
      }
    },
    agreementId() {
      return this.$route?.params?.id
    },
    agreementState() {
      const obj = Object.clone(this.agreement)
      delete obj.calculation
      return this.setCache([obj])
    },
    LO() {
      if (this.calculation) {
        return this.getLoDescription(this.calculation)
      } else {
        return null
      }
    },
    typeIdErr() {
      return this.setErrHandler(this.v$?.agreement?.typeId)
    },
    route() {
      return this.$route.name
    },
    isAdmin() {
      return this.user.roleId === 1
    },
    readonly() {
      return this.agreement.readOnly
    },
    routeCalculation() {
      return this.$route.name.includes('edit-calculator')
    },
  },
  methods: {
    getErrorMessages,
    urlExtraAgreementPrintedFormAdvance,
    setCache,
    setErrHandler,
    getLoDescription,
    urlUpdateExtraAgreementDfl,
    urlGetExtraAgreementPrintForm,
    urlDuChangeCalculation,
    downloadPrintedForm,
    getBeautyNum,
    openDialog(dialogName) {
      if (dialogName === 'editorship') {
        this.dialogData.editorshipText = this.agreement.editorship
      }
      this.dialogData[dialogName] = true
    },
    closeDialog(dialogName, payload) {
      if (dialogName === 'calculation' && payload) {
        this.$store.commit('setExtraAgreementCalculation', payload)
      }
      this.dialogData[dialogName] = false
    },
    // openExtraPfDialog() {
    //   this.$store.commit('setDialog', {
    //     title: 'Оберіть розрахунковий рахунок',
    //     dialogItem: {
    //       contractId: this.agreementId,
    //       accounts: this.agreement?.contract?.lessor.currentAccounts,
    //     },
    //     params: {
    //       submitText: 'Сформувати',
    //       cb: this.getExtraAgreement,
    //     },
    //     action: 'selectPrintedForm',
    //   })
    // },
    getExtraAgreement() {
      this.$store.dispatch('addAgreementTypes')
      this.$store
        .dispatch('getExtraAgreement', {
          id: this.agreementId,
          duType: this.duType,
        })
        .then(() => {
          this.setStoreCache()
          this.setBreadScrumb()
        })
        .catch(this.$err)
    },
    setBreadScrumb(res) {
      this.$store.commit('setBreadScrumbPart', [
        null,
        null,
        `${getEntityName(this.agreement.lessee)} - ${this.agreement.number}`,
      ])
      return res
    },
    setCancel() {
      confirm({
        text: 'Ви дійсно бажаєте анулювати договір?',
      }).then(() => {
        return this.$axios
          .post(urlSetCancelContractExtra(this.agreement.id), {
            isCancelContract: true,
          })
          .then(res => {
            this.$setSnackbar({ text: 'Оновлено' })
            Object.assign(this.agreement, res.data)
            this.setCache([this.agreement])
            this.setStoreCache()

            return res
          })
      })
    },
    setStoreCache(res) {
      const obj = Object.clone(this.agreement)
      delete obj.calculation
      this.$store.commit('setExtraAgreementCache', this.setCache([obj]))
      return res
    },
    async setExtraAgreementStatus(statusId) {
      if (this.v$.$invalid) {
        return Promise.reject(v$Notify(this.v$.agreement, 'extraAgreement'))
      }

      const currentStatus = this.agreement.statusId

      if (currentStatus === 5) {
        return this.$setSnackbar({
          text: 'Договір з статусу підписаного не може бути змiнено',
          color: 'warning',
        })
      }
      if (
        [4, 5].includes(statusId) &&
        this.agreement.agreements?.some(a => !a.isAgreed)
      ) {
        return this.$setSnackbar({
          text: 'Договір повинен бути завізований всiма учасниками',
          color: 'warning',
        })
      }
      if (statusId < currentStatus || statusId > currentStatus + 1) {
        return this.$setSnackbar({ text: 'Дія неможлива', color: 'error' })
      }

      if ([2, 3].includes(statusId)) {
        try {
          const statusNames = {
            2: 'Підписано клієнтом',
            3: 'Візування',
          }
          await confirm({
            text: `Ви впевнені що хочете перевести договір в статус "${statusNames[statusId]}"?`,
          })
        } catch (e) {
          return
        }
      }

      return this.$axios
        .post(urlSetExtraAgreementStatus(this.agreementId), {
          statusId: statusId,
        })
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else this.$setSnackbar({ text: 'Статус успішно змiнено' })
          this.getExtraAgreement()
          return res
        })
        .catch(this.$err)
    },
    submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(this.v$.agreement, 'extraAgreement')
        return Promise.resolve()
      }

      const requestObj = Object.clone(this.agreement)
      if (requestObj.lesseeSignatories)
        requestObj.lesseeSignatories = requestObj.lesseeSignatories.map(s => ({
          contactId: s.contactId,
          basisId: s.basisId,
        }))

      this.submitLoading = true
      return this.$axios
        .post(this.urlUpdateExtraAgreementDfl(this.agreementId), requestObj)
        .then(res => {
          if (res.data.message) throw new Error(res.data.message)
          this.submitLoading = false
          this.setStoreCache()
          this.$setSnackbar({ text: 'Дані оновлено' })
          return res
        })
        .catch(err => this.$err(err, () => (this.submitLoading = false)))
    },
  },
  watch: {
    route() {
      this.setBreadScrumb()
    },
    v$: {
      deep: true,
      handler(v) {
        this.$store.commit('agreementValidity', v)
      },
    },
    'dialogData.calculation': {
      handler(val) {
        if (val) {
          const calculationId = this.calculation.id
          const applicationId = this.calculation.applicationId

          this.$router.replace({
            name: 'du-dfl-calculation',
            params: { calculationId, applicationId },
          })
        } else {
          this.$router.push({ name: 'du-dfl-parties-requisites' })
        }
      },
      immediate: true,
    },
  },
  created() {
    fillDirectory('dflStatuses')
    this.getExtraAgreement()
  },
}
</script>

<style scoped>
.label {
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 12px;
  color: rgb(131, 130, 130);
}
.label-bottom {
  color: #000;
  font-weight: 700;
  font-size: 14px;
}
a.v-tab-item--selected.v-tab {
  color: #08487a !important;
  font-weight: 700 !important;
  font-size: 14px;
  letter-spacing: normal;
  padding-left: 0;
  padding-right: 0;
}
a.v-tab {
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
  color: #838282 !important;
  letter-spacing: normal !important;
}
.dkpTabs .v-slide-group__wrapper {
  background: #fff !important;
}
</style>
