<template>
  <v-col cols="12" md="12" sm="12" class="pt-0">
    <span class="label">Місто/Село</span>
    <v-autocomplete
      v-model="city"
      v-mask:[mask]
      :items="cities"
      :item-title="getCityTitle"
      item-value="Id"
      :loading="$loading.isLoading('getCities')"
      :error-messages="errorMessages"
      placeholder="Оберіть зі списку"
      no-filter
      return-object
      hide-details
      @update:search="getCities"
      @blur="$emit('blur')">
      <template #item="{ item, props }">
        <v-list-item v-bind="props" :title="getCityItemTitle(item.raw)">
        </v-list-item>
      </template>
      <template v-if="city?.CityOld && city.CityOld !== city.City" #append>
        <toggle-old-name
          v-model="city.useOldCity"
          :new-name="city.City"
          :old-name="city.CityOld"></toggle-old-name>
      </template>
    </v-autocomplete>
  </v-col>
</template>

<script lang="ts">
import { computed, PropType, ref } from 'vue'
import { City } from '@/utils/types'
import { debounce } from 'lodash'
import { handleAsync } from 'best-modules/plugins'
import { getCities as getCitiesRequest } from '@/utils/addressApi'
import ToggleOldName from './ToggleOldName.vue'
import { concatRegex, SPACE, CYRILLIC } from 'best-modules/utils/regExp'
import { getCityTemplate } from '../address'
import { getAddressPartName } from '../utils'

export default {
  name: 'City',
  components: { ToggleOldName },
  props: {
    modelValue: {
      type: Object as PropType<City>,
      required: true,
    },
    errorMessages: {
      type: Array as PropType<string[]>,
    },
  },
  data: () => {
    return {
      mask: {
        mask: 'A'.repeat(100),
        tokens: {
          A: { pattern: concatRegex(CYRILLIC, SPACE, '-') },
        },
      },
    }
  },
  emits: ['update:model-value', 'blur'],
  setup(props, { emit }) {
    const city = computed<City | null>({
      get() {
        if (props.modelValue.Id) {
          return props.modelValue
        }
        return null
      },
      set(v) {
        emit('update:model-value', v || getCityTemplate())
      },
    })

    const getCityTitle = (city: City | null) => {
      if (city) {
        return getAddressPartName(city.City, city.CityOld, city.useOldCity)
      } else {
        return null
      }
    }
    const getCityItemTitle = (c: City): string => {
      const region = c.Region !== c.City ? c.Region : null
      const city = getAddressPartName(c.City, c.CityOld, c.useOldCity)
      return [region, city, c.Area].filter(Boolean).join(', ')
    }

    const cities = ref<Array<City>>([])

    const getCities = debounce((search: string) => {
      return handleAsync('getCities', async () => {
        if (search && search !== getCityTitle(city.value)) {
          cities.value = await getCitiesRequest(search)
        }
      })
    }, 500)

    return { city, cities, getCities, getCityTitle, getCityItemTitle }
  },
}
</script>

<style scoped></style>
