<template>
  <div>
    <ListSection
      :addAction="() => openDialog('create')"
      addActionTitle="Додати паспорт"
      :addActionDisabled="readonly || !!localPassport"
      title="Документ, що посвідчує особу">
      <template #body>
        <v-data-table
          :headers="passportHeaders"
          :items="localPassport ? [localPassport] : []"
          class="custom-table head-line"
          dense
          :hide-default-header="!localPassport"
          hide-default-footer>
          <!-- eslint-disable-next-line -->
          <template #item.type="{ item }">
            <span>{{ getSafe(() => item.type.name) }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.serial="{ item }">
            <span>{{ item.serial || '---' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.unzr="{ item }">
            <span>{{ item.unzr || '---' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.number="{ item }">
            <span>{{ item.number || '---' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.issuedBy="{ item }">
            <span>{{ item.issuedBy || '---' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.sellBy="{ item }">
            <span>{{ toFormatDate(item.sellBy) || '---' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.issueDate="{ item }">
            <span>{{ toFormatDate(item.issueDate) || '---' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <div style="margin-right: -10px">
              <TableCrud
                :actionsArray="[
                  {
                    action: () => openDialog('edit'),
                    text: 'Редагувати',
                    icon: 'mdi-pencil',
                    disabled: readonly,
                  },
                  {
                    action: deletePassport,
                    text: 'Видалити',
                    icon: 'mdi-delete',
                    disabled: readonly,
                  },
                ]">
              </TableCrud>
            </div>
          </template>
        </v-data-table>
      </template>
    </ListSection>
  </div>
</template>

<script>
import ListSection from '@/components/list-section.vue'
import { urlDeleteContactPassport } from '@/pages/request'
import { getSafe, toFormatDate } from '@/utils/helperFunc'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: {
    TableCrud,
    ListSection,
  },
  emits: ['identityDocumentUpdated'],
  props: {
    identityDocument: { type: Object },
    readonly: { type: Boolean },
    contactId: { type: Number },
  },
  data() {
    return {
      localPassport: null,
      fav: true,
      menu: false,
    }
  },
  computed: {
    passportHeaders() {
      const idCard = [
        {
          title: 'Тип документу',
          key: 'type',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Запис №',
          key: 'unzr',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Документ №',
          key: 'number',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Орган, що видав',
          key: 'issuedBy',
          sortable: false,
          align: 'start',
        },
        { title: 'Дійсний до', key: 'sellBy', sortable: false, align: 'start' },
        {
          title: 'Дата видачі',
          key: 'issueDate',
          sortable: false,
          align: 'start',
        },
        { title: 'Дії', key: 'actions', sortable: false, align: 'end' },
      ]
      const interNationalDocument = [
        {
          title: 'Тип документу',
          key: 'type',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Серія',
          key: 'serial',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Документ №',
          key: 'number',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Орган, що видав',
          key: 'issuedBy',
          sortable: false,
          align: 'start',
        },
        { title: 'Дійсний до', key: 'sellBy', sortable: false, align: 'start' },
        {
          title: 'Дата видачі',
          key: 'issueDate',
          sortable: false,
          align: 'start',
        },
        { title: 'Дії', key: 'actions', sortable: false, align: 'end' },
      ]
      const oldCard = [
        {
          title: 'Тип документу',
          key: 'type',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Серія',
          key: 'serial',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Номер',
          key: 'number',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Орган, що видав',
          key: 'issuedBy',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Дата видачі',
          key: 'issueDate',
          sortable: false,
          align: 'start',
        },
        { title: 'Дії', key: 'actions', sortable: false, align: 'end' },
      ]
      const otherDocument = [
        {
          title: 'Тип документу',
          key: 'type',
          sortable: false,
          align: 'start',
        },
        {
          title: '№ запису',
          key: 'unzr',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Документ №',
          key: 'number',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Дата видачі',
          key: 'issueDate',
          sortable: false,
          align: 'start',
        },
        { title: 'Дійсний до', key: 'sellBy', sortable: false, align: 'start' },
        { title: 'Дії', key: 'actions', sortable: false, align: 'end' },
      ]
      const temporaryDocument = [
        {
          title: 'Тип документу',
          key: 'type',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Назва документу',
          key: 'serial',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Документ №',
          key: 'number',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Орган, що видав',
          key: 'issuedBy',
          sortable: false,
          align: 'start',
        },
        {
          title: 'Дата видачі',
          key: 'issueDate',
          sortable: false,
          align: 'start',
        },
        { title: 'Дійсний до', key: 'sellBy', sortable: false, align: 'start' },
        { title: 'Дії', key: 'actions', sortable: false, align: 'end' },
      ]
      switch (this.localPassport?.typeId) {
        case 1:
          return oldCard
        case 5:
          return interNationalDocument
        case 7:
          return temporaryDocument
        case [2, 9, 8].includes(this.localPassport?.typeId):
          return idCard
        case 3:
          return oldCard
        case [10, 11, 12, 13].includes(this.localPassport?.typeId):
          return otherDocument
        default:
          return idCard
      }
    },
  },
  methods: {
    getSafe,
    toFormatDate,
    deletePassport() {
      return this.$axios
        .delete(urlDeleteContactPassport(this.localPassport.id))
        .then(() => {
          this.localPassport = null
          this.$store.commit('refreshPage')
        })
    },
    openDialog(action) {
      this.$store.commit('setDialog', {
        title: 'Документ, що посвідчує особу',
        dialogItem: this.localPassport,
        params: {
          dialogWidth: '700',
          contactId: this.contactId,
          cb: identityDocument => {
            this.localPassport = identityDocument
            this.$emit('identityDocumentUpdated', identityDocument)
          },
        },
        action: `${action}-passport`,
      })
    },
  },
  created() {
    this.localPassport = Object.clone(this.identityDocument)
  },
}
</script>

<style scoped></style>
