<template>
  <div class="snowflakes" aria-hidden="true">
    <div class="snowflake">❅</div>
    <div class="snowflake">❅</div>
    <div class="snowflake">❄</div>
    <div class="snowflake">❄</div>
    <div class="snowflake">❅</div>
    <div class="snowflake">❆</div>
    <div class="snowflake">❄</div>
    <div class="snowflake">❅</div>
    <div class="snowflake">❆</div>
    <div class="snowflake">❄</div>
  </div>
</template>

<style scoped>
.snowflakes {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}

.snowflake {
  color: #fff;
  text-shadow: 0 0 1px #000;
  position: absolute;
  top: -50%;
  z-index: 9999;
  user-select: none;
  cursor: default;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
}

@keyframes snowflakes-fall {
  0% {
    top: -50%;
  }
  100% {
    top: 100%;
  }
}

@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(35px);
  }
  100% {
    transform: translateX(0px);
  }
}

.snowflake:nth-of-type(1) {
  left: 1%;
  animation-delay: 0s, 0s;
  font-size: 0.8em;
  opacity: 0.9;
}
.snowflake:nth-of-type(2) {
  left: 10%;
  animation-delay: 1s, 1s;
  font-size: 0.6em;
  opacity: 0.7;
}
.snowflake:nth-of-type(3) {
  left: 20%;
  animation-delay: 6s, 0.5s;
  font-size: 0.7em;
  opacity: 0.8;
}
.snowflake:nth-of-type(4) {
  left: 30%;
  animation-delay: 4s, 2s;
  font-size: 0.5em;
  opacity: 0.6;
}
.snowflake:nth-of-type(5) {
  left: 40%;
  animation-delay: 2s, 2s;
  font-size: 0.9em;
  opacity: 0.85;
}
.snowflake:nth-of-type(6) {
  left: 50%;
  animation-delay: 8s, 3s;
  font-size: 0.4em;
  opacity: 0.5;
}
.snowflake:nth-of-type(7) {
  left: 60%;
  animation-delay: 6s, 2s;
  font-size: 0.75em;
  opacity: 0.8;
}
.snowflake:nth-of-type(8) {
  left: 70%;
  animation-delay: 2.5s, 1s;
  font-size: 0.6em;
  opacity: 0.7;
}
.snowflake:nth-of-type(9) {
  left: 80%;
  animation-delay: 1s, 0s;
  font-size: 0.5em;
  opacity: 0.6;
}
.snowflake:nth-of-type(10) {
  left: 90%;
  animation-delay: 3s, 1.5s;
  font-size: 0.8em;
  opacity: 0.9;
}
</style>
