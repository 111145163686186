<template>
  <div>
    <div v-if="agentListRoute">
      <v-fade-transition hide-on-leave>
        <div>
          <Filters :filters="filters" class="ml-2">
            <template #header-action>
              <v-btn
                v-if="hasAccess"
                data-readonly="hide"
                class="mr-3"
                style="
                  background: rgb(252, 114, 71);
                  border-radius: 4px;
                  color: #fff;
                "
                @click="
                  $store.commit('setDialog', {
                    title: 'Агент',
                    params: {
                      cb: res => {
                        res && setSnackbar({ text: 'Створено агента' })
                      },
                    },
                    action: 'addAgent',
                  })
                ">
                <v-icon style="margin-right: 5px; color: #fff !important">
                  {{ 'mdi-plus' }}
                </v-icon>
                Додати агента
              </v-btn>
            </template>
          </Filters>
          <v-row>
            <v-col :cols="12">
              <div v-show="!pageLoad" class="pt-0">
                <v-data-table
                  :headers="agentHeaders"
                  :items="tBody"
                  class="calc-table --cust-cell-pd"
                  mobileBreakpoint="750"
                  dense
                  hide-default-footer
                  :items-per-page="-1"
                  :hide-default-header="!tBody.length"
                  :custom-sort="tableDateSort()"
                  @dblclick:row="navigateToAgent">
                  <!-- eslint-disable-next-line -->
                  <template #item.actions="{ item }">
                    <div style="width: 20px">
                      <TableCrud
                        data-readonly="hide"
                        :actionsArray="[
                          {
                            action: () =>
                              $router.push({
                                name: 'agent-form',
                                params: { id: item.id },
                              }),
                            text: 'Відкрити',
                            icon: 'mdi-folder-open-outline',
                          },
                          {
                            action: () =>
                              $store.commit('setDialog', {
                                title: 'Агент',
                                dialogItem: item,
                                params: {
                                  cb: () => {
                                    $setSnackbar({
                                      text: 'Агента видалено',
                                    })
                                    updateList()
                                  },
                                },
                                action: 'deleteAgent',
                              }),
                            text: 'Видалити',
                            icon: 'mdi-delete',
                          },
                        ]">
                      </TableCrud>
                    </div>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.dealerPoint="{ item }">
                    <ALink
                      v-if="item.dealerPointId"
                      :route="{
                        name: 'dealer-form',
                        params: { id: item.dealerPointId },
                      }"
                      :text="item.dealerPoint.name">
                    </ALink>
                    <span v-else>---</span>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.responsible="{ item }">
                    {{ item.responsible }}
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.fullName="{ item }">
                    <ALink
                      v-if="item.id"
                      :text="item.fullName"
                      :route="{ name: 'agent-form', params: { id: item.id } }">
                    </ALink>
                    <span v-else>{{ '---' }}</span>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.agent="{ item }">
                    {{ item.name }}
                  </template>
                </v-data-table>
                <Pagination
                  :getItems="getAgents"
                  :trigger="paginationTrigger" />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-fade-transition>
    </div>
    <SectionLoader v-if="pageLoad && agentListRoute"> </SectionLoader>
    <v-fade-transition hide-on-leave>
      <RouterView v-if="!agentListRoute" @data-changed="updateList">
      </RouterView>
    </v-fade-transition>
  </div>
</template>
<script>
import ALink from '@/components/Link.vue'

import SectionLoader from '@/components/section-loader.vue'
import Pagination from '@/components/Pagination.vue'
import { mapState, mapGetters } from 'vuex'
import {
  tableDateSort,
  getContactInitials,
  getResponsibleShortName,
} from '@/utils/helperFunc'
import { FRONT_MANAGER, ADMIN, SUPERVISOR, SALES_DIRECTOR } from '@/roles'
import Filters from '@/components/Filters.vue'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'
import { setSnackbar } from 'best-modules/plugins'
import { agentHeaders } from '@/utils/tableHeaders'
import { fillDirectory } from '@/plugins/directory'
export default {
  components: {
    TableCrud,
    Filters,
    Pagination,
    SectionLoader,
    ALink,
  },
  setup() {
    const filters = reactive({
      responsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'activeUsers',
        },
        focus: () => fillDirectory('allUsers'),
      },
      search: { value: null },
    })
    return {
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      agentHeaders,
      pageLoad: false,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    hasAccess() {
      return [ADMIN, FRONT_MANAGER, SUPERVISOR, SALES_DIRECTOR].includes(
        this.user.roleId
      )
    },
    agentListRoute() {
      return this.$route.name === 'agent-list'
    },
    tBody() {
      return this.selectItems.agents.map(el => {
        return {
          id: el.id,
          contact: el.contact || {},
          contractor: el.dealerPoint?.contractor || {},
          fullName: el.contact.fullName || '---',
          dealerPointId: el.dealerPointId,
          contactId: el.contact?.id || null,
          dealerPoint: el.dealerPoint || '---',
          isActive: el.isActive ? 'Активний' : 'Неактивний',
          responsible: this.getResponsibleShortName(el.contact.responsible),
        }
      })
    },
  },
  methods: {
    setSnackbar,
    tableDateSort,
    getContactInitials,
    getResponsibleShortName,
    getAgents(page = 1) {
      this.pageLoad = true
      return this.$store
        .dispatch('addAgents', { page, filters: this.filtersQueryObj })
        .then(res => {
          this.pageLoad = false
          return { data: res }
        })
        .catch(err => this.$err(err, () => (this.pageLoad = false)))
    },
    navigateToAgent(e, row) {
      this.$router.push({
        name: 'agent-form',
        params: { id: row.item.id },
      })
    },
  },
}
</script>
