<template>
  <div>
    <b-select
      v-model="signatureBasis.basisDocumentId"
      label="На підставі"
      :items="
        $directory
          .get('basisDocuments')
          .filter(b => [1, 2, 8, 9].includes(b.id as number))
      "
      :loading="$loading.isLoading('basisDocuments')"
      :error-messages="getErrorMessages(v$.basisDocumentId)"
      hide-details
      @blur="v$.basisDocumentId.$touch()"></b-select>
    <v-scroll-x-transition hide-on-leave>
      <b-input
        v-show="hasDocumentComment"
        v-model="signatureBasis.documentComment"
        label="Назва"
        hide-details
        placeholder="введіть значення"
        :error-messages="getErrorMessages(v$.documentComment)"
        @blur="v$.documentComment.$touch()"></b-input>
    </v-scroll-x-transition>
    <div class="d-flex justify-end">
      <v-btn
        variant="flat"
        class="mt-3"
        :loading="$loading.isLoading('createSignatureBasis')"
        @click="createSignatureBasis">
        <span>Додати</span>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import axios from '@/plugins/axios.js'
import { urlSignatureBasisCreate } from '@/pages/request'
import { SignatureBasis } from '@/utils/types'
import { computed, reactive, watch } from 'vue'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { required, requiredIf } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getErrorMessages } from 'best-modules/utils'
import { fillDirectory } from '@/plugins/directory'

export default {
  name: 'SignatureBasisForm',
  props: {
    employeeId: {
      type: Number,
    },
  },
  methods: { getErrorMessages },
  emits: ['submit'],
  setup(props, { emit }) {
    const signatureBasis = reactive<SignatureBasis & { employeeId: number }>({
      employeeId: props.employeeId,
      basisDocumentId: null,
      documentComment: null,
    } as SignatureBasis & { employeeId: number })
    const hasDocumentComment = computed(() => {
      return [2, 8, 9].includes(signatureBasis.basisDocumentId)
    })
    const rules = {
      basisDocumentId: { required: required },
      documentComment: { required: requiredIf(() => hasDocumentComment.value) },
    }
    const v$ = useVuelidate(rules, signatureBasis)

    const createSignatureBasis = () => {
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      return handleAsync('  createSignatureBasis', () => {
        return axios
          .post(urlSignatureBasisCreate(), signatureBasis)
          .then(res => {
            signatureBasis.basisDocumentId = null
            signatureBasis.documentComment = null

            v$.value.$reset()

            setSnackbar({ text: 'Підставу успішно додано' })
            emit('submit', res.data)
          })
      })
    }

    watch(hasDocumentComment, v => {
      if (!v) {
        signatureBasis.documentComment = null
      }
    })

    fillDirectory('basisDocuments')

    return {
      signatureBasis,
      createSignatureBasis,
      v$,
      hasDocumentComment,
    }
  },
}
</script>

<style scoped></style>
