<template>
  <div>
    <FieldsColumnBlock
      v-if="dialog.dialogItem.relationEmployees.length"
      title="Зв'язки кар'єри">
      <template #default>
        <v-data-table
          :items="dialog.dialogItem.relationEmployees || []"
          :headers="relatedEmployeesHeaders"
          class="header-upper-case remove-borders cursor-default"
          :items-per-page="50"
          :hide-default-header="!dialog.dialogItem.relationEmployees.length"
          hide-default-footer>
          <template #item.contractor="{ item }">
            <v-tooltip
              v-if="item.contractor"
              :text="item.contractor?.shortName">
              <template #activator="{ props }">
                <ALink
                  v-bind="props"
                  class="contractor-label"
                  :route="{
                    name: 'contractors-form',
                    params: { id: item?.contractor?.id },
                  }"
                  :text="item?.contractor?.shortName">
                </ALink>
              </template>
            </v-tooltip>
          </template>
          <template #item.post="{ item }">
            <span>{{ item.post?.name || '---' }}</span>
          </template>
          <template #item.department="{ item }">
            <span>{{ item.department?.name || '---' }}</span>
          </template>
        </v-data-table>
      </template>
    </FieldsColumnBlock>

    <v-divider class="my-3"></v-divider>

    <FieldsColumnBlock
      v-if="dialog.dialogItem.relationBeneficiars.length"
      title="Зв'язки бенефіціара">
      <template #default>
        <v-data-table
          :items="dialog.dialogItem.relationBeneficiars || []"
          :headers="relatedBeneficiarsHeaders"
          class="header-upper-case remove-borders cursor-default"
          :items-per-page="50"
          :hide-default-header="!dialog.dialogItem.relationBeneficiars.length"
          hide-default-footer>
          <template #item.contractor="{ item }">
            <v-tooltip
              v-if="item.contractor"
              :text="item.contractor?.shortName">
              <template #activator="{ props }">
                <ALink
                  v-bind="props"
                  class="contractor-label"
                  :route="{
                    name: 'contractors-form',
                    params: { id: item.contractor?.id },
                  }"
                  :text="item?.contractor?.shortName">
                </ALink>
              </template>
            </v-tooltip>
          </template>
          <template #item.percent="{ item }">
            <div class="d-flex align-center">
              <CircleDiagram :percent="item.percent" />
              <span class="ml-1">
                {{
                  getBeautyNum(item?.percent, {
                    float: 6,
                    round: true,
                  })
                }}%
              </span>
            </div>
          </template>
        </v-data-table>
      </template>
    </FieldsColumnBlock>
  </div>
</template>

<script>
import {
  relatedBeneficiarsHeaders,
  relatedEmployeesHeaders,
} from '@/utils/tableHeaders'
import ALink from '@/components/Link.vue'
import CircleDiagram from '@/components/CircleDiagram.vue'
import { getBeautyNum } from '@/utils/helperFunc'
import FieldsColumnBlock from '@/pages/contracts/components/FieldsColumnBlock.vue'

export default {
  components: { FieldsColumnBlock, CircleDiagram, ALink },
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getBeautyNum },
  setup() {
    return { relatedEmployeesHeaders, relatedBeneficiarsHeaders }
  },
}
</script>

<style lang="scss" scoped>
.contractor-label {
  display: block;
  width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
