<template>
  <div class="dkp-contract">
    <SectionLoader v-if="$loading.isLoading('getContract')"> </SectionLoader>
    <v-row v-else>
      <v-col cols="12" md="3" sm="12">
        <FieldsColumnBlock title="Основні дані">
          <template #general-fields>
            <Fields :fields="fields" :type="2" direction="column">
              <template #number_field>
                <EditableField
                  v-model="contract.number"
                  label="№ Договору"
                  style="margin-top: -5px"
                  :readonly="readonly"
                  :invalid="!!v$.number.$error"
                  @blur="v$.number.$touch()" />
              </template>
            </Fields>
          </template>
          <template #default>
            <div class="mb-2">
              <span class="label">Вид підпису</span>
              <v-select
                v-model="contract.signingTypeId"
                :items="$directory.get('signingTypes', contract.signingType) as any[]"
                item-title="name"
                item-value="id"
                hide-details
                :readonly="readonly"
                :disabled="readonly"
                placeholder="Оберіть вид"
                :error-messages="getErrorMessages(v$.signingTypeId)"
                :loading="$loading.isLoading('signingTypes')"
                @focus="$directory.fill('signingTypes')"
                @blur="v$.signingTypeId.$touch()">
              </v-select>
            </div>
          </template>
        </FieldsColumnBlock>
        <FieldsColumnBlock title="Візування" :fullHeight="!mobile">
          <template #agreements>
            <Agreements :agreements="contract.agreements" />
          </template>
        </FieldsColumnBlock>
      </v-col>
      <v-col cols="12" md="9" sm="12" :class="`${mobile ? '' : 'pl-0'}`">
        <div class="white-block" style="padding-left: 7px">
          <ContractStatusBar
            class="mb-3"
            :contract="contract"
            :mobile="mobile"
            :isCancelContract="contract.isCancelContract"
            :isTerminated="contract.isTerminated"
            :currentStatus="contract.statusId"
            :submit="setDkpStatus"
            :statuses="filteredStatuses"
            spreadItems
            size="small">
            <template #actions>
              <ContractActionButton :actions="btnActions" />
            </template>
          </ContractStatusBar>
          <v-tabs
            show-arrows
            :direction="mobile ? 'vertical' : 'horizontal'"
            :height="38"
            class="dkpTabs"
            color="white"
            dark
            slider-color="#fc7247"
            dense>
            <v-tab
              replace
              :to="{
                name: 'parties-details',
                params: { id: $route.params.id },
              }">
              Реквізити
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'sale-terms',
                params: { id: $route.params.id },
              }">
              Умови продажу
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'buyback-schedule',
                params: { id: $route.params.id },
              }">
              Графiк зворотнього викупу
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'agreements',
                params: { id: $route.params.id },
              }">
              Договори
            </v-tab>
            <v-tab
              replace
              :to="{ name: 'links', params: { id: $route.params.id } }">
              Зв`язки
            </v-tab>
            <v-tab
              replace
              :to="{
                name: 'dkp-history',
                params: { id: $route.params.id },
              }">
              Історія
            </v-tab>
          </v-tabs>
        </div>
        <RouterView
          :contract="contract"
          :endorsement="endorsement"
          :readonly="readonly">
        </RouterView>
        <v-card-actions>
          <ActionButtons
            :confirm="submit"
            :cancel="getContract"
            :confirmLoading="$loading.isLoading('submit')"
            :cancelDisable="
              $loading.isLoading('submit') || !contract.$hasChanges
            "
            :confirmDisable="!contract.$hasChanges">
          </ActionButtons>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import ContractActionButton from '@/pages/contracts/components/ContractActionButton.vue'
import ActionButtons from '@/components/action-buttons.vue'
import SectionLoader from '@/components/section-loader.vue'
import { mapState } from 'vuex'
import { required, minLength, requiredIf } from '@vuelidate/validators'
import {
  urlUpdateDkpContract,
  urlGetDKPPrintData,
  urlSetDkpStatus,
  urlSetCancelContractDkp,
  urlCreateExtraAgreementDkp,
  urlGetSingleDkpContract,
} from '@/pages/request.js'
import {
  LODescription as getLoDescription,
  v$Notify,
  backDate,
  toFormatDate,
  downloadPrintedForm,
  getEntityName,
} from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import { confirm, handleAsync } from 'best-modules/plugins'
import { fillDirectory, getDirectory } from '@/plugins/directory'
import ContractStatusBar from '@/components/contracts/ContractStatusBar.vue'
import Fields from '@/components/Fields.vue'
import EditableField from '@/components/EditableField.vue'
import Agreements from '@/components/Agreements.vue'
import FieldsColumnBlock from '@/pages/contracts/components/FieldsColumnBlock.vue'
import { useDisplay } from 'vuetify'
import { contractKey, updateContractKey, v$Key } from './injectionKeys'
import { cachedObject } from 'best-modules/utils'
import { computed, provide } from 'vue'
import { DkpContract } from '@/utils/types/contract/dkp'
import { cloneDeep } from 'lodash'
import { getErrorMessages } from 'best-modules/utils'

export default {
  components: {
    ContractActionButton,
    FieldsColumnBlock,
    Agreements,
    EditableField,
    Fields,
    ContractStatusBar,
    SectionLoader,
    ActionButtons,
  },
  setup() {
    const { xs, sm } = useDisplay()
    const mobile = computed(() => xs.value || sm.value)

    const contract = cachedObject<DkpContract>({} as DkpContract)
    const updateContract = (c: DkpContract) => {
      Object.assign(contract, c)
    }

    const rules = {
      quantityMonthGuarantees: {
        requiredIf: requiredIf(() => contract.isGuarantees),
      },
      typeId: { required },
      number: { required },
      date: { required, minLength: minLength(10) },
      orderCalculationId: { required },
      dkpBuyerBasisId: { required },
      signatoryId: { required },
      signingTypeId: { required },
      providerSignatories: {
        required,
        minLength: minLength(1),
        $each: {
          contactId: { required },
          basisId: { required },
        },
      },
    }

    const v$ = useVuelidate(rules, contract)

    provide(contractKey, contract)
    provide(updateContractKey, updateContract)
    provide(v$Key, v$)

    return { v$, mobile, contract }
  },
  data: () => ({
    DKPPrintData: {},
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    btnActions() {
      return {
        main: { label: 'Дії', disabled: false },
        list: [
          {
            show:
              !this.contract.isTerminated && !this.contract.isCancelContract,
            label: 'Анулювати договір',
            icon: 'mdi-file-document-remove-outline',
            action: () => this.setCancel(),
            disabled:
              (!this.admin && this.readonly) || this.contract.isCancelContract,
          },
          {
            show:
              !this.contract.isTerminated && !this.contract.isCancelContract,
            label: 'Розірвати договір',
            icon: 'mdi-file-remove',
            action: () => this.terminateContract(),
            disabled: false,
          },
          {
            show: true,
            label: 'Договір купівлі-продажу',
            icon: 'mdi-file',
            action: () =>
              downloadPrintedForm(
                urlGetDKPPrintData(this.contract.id),
                this.contract.number
              ),
            disabled: false,
          },
          {
            show: true,
            label: 'Створити Додаткову угоду',
            action: () => this.createExtraAgreement(),
            icon: 'mdi-plus',
            disabled:
              this.contract.isTerminated || this.contract.isCancelContract,
          },
        ],
      }
    },
    fields() {
      return [
        { label: 'Тип договору', value: this.contract.type?.name },
        {
          label: 'Дата договору',
          value: toFormatDate(this.contract.date, true),
        },
        { label: '№ Договору', slot: 'number' },
        {
          label: 'Проект',
          value: `${this.contract?.project?.number} - ${this.contract?.project?.lesseeName}`,
          class: { link: true },
          showHover: true,
          click: () => {
            this.$router.push({
              name: 'project',
              params: { projectId: this.contract.project.id },
            })
          },
        },
      ]
    },
    admin() {
      return this.user.roleId === 1
    },
    endorsement() {
      return this.contract.statusId === 3
    },
    providerName() {
      return (
        (getEntityName(this.contract.provider) || '---') +
        ' - ' +
        this.contract.number
      )
    },
    contractId() {
      return this.$route?.params?.id
    },
    LO() {
      return this.getLoDescription(this.contract.calculation)
    },
    lesseeName() {
      if (!this.contract || !this.contract.contract) {
        return '---'
      }

      const lessee = this.contract.contract.lessee
      const lesseeName =
        lessee?.contact?.fullName || lessee?.contractor?.shortName || '---'
      const contractNumber = this.contract.number

      return `${lesseeName} - ${contractNumber}`
    },
    route() {
      return this.$route.name
    },
    readonly() {
      return this.contract?.readOnly
    },
    filteredStatuses() {
      const filteredStatuses = getDirectory('dkpStatuses').filter(item => {
        if (item.id === 6) {
          return this.contract.isCancelContract
        }
        if (item.id === 7) {
          return this.contract.isTerminated
        }
        return true
      })
      return filteredStatuses.map(s => {
        let showCheckIcon

        if ([6, 7].includes(this.contract.statusId)) {
          showCheckIcon = s.id === this.contract.statusId
        }

        return { ...s, showCheckIcon }
      })
    },
  },
  methods: {
    getLoDescription,
    urlSetCancelContractDkp,
    urlUpdateDkpContract,
    urlGetDKPPrintData,
    urlSetDkpStatus,
    downloadPrintedForm,
    backDate,
    toFormatDate,
    getErrorMessages,
    terminateContract() {
      if (
        this.contract.statusId !== 5 ||
        !this.contract.extraAgreementsDkp?.some(a => {
          return a.statusId === 5
        })
      ) {
        return this.$setSnackbar({
          text: 'Для розривання договір та додаткова угода мають бути в статусі "Підписаний"',
          color: 'warning',
        })
      }
      this.$store.commit('setDialog', {
        title: 'Розірвання договору',
        dialogItem: {
          contractId: this.contract.id,
          agreements: this.contract.extraAgreementsDkp.filter(a => {
            return a.statusId === 5
          }),
        },
        params: {
          cb: this.getContract,
        },
        action: 'terminateDkp',
      })
    },
    openAgreementSingle(id) {
      this.$router.push({ name: 'du-dkp-agreement', params: { id } })
    },
    setBreadScrumb(res) {
      this.$store.commit('setBreadScrumbPart', [null, null, this.providerName])
      return res
    },
    setCancel() {
      confirm({
        text: 'Ви дійсно бажаєте анулювати договір?',
      }).then(() => {
        return this.$axios
          .post(urlSetCancelContractDkp(this.contract.id), {
            isCancelContract: true,
          })
          .then(res => {
            this.$setSnackbar({ text: 'Дані оновлено' })
            this.contract.$set(res.data)

            return res
          })
      })
    },
    submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        return Promise.resolve(v$Notify(this.v$, 'dkp'))
      } else if (!this.contract.calculation.specificationDocuments?.length) {
        return Promise.resolve(
          this.$setSnackbar({
            text: 'Вкажіть документи специфікації',
            color: 'warning',
          })
        )
      }

      const requestObj = cloneDeep(this.contract.$object)

      if (requestObj.providerSignatories)
        requestObj.providerSignatories = requestObj.providerSignatories.map(
          s => ({
            contactId: s.contactId,
            basisId: s.basisId,
          })
        )

      requestObj.currencyCourse = +requestObj.currencyCourse

      return handleAsync('submit', () => {
        return this.$axios
          .post(this.urlUpdateDkpContract(this.contractId), requestObj)
          .then(res => {
            if (res.data.message) throw new Error(res.data.message)
            this.$setSnackbar({ text: 'Оновлено' })
            this.contract.$set(res.data)
          })
          .catch(err => this.$err(err))
      })
    },
    async setDkpStatus(statusId) {
      if (this.v$.$invalid) {
        return Promise.reject(v$Notify(this.v$, 'dkp'))
      }

      const currentStatus = this.contract.statusId

      if (currentStatus === 5) {
        return this.$setSnackbar({
          text: 'Договір з статусу підписаного не може бути змiнено',
          color: 'warning',
        })
      }
      if (
        [4, 5].includes(statusId) &&
        this.contract.agreements?.some(a => !a.isAgreed)
      ) {
        return this.$setSnackbar({
          text: 'Договір повинен бути завізований всiма учасниками',
          color: 'warning',
        })
      }
      if (statusId < currentStatus || statusId > currentStatus + 1) {
        return this.$setSnackbar({ text: 'Дія неможлива', color: 'error' })
      }

      if ([2, 3].includes(statusId)) {
        try {
          const statusNames = {
            2: 'Підписано Постачальником',
            3: 'Візування',
          }
          await confirm({
            text: `Ви впевнені що хочете перевести договір в статус "${statusNames[statusId]}"?`,
          })
        } catch (e) {
          return
        }
      }

      try {
        const response = await this.$axios.post(
          this.urlSetDkpStatus(this.contract.id),
          { statusId }
        )
        if (response.data.message) {
          this.$setSnackbar({ text: response.data.message, color: 'warning' })
        } else {
          this.$setSnackbar({ text: 'Статус успішно змiнено' })
        }
        this.getContract()
        return response
      } catch (error) {
        this.$err(error)
      }
    },
    getContract() {
      if (this.v$.$reset) {
        this.v$.$reset()
      }
      fillDirectory('dkpStatuses')
      return handleAsync('getContract', () => {
        return this.$axios
          .get(urlGetSingleDkpContract(this.contractId))
          .then(res => {
            if (res?.data?.message) {
              return this.$setSnackbar({
                text: res.data.message,
                color: 'error',
              })
            }
            this.contract.$set(res.data)

            return res
          })
          .then(this.setBreadScrumb)
          .catch(err => {
            this.$setSnackbar({ text: err, color: 'error' })
            throw err
          })
      })
    },
    createExtraAgreement() {
      return handleAsync('createExtraAgreement', () => {
        return this.$axios
          .post(urlCreateExtraAgreementDkp(), {
            dkpId: this.contract.id,
          })
          .then(res => {
            this.$setSnackbar({ text: 'Додаткову угоду успішно створено' })
            this.$router.push({
              name: 'du-dkp-agreement',
              params: { id: res.data.id },
            })

            return res
          })
          .catch(this.$err)
      })
    },
  },
  watch: {
    route() {
      this.setBreadScrumb()
    },
  },
  created() {
    this.getContract()
  },
}
</script>

<style>
.dkpTabs .v-slide-group__wrapper {
  background: #fff !important;
}
</style>

<style scoped>
a.v-tab-item--selected.v-tab {
  color: #08487a !important;
  font-weight: 700 !important;
  font-size: 14px;
  letter-spacing: normal;
  padding-left: 0;
  padding-right: 0;
}
a.v-tab {
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
  color: #838282 !important;
  letter-spacing: normal !important;
}
.v-slide-group__wrapper {
  background: #fff !important;
}
</style>
