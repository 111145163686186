<template>
  <div>
    <v-data-table
      :headers="historyTHead"
      :items="lead.histories"
      class="pb-2 custom-table"
      dense>
      <!-- eslint-disable-next-line -->
      <template #item.created_at="{ item }">
        <span style="font-size: 13px">{{ toFormatDate(item.created_at) }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <span style="font-size: 13px">{{ item?.status?.name || '---' }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.source="{ item }">
        <span style="font-size: 13px">{{ item?.source?.name || '---' }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.leadName="{ item }">
        <span class="link">{{ item.leadName }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.responsible="{ item }">
        <span style="font-size: 13px">{{
          getResponsibleShortName(item.responsible)
        }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getResponsibleShortName, toFormatDate } from '@/utils/helperFunc'
export default {
  props: {
    lead: { type: Object },
    readonly: { type: Boolean, default: false },
  },
  data: () => ({
    historyTHead: [
      {
        title: 'Дата',
        key: 'created_at',
        sortable: false,
        align: 'start',
        width: '130px',
      },
      {
        title: 'Назва лiда',
        key: 'leadName',
        sortable: false,
        align: 'start',
      },
      { title: 'Статус', key: 'status', sortable: false, align: 'start' },
      { title: 'Джерело', key: 'source', sortable: false, align: 'start' },
      {
        title: 'Відповідальний',
        key: 'responsible',
        sortable: false,
        align: 'start',
      },
    ],
  }),
  methods: {
    getResponsibleShortName,
    toFormatDate,
  },
}
</script>
