<template>
  <div>
    <lessee-retreatment
      v-if="application.sourceTypeId === 3"></lessee-retreatment>
    <lessee-default v-else></lessee-default>

    <div v-if="!createApplication">
      <div class="d-flex align-center gap-2">
        <CommentIcon />
        <span class="font-weight-bold" style="color: #08487a"> Коментар </span>
      </div>

      <Comments
        :label="false"
        :comments="application.comments || []"
        :entityId="application.id"
        :entityTypeId="3" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, inject } from 'vue'
import {
  applicationKey,
  updateApplicationObjectKey,
  createApplicationKey,
} from '../../injectionKeys'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { Application } from '@/utils/types/application'
import LesseeDefault from './LesseeDefault.vue'
import LesseeRetreatment from './LesseeRetreatment.vue'
import CommentIcon from '@/assets/svg/comment-icon.vue'
import Comments from '@/components/comments/Index.vue'

export default {
  name: 'GeneralInfo',
  components: { Comments, CommentIcon, LesseeRetreatment, LesseeDefault },
  setup() {
    const createApplication = inject(createApplicationKey)

    const updateApplicationObject = inject(updateApplicationObjectKey)
    const application = computed<CachedObjectProxy<Application>>({
      get: () => inject(applicationKey),
      set: updateApplicationObject,
    })

    return { application, createApplication }
  },
}
</script>

<style scoped></style>
