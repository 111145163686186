<template>
  <div>
    <v-row>
      <v-col class="pb-0 pt-0">
        <list-section title="Зв`язки з заявками">
          <template #body>
            <v-data-table
              :headers="contactApplicationLinkHeaders"
              :items="contactData.applications || []"
              hide-default-footer
              :hide-default-header="!contactData.applications"
              class="pb-2 pt-1 custom-table head-line"
              dense>
              <template #no-data>
                <no-data></no-data>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.created_at="{ item }">
                {{ toFormatDate(item.created_at) }}
              </template>
              <template #item.status="{ item }">
                {{ item.status?.name }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.responsible="{ item }">
                {{
                  getContactInitials(item.responsible.fullName, {
                    byName: true,
                  })
                }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.applicationName="{ item }">
                <ALink
                  :route="{
                    name: 'edit-application',
                    params: { applicationId: item.id },
                  }"
                  :text="item.applicationName">
                </ALink>
              </template>
            </v-data-table>
          </template>
        </list-section>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import listSection from '@/components/list-section.vue'
import noData from '@/components/no-table-data.vue'
import { getContactInitials, toFormatDate } from '@/utils/helperFunc'
import { contactApplicationLinkHeaders } from '@/utils/tableHeaders'
import ALink from '@/components/Link.vue'

export default {
  components: { ALink, listSection, noData },
  props: {
    contact: { type: Object },
  },
  data() {
    return {
      contactApplicationLinkHeaders,
    }
  },
  computed: {
    contactData() {
      return this.contact
    },
  },
  methods: {
    toFormatDate,
    getContactInitials,
  },
}
</script>
