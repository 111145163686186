<template>
  <v-dialog
    v-model="innerState"
    :value="dialog"
    :max-width="dialogParams.dialogwidth || 380"
    persistent>
    <div v-if="dialog" style="background: #fff; overflow: scroll">
      <v-toolbar
        style="background-color: transparent"
        :elevation="0"
        :height="60"
        dark>
        <v-toolbar-title
          style="
            color: rgb(8, 72, 122);
            font-weight: 700 !important;
            font-size: 1rem;
          ">
          <span v-show="changeFrontManager">
            {{ 'Фронт менеджер' }}
          </span>
          <span v-show="changeMiddleManager">
            {{ 'Мiдл менеджер' }}
          </span>
          <span v-show="addBankAccount || editBankAccount">
            {{ 'Банкiвський рахунок' }}
          </span>
          <span v-show="addBeneficiar || editBeneficiar || deleteBeneficiar">
            {{ 'Засновник' }}
          </span>
          <span v-show="syncWithOpenData">
            <v-icon size="28">
              {{ 'mdi-alert-circle-outline' }}
            </v-icon>
            {{ 'ЕДР' }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn size="small" icon @click="$emit('update:dialog', !dialog)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 26 26"
            fill="none">
            <path
              d="M25 13C25 6.375 19.625 1 13 1C6.375 1 1 6.375 1 13C1 19.625 6.375 25 13 25C19.625 25 25 19.625 25 13Z"
              stroke="#C0C1C7"
              stroke-width="2"
              stroke-miterlimit="10" />
            <path
              d="M17 17L9 9M9 17L17 9"
              stroke="#C0C1C7"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </v-btn>
      </v-toolbar>
      <div class="pr-4 pl-4">
        <div v-if="changeFrontManager">
          <v-select
            v-model="contractorData.frontManagerId"
            :items="['Тэст 1', 'Тэст 2']"
            label="Фронт менеджер"
            dense
            @keyup.enter="submit()">
          </v-select>
        </div>
        <div v-if="changeMiddleManager">
          <v-select
            v-model="contractorData.middleManagerId"
            :items="['Тэст 1', 'Тэст 2']"
            label="Мiдл менеджер"
            dense
            @keyup.enter="submit()">
          </v-select>
        </div>
        <div v-if="addBankAccount || editBankAccount">
          <span class="area-label">№ рахунку (IBAN)</span>
          <v-text-field
            id="cont-bank-num"
            v-model="bankAccount.accountNumber"
            v-mask:[ibanMask]
            :error-messages="bankAccountNumErr"
            placeholder="Введіть номер"
            dense
            @keyup.enter="submit()"
            @blur="v$.bankAccount.accountNumber.$touch()">
          </v-text-field>
          <span class="area-label">МФО</span>
          <v-text-field
            id="contractor-account-mfo"
            v-model="bankAccount.mfo"
            :error-messages="interbranchNumErr"
            placeholder="6 цифр"
            dense
            :disabled="mfoLoading"
            readonly
            @keyup.enter="submit()"
            @blur="v$.bankAccount.mfo.$touch()">
            <template #append>
              <v-btn v-if="mfoLoading" icon size="small" :loading="mfoLoading">
              </v-btn>
              <span v-else></span>
            </template>
          </v-text-field>
          <span class="area-label">Банк</span>
          <v-text-field
            id="contractor-account-name"
            v-model="bankAccount.bankName"
            :error-messages="bankNameErr"
            placeholder="Банк"
            dense
            readonly
            @keyup.enter="submit()"
            @blur="v$.bankAccount.bankName.$touch()">
          </v-text-field>
          <span class="area-label">Тип рахунку</span>
          <v-select
            v-model="bankAccount.type.id"
            :error-messages="bankAccountTypeIdErr"
            :items="$directory.get('bankAccountTypes', bankAccount.type)"
            item-title="name"
            item-value="id"
            placeholder="Тип рахунку"
            :loading="$loading.isLoading('bankAccountTypes')"
            dense
            @keyup.enter="submit()"
            @update:model-value="bankAccount.isBasic = false"
            @blur="v$.bankAccount.type.$touch()"
            @focus="$directory.fill('bankAccountTypes')">
            <template #append-item>
              <loader v-if="$loading.isLoading('bankAccountTypes')"></loader>
            </template>
          </v-select>
          <v-checkbox
            v-if="currentAccount"
            v-model="bankAccount.isBasic"
            class="mt-0 mb-3"
            color="rgb(210, 74, 67)"
            hide-details
            dense
            @keyup.enter="submit()">
            <template #label>
              <span style="font-size: 0.8rem"> Основний рахунок </span>
            </template>
          </v-checkbox>
          <span class="area-label">Валюта</span>
          <v-select
            v-model="bankAccount.currency.id"
            :error-messages="currencyErr"
            placeholder="Оберіть зi списку"
            :items="$directory.get('currencies', bankAccount.currency)"
            item-title="name"
            item-value="id"
            dense
            @keyup.enter="submit()"
            @blur="v$.bankAccount.currency.$touch()">
          </v-select>
        </div>
        <div v-if="addBeneficiar || editBeneficiar || deleteBeneficiar">
          <v-card-title v-show="deleteBeneficiar">
            Засновника буде видалено!
          </v-card-title>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <span class="label">Назва компаниї/ПІБ</span>
              <v-text-field
                v-model="beneficiar.name"
                :error-messages="beneficiarNameErr"
                maxLength="255"
                dense
                placeholder="Введіть назву"
                hide-details
                :disabled="deleteBeneficiar"
                @blur="v$.beneficiar.name.$touch()"
                @keyup.enter="submit()">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pt-0">
              <span class="label">Тип володіння</span>
              <v-text-field
                :model-value="getSafe(() => beneficiar.founder_role.name)"
                :items="selectItems.founderRoles"
                item-title="name"
                item-value="id"
                dense
                hide-details
                disabled
                @keyup.enter="submit()">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pt-0">
              <span class="label">Доля в статутному капіталі. грн</span>
              <v-text-field
                id="cp-d-ben-am"
                v-model="beneficiar.amount"
                :error-messages="beneficiarAmountErr"
                dense
                hide-details
                placeholder="Введіть суму"
                :disabled="deleteBeneficiar"
                @input="inputNumber($event, { float: 2, minValue: 1 })"
                @blur="v$.beneficiar.amount.$touch()"
                @keyup.enter="submit()">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pt-0">
              <span class="label">Доля в статутному капіталі. %</span>
              <v-text-field
                id="cp-d-ben-am-per"
                v-model="beneficiar.amountPercent"
                :error-messages="beneficiarAmountPercentErr"
                dense
                placeholder="Введіть відсоток"
                hide-details
                :disabled="deleteBeneficiar"
                @blur="
                  event => {
                    inputNumber(event, { float: 6 })
                    v$.beneficiar.amountPercent.$touch()
                  }
                "
                @input="
                  $event => {
                    inputNumber($event, {
                      float: 6,
                      maxValue: dialogParams.maxPercent,
                    })
                    v$.beneficiar.amountPercent.$touch()
                  }
                "
                @keyup.enter="submit()">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pt-0">
              <span class="label">ЭДРПОУ/ІПН</span>
              <v-text-field
                id="cp-d-ben-code"
                v-model="beneficiar.code"
                :error-messages="beneficiarEdrpouErr"
                dense
                hide-details
                placeholder="Введіть номер"
                :disabled="deleteBeneficiar"
                @blur="v$.beneficiar.code.$touch()"
                @keyup.enter="submit()">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pt-0">
              <span class="label">Країна резидентства</span>
              <v-autocomplete
                v-model="beneficiar.residentCountryId"
                :items="
                  $directory.get('nationalityList', beneficiar.residentCountry)
                "
                :loading="$loading.isLoading('nationalityList')"
                item-title="name"
                item-value="id"
                placeholder="Оберіть зі списку"
                dense
                hide-details
                :error-messages="beneficiarResidentCountryIdErr"
                :disabled="deleteBeneficiar"
                @blur="v$.beneficiar.registrationCountryId.$touch()">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pt-0">
              <span class="label">Країна реєстрації</span>
              <v-autocomplete
                v-model="beneficiar.registrationCountryId"
                :items="
                  $directory.get(
                    'nationalityList',
                    beneficiar.registrationCountry
                  )
                "
                :loading="$loading.isLoading('nationalityList')"
                item-title="name"
                item-value="id"
                placeholder="Оберіть зі списку"
                dense
                hide-details
                :disabled="deleteBeneficiar"
                :error-messages="beneficiarRegistrationCountryIdErr"
                @blur="v$.beneficiar.registrationCountryId.$touch()">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pt-0">
              <span class="label">Адреса</span>
              <v-text-field
                id="cp-d-ben-code"
                v-model="beneficiar.location"
                dense
                hide-details
                placeholder="Введіть адресу"
                :disabled="deleteBeneficiar"
                :error-messages="beneficiarLocationErr"
                @blur="v$.beneficiar.location.$touch()"
                @keyup.enter="submit()">
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div v-if="syncWithOpenData" class="pb-4" style="font-size: 0.88rem">
          <edr-contractor-mapper :contractor="ODContractor">
          </edr-contractor-mapper>
        </div>
      </div>
      <v-card-actions class="justify-space-around pb-2 mt-5">
        <v-btn
          class="no-transform"
          style="
            height: 33px;
            width: 45%;
            color: rgb(8, 72, 122);
            font-weight: 700;
            font-size: 12px;
            background: rgb(255, 255, 255);
            border: 2px solid rgb(8, 72, 122);
          "
          small
          @click="innerState = !innerState">
          ВIДХИЛИТИ
        </v-btn>
        <v-btn
          class="text-white no-transform"
          style="
            width: 45%;
            height: 33px;
            font-weight: 700;
            font-size: 12px;
            background: rgb(8, 72, 122);
            border-radius: 4px;
          "
          small
          @click="submit()">
          ЗБЕРЕГТИ
        </v-btn>
      </v-card-actions>
    </div>
  </v-dialog>
</template>
<script>
import edrContractorMapper from './edr-contractor-mapper.vue'
import { mapState } from 'vuex'
import { required, minLength, minValue } from '@vuelidate/validators'
import {
  urlCreateFounder,
  urlUpdateFounder,
  urlUpdateBeneficiar,
  urlCreateBankAccount,
  urlUpdateBankAccount,
  urlDeleteFounder,
  urlGetAllContractors,
} from '@/pages/request'
import {
  maxNum,
  keepDigit,
  formutSum,
  changeInputVal,
  inputNumber,
} from '@/utils/formatFunc'
import {
  trimExceededLength,
  setErrHandler,
  v$Notify,
  contactFilter,
  getMfoFromIban,
  getBeautyNum,
  getSafe,
} from '@/utils/helperFunc'
import { ibanMask, maxNumLength } from '@/utils/masks'
import Loader from '@/components/Loader.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { setSnackbar } from 'best-modules/plugins'
import { fillDirectory } from '@/plugins/directory/index.ts'

export default {
  components: {
    Loader,
    edrContractorMapper,
  },
  emits: [
    'tableChange',
    'update:dialogState',
    'update:dialog',
    'updateCompanyGroup',
    'openDataAssign',
    'disableBankAccountStatuses',
    'updateContractor',
  ],
  setup() {
    return { v$: useVuelidate(), ...useSelect(), ibanMask }
  },
  props: {
    contractor: { type: Object },
    dialog: { type: Boolean, default: false },
    dialogItem: { type: Object },
    dialogState: { type: String },
    contractorId: { type: Number },
    dialogParams: { type: Object, default: () => {} },
    openDataSync: { type: Boolean },
    ODContractor: { type: Object },
  },
  validations() {
    const bank =
      this.addBankAccount || this.editBankAccount
        ? {
            bankAccount: {
              bankName: { required },
              mfo: { required },
              accountNumber: { required, minLength: minLength(29) },
              type: {
                id: { required },
              },
              currency: {
                id: { required },
              },
            },
          }
        : {}

    const beneficiar =
      this.addBeneficiar || this.editBeneficiar || this.deleteBeneficiar
        ? {
            beneficiar: {
              name: { required },
              amount: { required },
              amountPercent: {
                required,
                minValue: this.deleteBeneficiar ? minValue(0) : minValue(0.01),
              },
              code: { minLength: minLength(8) },
              founderRoleId: { required },
              registrationCountryId: this.deleteBeneficiar ? {} : { required },
              residentCountryId: this.deleteBeneficiar ? {} : { required },
              location: this.deleteBeneficiar ? {} : { required },
            },
          }
        : {}

    return {
      ...bank,
      ...beneficiar,
    }
  },
  data: () => ({
    contractors: [],
    bankAccount: {
      typeId: null,
      accountNumber: null,
      bankName: null,
      mfo: null,
      currency: {
        id: null,
      },
      isBasic: 0,
      contactId: null,
      type: {
        id: null,
      },
    },
    beneficiar: {
      name: null,
      founder_role: { id: 2, name: 'Засновник' },
      founderRoleId: 2,
      amount: null,
      amountPercent: null,
      contractorId: null,
      residentCountryId: 1,
      registrationCountryId: 1,
      location: null,
      code: null,
    },
    innerState: false,
    mfoLoading: false,
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    contractorData() {
      return this.contractor
    },
    currentAccount() {
      return this.bankAccount?.type?.id === 1
    },
    imutableCurrAccount() {
      return this.bankAccount?.type?.id === 1
    },
    nameErr() {
      return setErrHandler(this.v$.name)
    },
    interbranchNumErr() {
      return setErrHandler(this.v$.bankAccount.mfo)
    },
    bankNameErr() {
      return setErrHandler(this.v$.bankAccount.bankName)
    },
    bankAccountTypeIdErr() {
      return setErrHandler(this.v$.bankAccount.type)
    },
    bankAccountTypeErr() {
      return setErrHandler(this.v$.bankAccount.bankAccountType)
    },
    currencyErr() {
      return setErrHandler(this.v$.bankAccount.currency)
    },
    addressTypeIdErr() {
      return setErrHandler(this.v$.newAddress.addressTypeId)
    },
    countryIdErr() {
      return setErrHandler(this.v$.newAddress.countryId)
    },
    regionIdErr() {
      return setErrHandler(this.v$.newAddress.regionId)
    },
    districtIdErr() {
      return setErrHandler(this.v$.newAddress.districtId)
    },
    cityIdErr() {
      return setErrHandler(this.v$.newAddress.cityId)
    },
    addressErr() {
      return setErrHandler(this.v$.newAddress.address)
    },
    indexErr() {
      return setErrHandler(this.v$.newAddress.index)
    },
    // Beneficiars
    beneficiarNameErr() {
      return setErrHandler(this.v$.beneficiar.name)
    },
    beneficiarAmountErr() {
      return setErrHandler(this.v$.beneficiar.amount)
    },
    beneficiarAmountPercentErr() {
      return setErrHandler(this.v$.beneficiar.amountPercent)
    },
    beneficiarFounderRoleIdErr() {
      return setErrHandler(this.v$.beneficiar.founderRoleId)
    },
    beneficiarEdrpouErr() {
      return setErrHandler(this.v$.beneficiar.code)
    },
    beneficiarLocationErr() {
      return setErrHandler(this.v$.beneficiar.location)
    },
    beneficiarRegistrationCountryIdErr() {
      return setErrHandler(this.v$.beneficiar.registrationCountryId)
    },
    beneficiarResidentCountryIdErr() {
      return setErrHandler(this.v$.beneficiar.residentCountryId)
    },
    bankAccountNumErr() {
      const errors = []
      if (!this.v$.bankAccount.accountNumber.$error) return errors
      !this.v$.bankAccount.accountNumber.minLength &&
        errors.push('Невiрний номер IBAN')
      !this.v$.bankAccount.accountNumber.required &&
        errors.push('Обов`язково для заповнення')
      return errors
    },
    // Dialog States
    changeFrontManager() {
      return this.dialogState === 'changeFrontManager'
    },
    changeMiddleManager() {
      return this.dialogState === 'changeMiddleManager'
    },
    // Banks
    addBankAccount() {
      return this.dialogState === 'addBankAccount'
    },
    editBankAccount() {
      return this.dialogState === 'editBankAccount'
    },
    deleteBankAccount() {
      return this.dialogState === 'deleteBankAccount'
    },
    // Beneficiars
    addBeneficiar() {
      return this.dialogState === 'addBeneficiar'
    },
    deleteBeneficiar() {
      return this.dialogState === 'deleteBeneficiar'
    },
    editBeneficiar() {
      return this.dialogState === 'editBeneficiar'
    },
    syncWithOpenData() {
      return this.dialogState === 'syncWithOpenData'
    },
    contactId() {
      return parseInt(this.$route.params.id)
    },
  },
  methods: {
    urlGetAllContractors,
    getSafe,
    contactFilter,
    keepDigit,
    formutSum,
    changeInputVal,
    maxNum,
    urlUpdateBeneficiar,
    trimExceededLength,
    urlCreateFounder,
    urlUpdateFounder,
    urlDeleteFounder,
    maxNumLength,
    getMfoFromIban,
    inputNumber,
    async getBank(event) {
      this.trimExceededLength('cont-bank-num', 29)
      const { mfo = null, name = null } = await this.getMfoFromIban(event)
      this.bankAccount.bankName = name
      this.bankAccount.mfo = mfo
    },
    /* Action controller */
    async switchAction() {
      this.$emit('update:dialogState', null)

      if (this.addBankAccount) {
        this.bankAccount.contractorId = this.contractorId
        this.innerState = false
        return this.createBankAccount().then(() => {
          this.$emit('updateContractor')
        })
      } else if (this.editBankAccount) {
        this.bankAccount.contractorId = this.contractorId
        this.innerState = false
        return this.updateBankAccount().then(() => {
          this.$emit('tableChange')
        })
      } else if (this.addBeneficiar) {
        // this.beneficiar.founder_role = this.beneficiar.founder_role.name
        this.beneficiar.contractorId = this.contractorId
        this.beneficiar.code = String(this.beneficiar.code)
        this.beneficiar.amount = Number(
          keepDigit(this.beneficiar.amount) || 0
        ).toFixed(4)
        this.beneficiar.amountPercent = Number(
          keepDigit(this.beneficiar.amountPercent) || 0
        ).toFixed(6)
        return this.createBeneficiar().then(() => {
          this.$emit('tableChange')
        })
      } else if (this.editBeneficiar) {
        // this.beneficiar.founder_role = this.beneficiar.founder_role.name
        this.beneficiar.contractorId = this.contractorId
        this.beneficiar.code = String(this.beneficiar.code)
        this.beneficiar.amount = Number(
          keepDigit(this.beneficiar.amount) || 0
        ).toFixed(4)
        this.beneficiar.amountPercent = Number(
          keepDigit(this.beneficiar.amountPercent) || 0
        ).toFixed(6)
        return this.updateBeneficiar().then(() => {
          this.$emit('tableChange')
        })
      } else if (this.deleteBeneficiar) {
        return this.removeBeneficiar().then(() => {
          this.$emit('tableChange')
        })
      } else if (this.changeFrontManager) {
        this.innerState = false
        /** */
      } else if (this.changeMiddleManager) {
        this.innerState = false
        /** */
      } else if (this.syncWithOpenData) {
        this.innerState = false
        this.$emit('openDataAssign')
      }
    },
    disableBankAccountStatuses() {
      this.bankAccount.isBasic
        ? this.$emit('disableBankAccountStatuses', this.bankAccount.accountId)
        : ''
    },
    /** Bank methods */
    createBankAccount() {
      const req = {
        ...this.bankAccount,
        typeId: this.bankAccount.type.id,
        currencyId: this.bankAccount.currency.id,
        entityId: this.contractorId,
        entityTypeId: 2,
      }
      return this.$axios
        .post(urlCreateBankAccount(), req)
        .then(res => {
          setSnackbar({ text: 'Рахунок додано' })
          return res
        })
        .catch(this.$err)
    },
    updateBankAccount() {
      const req = {
        ...this.bankAccount,
        typeId: this.bankAccount.type.id,
        currencyId: this.bankAccount.currency.id,
        entityId: this.contactId,
        entityTypeId: 2,
      }
      return this.$axios
        .post(urlUpdateBankAccount(this.bankAccount.id), req)
        .then(() => setSnackbar({ text: 'Рахунок оновлено' }))
        .catch(this.$err)
    },
    clearObjectFields(object) {
      for (const key in object) {
        if (typeof object[key] === 'boolean') {
          object[key] = false
          continue
        }
        if (typeof object[key] === 'object') {
          this.clearObjectFields(object[key])
          continue
        }
        object[key] = null
      }
    },
    /** Beneficiar methods */
    createBeneficiar() {
      return this.$axios
        .post(this.urlCreateFounder(), this.beneficiar)
        .then(res => {
          this.innerState = false
          setSnackbar({ text: 'Створено засновника' })
          return res
        })
        .catch(err => this.$err(err, () => (this.innerState = false)))
    },
    updateBeneficiar() {
      return this.$axios
        .post(this.urlUpdateFounder(this.beneficiar?.id), this.beneficiar)
        .then(() => {
          this.innerState = false
          setSnackbar({ text: 'Бенефiцiара оновлено' })
        })
        .catch(err => this.$err(err, () => (this.innerState = false)))
    },
    removeBeneficiar() {
      return this.$axios
        .delete(this.urlDeleteFounder(this.beneficiar?.id))
        .then(() => {
          setSnackbar({ text: 'Засновника видалено' })
          this.innerState = false
        })
        .catch(err => this.$err(err, () => (this.innerState = false)))
    },
    clearDialogFields() {
      this.clearObjectFields(this.bankAccount)
      this.clearObjectFields(this.beneficiar)
      this.beneficiar.founderRoleId = 2
      this.beneficiar.founder_role = { id: 2, name: 'Засновник' }
      this.clearObjectFields(this.newAddress)
      this.clearObjectFields(this.contact)
      this.bankAccount.isBasic = false
    },
    /** */
    submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(
          {
            ...(this.v$.bankAccount || {}),
            ...(this.v$.beneficiar || {}),
          },
          'contractorDialog'
        )
        return
      }
      this.switchAction()
    },
  },
  watch: {
    'bankAccount.accountNumber': function (accountNumber) {
      this.getBank(accountNumber)
    },
    dialog(val) {
      this.v$.$reset()
      this.innerState = val
      this.cityState = false
    },
    innerState(val) {
      val && this.clearDialogFields()
      this.$emit('update:dialog', val)
      if (val) {
        fillDirectory('bankAccountTypes')
        if (this.editBankAccount || this.addBankAccount) {
          Object.assign(this.bankAccount, Object.clone(this.dialogItem))
        }
        if (val && (this.editBeneficiar || this.deleteBeneficiar)) {
          Object.assign(this.beneficiar, this.dialogItem)
          this.beneficiar.amount = getBeautyNum(this.beneficiar.amount, {
            float: 4,
          })
        }
        if (
          this.addBeneficiar ||
          this.editBeneficiar ||
          this.deleteBeneficiar
        ) {
          fillDirectory('nationalityList')
        }
      }
    },
  },
}
</script>
