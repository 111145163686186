<template>
  <div>
    <CallingHistory :tableBody="callHistoryItems"> </CallingHistory>
  </div>
</template>

<script lang="ts">
import CallingHistory from '@/components/CallingHistory.vue'
import { computed, inject, ref } from 'vue'
import { urlGetApplicationCallingHistory } from '@/pages/request'
import { handleAsync } from 'best-modules/plugins'
import axios from '@/plugins/axios.js'
import {
  applicationKey,
  updateApplicationObjectKey,
} from '@/pages/applications/single/injectionKeys'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { Application } from '@/utils/types/application'

export default {
  name: 'CallHistory',
  components: { CallingHistory },
  setup() {
    const updateApplicationObject = inject(updateApplicationObjectKey)
    const application = computed<CachedObjectProxy<Application>>({
      get: () => inject(applicationKey),
      set: updateApplicationObject,
    })

    const callHistoryItems = ref([])

    const getCallHistory = () => {
      return handleAsync('getCallHistory', () => {
        return axios
          .get(urlGetApplicationCallingHistory(application.value.id))
          .then(res => {
            callHistoryItems.value = res.data.data
          })
      })
    }

    getCallHistory()

    return { callHistoryItems }
  },
}
</script>

<style scoped></style>
