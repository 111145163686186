<template>
  <div>
    <b-section-loader
      v-if="$loading.isLoading('getApplication')"></b-section-loader>
    <div v-else>
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex gap-3 align-center">
          <v-btn
            v-show="offerCredentials.sendType"
            v-tooltip:bottom="'Назад'"
            color="red"
            density="compact"
            icon="mdi-arrow-left-circle"
            @click="offerCredentials.sendType = null">
          </v-btn>
          <span
            style="color: #09487a; font-size: 1rem"
            class="font-weight-bold">
            {{ 'Надіслати КП' }}
          </span>
        </div>
        <div class="d-flex gap-3 align-center">
          <v-switch
            v-if="offerCredentials.sendType"
            v-model="advance"
            color="red"
            hide-details
            dense>
            <template #label>
              <span style="font-size: 0.82rem">
                {{ 'Відобразити аванс' }}
              </span>
            </template>
          </v-switch>
          <v-btn
            color="grey"
            icon="mdi-close-circle-outline"
            @click="$store.commit('closeDialog')">
          </v-btn>
        </div>
      </div>
      <send-offer-select
        v-if="!offerCredentials.sendType"
        v-model="offerCredentials.sendType"></send-offer-select>
      <div v-else>
        <send-offer-email
          v-if="offerCredentials.sendType === 'email'"
          v-model:offer-credentials="offerCredentials"
          :emails="emails"
          :v$="v$"></send-offer-email>
        <send-offer-social
          v-if="
            ['viber', 'whats-up', 'telegram'].includes(
              offerCredentials.sendType
            )
          "
          v-model:offer-credentials="offerCredentials"
          :phones="phones"
          :v$="v$"></send-offer-social>
        <div class="d-flex justify-end">
          <v-btn
            style="width: 150px"
            color="#09487a"
            variant="flat"
            height="30"
            :loading="$loading.isLoading('sendOffer')"
            @click="submit">
            Відправити
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import axios from '@/plugins/axios.js'
import { urlApplicationById, sendClientOffer } from '@/pages/request.js'
import SendOfferSelect from './SendTypeSelect.vue'
import SendOfferEmail from './SendOfferEmail.vue'
import SendOfferSocial from './SendOfferSocial.vue'
import { BSectionLoader } from 'best-modules/components'
import { Application, OfferCredentials } from '@/utils/types/application'
import { required, requiredIf } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { cloneDeep, omit } from 'lodash'
import {
  setClientOfferCurrency,
  getManagerPhoto,
  getApplicationContacts,
} from '@/utils/helperFunc.js'
import { createPdf } from '@/utils/make-pdf/client-offer/constructor'
import { useStore } from 'vuex'

export default {
  name: 'SendOffer',
  components: {
    BSectionLoader,
    SendOfferSelect,
    SendOfferEmail,
    SendOfferSocial,
  },
  props: {
    dialog: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore()
    const user = computed(() => store.state.user)
    const advance = ref<boolean>(true)
    const emails = ref([])
    const phones = ref([])
    const application = reactive<Application>({} as Application)

    const setApplication = (obj: Application) => {
      Object.assign(application, cloneDeep(obj))

      emails.value.push(...getApplicationContacts(application, 'emails'))
      phones.value.push(...getApplicationContacts(application, 'phones'))
    }

    if (props.dialog.dialogItem.application) {
      console.log('nice', props.dialog.dialogItem.application)
      setApplication(props.dialog.dialogItem.application)
    } else if (props.dialog.dialogItem.applicationId) {
      handleAsync('getApplication', () => {
        return axios
          .get(urlApplicationById(props.dialog.dialogItem.applicationId))
          .then(res => setApplication(res.data))
      })
    }

    const getOfferFileName = (offer): string => {
      const req = offer?.requestData || offer
      const leasedAssertMarkName = req?.leasedAssertMark?.name || ''
      const leasedAssertModelName = req?.leasedAssertModel?.name || ''
      const advance = `${req.advance}%`
      const term = `${req.leasingTerm}мiс`
      const currency = req.currency || ''
      const id = offer.requestId || ''

      return `${leasedAssertMarkName}_${leasedAssertModelName}_${advance}_${term}_${currency}_${id}.pdf`.replace(
        /\s/g,
        '_'
      )
    }

    const defaultOffer: OfferCredentials = {
      calculationId: props.dialog.dialogItem.calculation.id,
      fileName: getOfferFileName(props.dialog.dialogItem.calculation),
      offer: null,
      messageSubject: 'Клієнтська пропозиція від Best24',
      messageText: null,
      sendType: null,
      extraFiles: null,
      phones: [],
      emails: [],
    }
    const offerCredentials = reactive<OfferCredentials>(cloneDeep(defaultOffer))
    watch(
      () => offerCredentials.sendType,
      v => {
        if (!v) {
          Object.assign(offerCredentials, omit(defaultOffer, ['sendType']))
          advance.value = true
          v$.value.$reset()
        }
      }
    )
    const rules = {
      messageText: { required: required },
      messageSubject: { required: required },
      phones: {
        required: requiredIf(() =>
          ['viber', 'whats-up', 'telegram'].includes(offerCredentials.sendType)
        ),
      },
      emails: {
        required: requiredIf(() => offerCredentials.sendType === 'email'),
      },
    }

    const v$ = useVuelidate(rules, offerCredentials)

    const generatePdf = (offer): Promise<Blob> => {
      if (offer.resultData.import) {
        /* TODO */
        delete offer.resultData.import
      }
      if (offer.requestData && offer.requestData?.programFSN) {
        offer.programFSN = offer.requestData.programFSN
      }
      if (offer?.resultData?.maintenance) {
        offer.maintenance = offer?.resultData?.maintenance
        offer.maintenance.graph.unshift({
          n: '0',
          payment: '0',
          servicePayment: '0',
        })

        delete offer?.resultData?.maintenance
      }
      return createPdf(offer).catch(e => {
        throw new Error(e)
      })
    }

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return handleAsync('sendOffer', async () => {
          const offer = await setClientOfferCurrency(
            props.dialog.dialogItem.calculation,
            {
              currency: (props.dialog.dialogItem.currency as string) || 'UAH',
              currencyRate: null,
              keepIntegers: false,
              fixedNum: 2,
              noFetchRate: false,
              computedShowExport: false,
            }
          )
          offer.showAdvance = advance.value
          offer.user = cloneDeep(user.value)
          try {
            offer.user.photo = await getManagerPhoto(offer.user.photo)
          } catch (e) {
            offer.user.photo = null
          }

          const pdf = await generatePdf(offer)

          const reader = new FileReader()
          reader.readAsDataURL(pdf)

          return new Promise((resolve, reject) => {
            reader.onload = e => {
              offerCredentials.offer = e.target.result as string

              return axios
                .post(sendClientOffer(), offerCredentials)
                .then(res => {
                  if (res.data.message) {
                    setSnackbar({
                      text: res.data.message,
                      color: 'warning',
                    })
                    store.commit('closeDialog')
                    return reject(res.data.message)
                  } else {
                    setSnackbar({
                      text: `Комерційну пропозицію на ${offerCredentials.sendType} успішно відправлено`,
                    })
                    if (typeof props.dialog.params.cb === 'function') {
                      props.dialog.params.cb()
                    }
                    store.commit('closeDialog')
                    return resolve(void [0])
                  }
                })
                .catch(e => {
                  return reject(e)
                })
            }
          })
        })
      }
    }

    return {
      offerCredentials,
      advance,
      v$,
      emails,
      phones,
      submit,
      application,
    }
  },
}
</script>

<style scoped></style>
