<template>
  <div>
    <div>
      <OpenableSection title="Юридичні документи">
        <template #append-title>
          <AllDocsBtn :id="contact.id" contact legal />
        </template>
        <div>
          <FilesUploader
            v-if="contact.identityDocument"
            v-model:documents="contactData.identityDocument.document"
            :replace="false"
            label="Паспорт (для нерезидента – посвідчення на тимчасове або постійне проживання в Україні, дозвіл на працевлаштування)"
            fileName="Паспорт"
            readonly
            acceptedExt=".xlsx, .xls, .pdf, .doc, .docs"
            :list="false"
            :disableDeleteAction="!userAdmin" />
          <FilesUploader
            v-if="!contact.identityDocument"
            :replace="false"
            label="Паспорт (для нерезидента – посвідчення на тимчасове або постійне проживання в Україні, дозвіл на працевлаштування)"
            fileName="Паспорт"
            readonly
            acceptedExt=".xlsx, .xls, .pdf, .doc, .docs"
            :list="false"
            :disableDeleteAction="!userAdmin" />
          <!-- contact.identificationNumber -->
          <FilesUploader
            v-model:documents="contactData.identificationNumber"
            :replace="false"
            :disabled="!contact.isInn"
            readonly
            acceptedExt=".xlsx, .xls, .pdf, .doc, .docs"
            label="Довідка про присвоєння ідентифікаційного номера фізичної особи-платника податків"
            :list="false"
            :disableDeleteAction="!userAdmin" />
          <!-- contact.documentsPowers -->
          <FilesUploader
            v-model:documents="contactData.documentsPowers"
            :replace="false"
            :readonly="readonly"
            acceptedExt=".xlsx, .xls, .pdf, .doc, .docs"
            label="Довіреність та інші док-ти про повноваження"
            :list="false"
            :disableDeleteAction="!userAdmin" />
          <!-- contact.marriageCertificate -->
          <FilesUploader
            v-model:documents="contactData.marriageCertificate"
            :replace="false"
            :readonly="readonly"
            acceptedExt=".xlsx, .xls, .pdf, .doc, .docs"
            label="Свідоцтво про укладення/розірвання шлюбу"
            :list="false"
            :disableDeleteAction="!userAdmin" />
          <FilesUploader
            v-if="contact.fop"
            v-model:documents="contactData.fop.extractOrExcerpt"
            :replace="false"
            readonly
            acceptedExt=".xlsx, .xls, .pdf, .doc, .docs"
            label="Виписка або витяг з ЄДРЮОб ФОП та ГФ" />
          <FilesUploader
            v-model:documents="contactData.otherLegalDocuments"
            :replace="false"
            :readonly="readonly"
            acceptedExt=".xlsx, .xls, .pdf, .doc, .docs"
            label="Інші юридичні документи"
            :list="false"
            :disableDeleteAction="!userAdmin" />
        </div>
      </OpenableSection>
      <OpenableSection title="Фiнансовi документи">
        <template #append-title>
          <AllDocsBtn :id="contact.id" class="ml-2" contact fin />
        </template>
        <div>
          <FilesUploader
            v-model:documents="contactData.incomeStatement"
            :replace="false"
            :readonly="readonly"
            acceptedExt=".xlsx, .xls, .pdf, .doc, .docs"
            label="Довідка про доходи"
            :disableDeleteAction="!userAdmin"
            :list="false" />
          <!-- contact.propertyDocuments -->
          <FilesUploader
            v-model:documents="contactData.propertyDocuments"
            :replace="false"
            :readonly="readonly"
            acceptedExt=".xlsx, .xls, .pdf, .doc, .docs"
            label="Документи, які підтверджують право власності на майно"
            :disableDeleteAction="!userAdmin"
            :list="false" />
          <!-- contact.debtCertificate -->
          <FilesUploader
            v-model:documents="contactData.debtCertificate"
            :replace="false"
            :readonly="readonly"
            acceptedExt=".xlsx, .xls, .pdf, .doc, .docs"
            label="Довідка з банку/лізингової компанії/ін.кредитора про стан поточної заборгованості"
            :disableDeleteAction="!userAdmin"
            :list="false" />
          <FilesUploader
            v-if="contact.fop"
            v-model:documents="contactData.declarationForInvoice"
            :replace="false"
            :readonly="readonly"
            acceptedExt=".xlsx, .xls, .pdf, .doc, .docs"
            info="Звіт з ДПС за попередній (підсумок) та за поточний (поквартально)"
            label="Податкова декларація платника єдиного податку"
            :disableDeleteAction="!userAdmin"
            :list="false" />
          <FilesUploader
            v-if="contact.fop"
            v-model:documents="contactData.copiesOfContracts"
            :replace="false"
            :readonly="readonly"
            acceptedExt=".xlsx, .xls, .pdf, .doc, .docs"
            :info="`Копії договорів, за якими передбачається отримання доходу в ${new Date().getFullYear()} р. з основними контрагентами (постачальниками і покупцями)`"
            label="Копії договорів"
            :disableDeleteAction="!userAdmin"
            :list="false" />
          <FilesUploader
            v-model:documents="contactData.otherFinancialDocuments"
            :replace="false"
            :readonly="readonly"
            acceptedExt=".xlsx, .xls, .pdf, .doc, .docs"
            label="Інші фінансові документи"
            :list="false"
            :disableDeleteAction="!userAdmin" />
        </div>
      </OpenableSection>
    </div>
  </div>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import AllDocsBtn from '@/components/AllDocsBtn.vue'
import OpenableSection from '@/components/OpenableSection.vue'
export default {
  components: { OpenableSection, FilesUploader, AllDocsBtn },
  props: {
    contact: { type: Object },
    readonly: { type: Boolean },
  },
  computed: {
    userAdmin() {
      return this.$store?.state?.user?.roleId === 1
    },
    contactData() {
      return this.contact
    },
  },
}
</script>
