<template>
  <div>
    <v-fade-transition hide-on-leave>
      <div>
        <v-row>
          <div class="d-flex justify-space-between" style="width: 100%">
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="d-flex align-center justify-space-between gap-4 pr-5">
              <Filters :filters="filters" class="ml-2">
                <template #header-action>
                  <div class="d-flex align-center">
                    <div class="d-inline-block mr-2">
                      <v-tooltip bottom text="Відобразити - Лист активностей">
                        <template #activator="{ props }">
                          <v-btn
                            class="activity-nav-btn rounded-circle"
                            icon
                            size="x-small"
                            v-bind="props"
                            @click="activityView = 1">
                            <v-icon
                              :color="
                                activityView === 1 ? 'black' : 'grey darken-2'
                              "
                              size="23">
                              {{ 'mdi-format-list-bulleted' }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Відобразити - Лист активностей</span>
                      </v-tooltip>
                    </div>
                    <div class="d-inline-block mr-2">
                      <v-tooltip
                        bottom
                        text="Відобразити - Календар активностей">
                        <template #activator="{ props }">
                          <v-btn
                            class="activity-nav-btn rounded-circle"
                            icon
                            size="x-small"
                            v-bind="props"
                            @click="activityView = 2">
                            <v-icon
                              size="23"
                              :color="
                                activityView === 2 ? 'black' : 'grey darken-2'
                              ">
                              {{ 'mdi-calendar-month-outline' }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Відобразити - Календар активностей</span>
                      </v-tooltip>
                    </div>
                    <v-btn
                      data-readonly="hide"
                      class="text-white"
                      style="background: rgb(252, 114, 71); border-radius: 4px"
                      @click="
                        openDialog(
                          'addEvent',
                          {},
                          {
                            dialogWidth: 1000,
                            isCreateMeeting: true,
                            submitText: 'Створити',
                          }
                        )
                      ">
                      Нова зустріч
                    </v-btn>
                  </div>
                </template>
              </Filters>
            </v-col>
          </div>
          <v-col :cols="12" class="pr-0">
            <div v-show="!pageLoad" class="pt-0">
              <!-- eslint-disable -->
              <ActivitiesView
                :activityView="activityView"
                :events="events"
                :tHead="activitiesMeetingsListHeaders">
              </ActivitiesView>
              <Pagination
                v-show="events.length"
                :getItems="getEvents"
                :trigger="paginationTrigger" />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
    <SectionLoader v-if="pageLoad"> </SectionLoader>
  </div>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import ActivitiesView from '@/components/AcvititiesView.vue'
import Pagination from '@/components/Pagination.vue'
import {
  backDate,
  toFormatArrWithDate,
  getResponsibleShortName,
  getContactInitials,
} from '@/utils/helperFunc'
import {
  urlGetActivities,
  urlFilterActivities,
  urlGetActivityCategories,
} from '@/pages/request.js'
import { useSelect } from '@/utils/mixins/useSelect'
import Filters from '@/components/Filters.vue'
import { useVuelidate } from '@vuelidate/core'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import { activitiesMeetingsListHeaders } from '@/utils/tableHeaders'
import { fillDirectory } from '@/plugins/directory'
export default {
  components: { Filters, Pagination, SectionLoader, ActivitiesView },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'activityStatuses' },
      },
      responsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'activeUsers',
        },
        focus: () => fillDirectory('allUsers'),
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
    })
    return {
      v$: useVuelidate(),
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      event: {
        address: null,
        typeId: null,
        startDate: null,
        endDate: null,
        invitations: [],
        description: null,
        contactId: null,
        contractorId: null,
        statusId: 1,
        resultId: null,
        documents: [],
      },
      events: [],
      activityView: 1,
      id: null,
      selectedEntities: [],
      pageLoad: false,
      activitiesMeetingsListHeaders,
    }
  },
  methods: {
    backDate,
    urlGetActivities,
    urlFilterActivities,
    urlGetActivityCategories,
    toFormatArrWithDate,
    getResponsibleShortName,
    getEvents(page = 1) {
      this.pageLoad = true
      return this.$axios
        .get(this.urlGetActivities({ page, filters: this.filtersQueryObj }))
        .then(res => {
          this.events.splice(0)
          this.events.push(
            ...this.toFormatArrWithDate(res.data.data, 'startDate', 'endDate')
          )

          return res
        })
        .catch(err => this.$err(err, () => (this.pageLoad = false)))
        .finally(() => (this.pageLoad = false))
    },
    openDialog(action, item = {}, params = {}) {
      this.$store.commit('setDialog', {
        title: 'Подія',
        dialogItem: item,
        params: params,
        action: action,
      })
    },
  },
}
</script>
