import axios from 'axios'
import {
  urlGetSingleDflContract,
  urlGetSingleGCContract,
  urlGetExtraAgreementDflById,
  urlGetExtraAgreementDkpById,
  urlGetServiceContractsSingle,
} from '@/pages/request'
import { toFormatDate } from '@/utils/helperFunc'
import { clear } from '../helper'
import { setSnackbar } from 'best-modules/plugins'
import router from '@/router/router'

const contracts = {
  state: () => ({
    dflContractList: [],
    serviceContractList: [],
    extraAgreement: {},
    $extraAgreement: {},
    dflContract: {
      typeId: null,
      number: null,
      date: null,
      orderCalculationId: null,
      lesseeSignatoryId: null,
      message: null,
    },
    serviceContract: {
      lesseeSignatoryId: null,
    },
    $vDfl: {},
    $vService: {},
    dflCache: null,
    serviceCache: null,
    extraAgreementCache: null,
    dflContractsLoading: false,
    dflContractLoading: false,
    serviceContractLoading: false,
    extraAgreementLoading: false,
  }),
  mutations: {
    setDflContractLesseeSignatoryId(state, value) {
      state.lesseeSignatoryId = value
    },
    setServiceContractLesseeSignatoryId(state, value) {
      state.lesseeSignatoryId = value
    },
    setDflProvider(state, id) {
      state.dflContract.providerId = id
    },
    setServiceProvider(state, id) {
      state.serviceContract.providerId = id
    },
    setGCProvider(state, id) {
      state.gcContract['providerId'] = id
      // Vue.set(state.gcContract, 'providerId', id)
    },
    setDflContractState(state, value) {
      state.dflContractLoading = value
    },
    setServiceContractState(state, value) {
      state.serviceContractLoading = value
    },
    setGCContractState(state, value) {
      state.gcContractLoading = value
    },
    setExtraAgreementState(state, value) {
      state.extraAgreementLoading = value
    },
    agreementValidity(state, value) {
      state.$extraAgreement = Object.assign({}, state.$extraAgreement, value)
    },
    assignDflContract(state, dflContract) {
      Object.assign(state.dflContract, dflContract)
    },
    assignDflContractMessage(state, message) {
      state.dflContract.message = message
    },
    assignServiceContract(state, serviceContract) {
      Object.assign(state.serviceContract, serviceContract)
    },
    assignGCContract(state, gcContract) {
      Object.assign(state.gcContract, gcContract)
    },
    assignExtraAgreement(state, extraAgreement) {
      Object.assign(state.extraAgreement, extraAgreement)
    },
    setExtraAgreementCalculation(state, calculation) {
      state.extraAgreement.calculation = calculation
    },
    setDflContractCache(state, string) {
      state.dflCache = string
    },
    setServiceContractCache(state, string) {
      state.serviceCache = string
    },
    setExtraAgreementCache(state, string) {
      state.extraAgreementCache = string
    },
    clearDflContract(state) {
      clear(state.dflContract)
      state.$vDfl = {}
      state.dflCache = null
    },
    clearServiceContract(state) {
      clear(state.serviceContract)
      state.$vService = {}
      state.serviceCache = null
    },
    setDflContractValidation(state, value) {
      state.$vDfl = Object.assign({}, state.$vDfl, value)
    },
    setServiceContractValidation(state, value) {
      state.$vService = Object.assign({}, state.$vService, value)
    },
  },
  /* eslint-enable no-unused-vars */
  actions: {
    getDflContract({ commit }, id) {
      commit('setDflContractState', true)
      return axios.get(urlGetSingleDflContract(id)).then(res => {
        if (res?.data?.message) {
          const err = res?.data?.message
          commit('assignDflContractMessage', res.data.message)
          // return this.$setSnackbar({text: err, color: 'error'})
          return console.log(err)
        }

        if (res?.data?.constructor?.name === 'Object') {
          if (!res.data.lesseeSignatories)
            res.data.lesseeSignatories = [{ contactId: null, basisId: null }]
          commit('assignDflContract', res.data)
          commit('setDflContractState', false)
          commit('setDflProvider', res?.data?.slider?.providerId)

          return res
        } else
          throw new Error(
            '[$store.contract.getDflContract]: invalid type: ' + res?.data
          )
      })
    },
    getServiceContract({ commit }, id) {
      commit('setServiceContractState', true)
      return axios.get(urlGetServiceContractsSingle(id)).then(res => {
        if (res?.data?.constructor?.name === 'Object') {
          if (!res.data.lesseeSignatories)
            res.data.lesseeSignatories = [{ contactId: null, basisId: null }]
          commit('assignServiceContract', res.data)
          commit('setServiceContractState', false)
          commit('setServiceProvider', res?.data?.slider?.providerId)
          return res
        } else
          throw new Error(
            '[$store.contract.getServiceContract]: invalid type: ' + res?.data
          )
      })
    },
    getGCContract({ commit }, id) {
      commit('setGCContractState', true)
      return axios.get(urlGetSingleGCContract(id)).then(res => {
        if (res?.data?.constructor?.name === 'Object') {
          res.data.lastAdvanceDate = toFormatDate(
            res.data.lastAdvanceDate,
            true
          )
          res.data.maxTermTransferDate = toFormatDate(
            res.data.maxTermTransferDate,
            true
          )
          commit('assignGCContract', res.data)
          commit('setGCContractState', false)
          commit('setGCProvider', res?.data?.slider?.providerId)
          return res
        } else
          throw new Error(
            '[$store.contract.getGCContract]: invalid type: ' + res?.data
          )
      })
    },
    getExtraAgreement({ commit }, { duType, id }) {
      commit('setExtraAgreementState', true)
      const url =
        duType === 'dfl'
          ? urlGetExtraAgreementDflById
          : urlGetExtraAgreementDkpById

      return axios
        .get(url(id))
        .then(res => {
          if (res?.data?.message) {
            const err = res?.data?.message
            return console.log(err)
          }

          if (res?.data?.constructor?.name === 'Object') {
            const signatoriesKey =
              duType === 'dfl' ? 'lesseeSignatories' : 'providerSignatories'
            if (!res.data[signatoriesKey])
              res.data[signatoriesKey] = [{ contactId: null, basisId: null }]
            commit('assignExtraAgreement', res.data)
            commit('setExtraAgreementState', false)
            return res
          } else {
            setSnackbar({ text: 'Договір не знайдено' })
            return router.go(-1)
          }
        })
        .catch(error => {
          commit('setExtraAgreementState', false)
          console.error(error)
          throw error
        })
    },
  },
  getters: {
    //...
  },
}

export { contracts }
