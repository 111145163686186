<template>
  <div>
    <v-tabs v-model="currentTab" class="mb-5 mt-5" show-arrows :height="38">
      <v-tab value="tasks">Нагадування</v-tab>
      <v-tab value="sms">Повідомлення</v-tab>
      <v-tab value="offers">Відправлені КП</v-tab>
      <v-tab value="callHistory">Історія дзвінків</v-tab>
    </v-tabs>

    <v-tabs-window v-model="currentTab">
      <v-tabs-window-item value="tasks">
        <div class="section-title">Нагадування</div>
        <TaskPlan
          :tasks="application.taskSchedulers || []"
          :applicationId="application.id"
          @update="$store.commit('refreshPage')" />
      </v-tabs-window-item>

      <v-tabs-window-item value="sms">
        <div class="section-title mb-8">Повідомлення</div>
        <PhoneMessages
          :readonly="application.readOnly"
          :messages="application.sends || []"
          :applicationId="application.id" />
      </v-tabs-window-item>
      <v-tabs-window-item value="offers">
        <div class="section-title">Відправлено КП</div>
        <offers></offers>
      </v-tabs-window-item>
      <v-tabs-window-item value="callHistory">
        <div class="section-title">Історія дзвінків</div>
        <call-history></call-history>
      </v-tabs-window-item>
    </v-tabs-window>
  </div>
</template>

<script lang="ts">
import { computed, inject, ref } from 'vue'
import {
  applicationKey,
  updateApplicationObjectKey,
} from '@/pages/applications/single/injectionKeys'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { Application } from '@/utils/types/application'
import TaskPlan from '@/components/TaskPlan.vue'
import PhoneMessages from '@/components/PhoneMessages.vue'
import Offers from './tabs/Offers.vue'
import CallHistory from './tabs/CallHistory.vue'

export default {
  name: 'Communications',
  components: {
    PhoneMessages,
    TaskPlan,
    Offers,
    CallHistory,
  },
  setup() {
    const updateApplicationObject = inject(updateApplicationObjectKey)
    const application = computed<CachedObjectProxy<Application>>({
      get: () => inject(applicationKey),
      set: updateApplicationObject,
    })

    const currentTab = ref<'tasks' | 'sms' | 'offers' | 'callHistory'>('tasks')

    return { application, currentTab }
  },
}
</script>

<style scoped>
.section-title {
  color: #08487a;
  font-weight: 700;
  font-size: 16px;
  margin: 10px 0;
}
</style>
