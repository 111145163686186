<template>
  <div>
    <div>
      <div class="mb-5 d-flex align-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="20"
          viewBox="0 0 22 20"
          fill="none">
          <path
            d="M20 14V11C20 9.89 19.11 9 18 9H12V6H14V0H8V6H10V9H4C2.89 9 2 9.89 2 11V14H0V20H6V14H4V11H10V14H8V20H14V14H12V11H18V14H16V20H22V14H20ZM10 2H12V4H10V2ZM4 18H2V16H4V18ZM12 18H10V16H12V18ZM20 18H18V16H20V18Z"
            fill="#08487A" />
        </svg>
        <span class="card-block-title">Зв’язки з заявками</span>
      </div>
      <div>
        <v-data-table
          v-if="contractorData.applications"
          :headers="tHeadApplications"
          :items="contractorData.applications"
          hide-default-footer
          :hide-default-header="!contractorData.applications"
          class="pb-2 pt-1 custom-table"
          dense>
          <template #no-data>
            <no-data></no-data>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.created_at="{ item }">
            {{ toFormatDate(item.created_at, false, true) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.responsible="{ item }">
            {{ item.responsible.fullName }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.sourceType="{ item }">
            <div class="d-flex align-center">
              <!-- Агент -->
              <svg
                v-if="item.sourceType.id === 1"
                class="mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none">
                <path
                  d="M7.50016 6.99992C9.34183 6.99992 10.8335 5.50825 10.8335 3.66658C10.8335 1.82492 9.34183 0.333252 7.50016 0.333252C5.6585 0.333252 4.16683 1.82492 4.16683 3.66658C4.16683 5.50825 5.6585 6.99992 7.50016 6.99992ZM7.50016 8.66658C5.27516 8.66658 0.833496 9.78325 0.833496 11.9999V13.6666H14.1668V11.9999C14.1668 9.78325 9.72516 8.66658 7.50016 8.66658Z"
                  fill="#08487A" />
              </svg>
              <!-- Повторне звернення -->
              <svg
                v-if="item.sourceType.id === 3"
                class="mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="10"
                viewBox="0 0 17 10"
                fill="none">
                <path
                  d="M8.5 9.58342C8.37778 9.58342 8.2632 9.56417 8.15625 9.52567C8.04931 9.48717 7.95 9.42239 7.85834 9.33134L2.08334 3.55633V6.83342C2.08334 7.09314 1.99534 7.311 1.81934 7.487C1.64334 7.663 1.42578 7.7507 1.16667 7.75008C0.906948 7.75008 0.689086 7.66208 0.513086 7.48608C0.337086 7.31008 0.249392 7.09253 0.250003 6.83342V1.33342C0.250003 1.0737 0.338003 0.855835 0.514003 0.679835C0.690003 0.503835 0.907559 0.41614 1.16667 0.416751H6.66667C6.92639 0.416751 7.14425 0.504751 7.32025 0.680751C7.49625 0.856751 7.58395 1.07431 7.58334 1.33342C7.58334 1.59314 7.49534 1.811 7.31934 1.987C7.14334 2.163 6.92578 2.2507 6.66667 2.25008H3.36667L8.5 7.38342L14.825 1.05842C14.9931 0.890362 15.2069 0.802668 15.4667 0.795335C15.7264 0.788001 15.9403 0.875695 16.1083 1.05842C16.2917 1.22647 16.3833 1.44036 16.3833 1.70008C16.3833 1.95981 16.2917 2.18133 16.1083 2.36467L9.14167 9.33134C9.05 9.423 8.9507 9.48808 8.84375 9.52658C8.73681 9.56508 8.62223 9.58403 8.5 9.58342Z"
                  fill="#08487A" />
              </svg>
              <!-- Пов'язана компанія з нашим клієнтом -->
              <svg
                v-if="item.sourceType.id === 4"
                class="mr-1"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.4998 3.83333H11.4998C11.2788 3.83333 11.0669 3.92113 10.9106 4.07741C10.7543 4.23369 10.6665 4.44565 10.6665 4.66667V7.16667H8.99984V1.33333C8.99984 1.11232 8.91204 0.900358 8.75576 0.744078C8.59948 0.587797 8.38752 0.5 8.1665 0.5H1.49984C1.27882 0.5 1.06686 0.587797 0.910582 0.744078C0.754301 0.900358 0.666504 1.11232 0.666504 1.33333V14.6667C0.666504 14.8877 0.754301 15.0996 0.910582 15.2559C1.06686 15.4122 1.27882 15.5 1.49984 15.5H16.4998C16.7208 15.5 16.9328 15.4122 17.0891 15.2559C17.2454 15.0996 17.3332 14.8877 17.3332 14.6667V4.66667C17.3332 4.44565 17.2454 4.23369 17.0891 4.07741C16.9328 3.92113 16.7208 3.83333 16.4998 3.83333ZM5.6665 3H7.33317V4.66667H5.6665V3ZM3.99984 11.3333H2.33317V9.66667H3.99984V11.3333ZM3.99984 8H2.33317V6.33333H3.99984V8ZM3.99984 4.66667H2.33317V3H3.99984V4.66667ZM7.33317 11.3333H5.6665V9.66667H7.33317V11.3333ZM7.33317 8H5.6665V6.33333H7.33317V8ZM14.8332 11.3333H13.1665V9.66667H14.8332V11.3333ZM14.8332 8H13.1665V6.33333H14.8332V8Z"
                  fill="#08487A" />
              </svg>
              <!-- Рекомендація нашого співробітника -->
              <svg
                v-if="item.sourceType.id === 5"
                class="mr-1"
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.875 0.875C10.5435 0.875 10.2255 1.0067 9.99112 1.24112C9.7567 1.47554 9.625 1.79348 9.625 2.125V4.625C9.625 4.95652 9.7567 5.27446 9.99112 5.50888C10.2255 5.7433 10.5435 5.875 10.875 5.875V7.75L13.125 5.875H15.875C16.2065 5.875 16.5245 5.7433 16.7589 5.50888C16.9933 5.27446 17.125 4.95652 17.125 4.625V2.125C17.125 1.79348 16.9933 1.47554 16.7589 1.24112C16.5245 1.0067 16.2065 0.875 15.875 0.875H10.875ZM5.25 4C4.58696 4 3.95107 4.26339 3.48223 4.73223C3.01339 5.20107 2.75 5.83696 2.75 6.5C2.75 7.16304 3.01339 7.79893 3.48223 8.26777C3.95107 8.73661 4.58696 9 5.25 9C5.91304 9 6.54893 8.73661 7.01777 8.26777C7.48661 7.79893 7.75 7.16304 7.75 6.5C7.75 5.83696 7.48661 5.20107 7.01777 4.73223C6.54893 4.26339 5.91304 4 5.25 4ZM8.375 10.25H2.125C1.62772 10.25 1.15081 10.4475 0.799175 10.7992C0.447544 11.1508 0.25 11.6277 0.25 12.125C0.25 13.52 0.82375 14.6375 1.765 15.3937C2.69125 16.1375 3.93375 16.5 5.25 16.5C6.56625 16.5 7.80875 16.1375 8.735 15.3937C9.675 14.6375 10.25 13.52 10.25 12.125C10.25 11.6277 10.0525 11.1508 9.70083 10.7992C9.34919 10.4475 8.87228 10.25 8.375 10.25Z"
                  fill="#08487A" />
              </svg>
              <span>
                {{ item.sourceType.name || '---' }}
              </span>
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.applicationName="{ item }">
            <ALink
              :route="{
                name: 'edit-application',
                params: { applicationId: item.id },
              }"
              :text="item.applicationName">
            </ALink>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>
<script>
import noData from '@/components/no-table-data.vue'
import { mapState } from 'vuex'
import { toFormatDate, getResponsibleShortName } from '@/utils/helperFunc'
import ALink from '@/components/Link.vue'

export default {
  components: { ALink, noData },
  props: { contractor: { type: Object } },
  data: () => ({
    tHeadApplications: [
      { title: 'Статус', key: 'status.name', sortable: false, align: 'start' },
      {
        title: 'Назва заявки',
        key: 'applicationName',
        sortable: false,
        align: 'start',
      },
      { title: 'Дата', key: 'created_at', sortable: false, align: 'start' },
      {
        title: 'Потреба',
        key: 'clientNeed.name',
        sortable: false,
        align: 'start',
      }, // add client_need.name
      {
        title: 'Джерело',
        key: 'sourceType.name',
        align: 'start',
        sortable: false,
      },
      {
        title: 'Відповідальний',
        key: 'responsible',
        align: 'start',
        sortable: false,
      },
    ],
  }),
  computed: {
    contractorData() {
      return this.contractor
    },
    ...mapState({ selects: state => state.selectItems }),
  },
  methods: {
    getResponsibleShortName,
    toFormatDate,
  },
}
</script>
