<template>
  <div>
    <Filters :filters="filters">
      <template #header-action>
        <v-btn
          data-readonly="hide"
          variant="flat"
          color="#fc7247"
          class="mr-3"
          @click="$router.push({ name: 'new-application' })">
          <v-icon class="mr-2">
            {{ 'mdi-plus' }}
          </v-icon>
          <span>Створити заявку</span>
        </v-btn>
      </template>
    </Filters>
    <div v-show="!$loading.isLoading('getApplications')">
      <v-data-table
        :headers="applicationListHeaders"
        :items="applicationList"
        hover
        mobileBreakpoint="1200"
        :custom-sort="tableDateSort('created_at')"
        :hide-default-header="!applicationList.length"
        hide-default-footer
        :items-per-page="-1"
        @dblclick:row="(e, row) => navigateToApplication(row.item.id)">
        <template #item.statusId="{ item }">
          <div
            class="font-weight-bold"
            :style="{
              color: getColorByStatus(item.statusId),
            }">
            {{ item.status.name }}
          </div>
        </template>
        <template #item.created_at="{ item }">
          {{ new CustomDate(item.created_at).toString({ time: true }) }}
        </template>
        <template #item.sourceType.name="{ item }">
          <v-icon
            v-if="getSourceIconName(item.sourceType?.id)"
            color="#08487a"
            :icon="getSourceIconName(item.sourceType?.id)" />
          {{ item.sourceType?.name || '---' }}
        </template>
        <template #item.name="{ item }">
          <v-menu>
            <template #activator="{ props }">
              <div v-bind="props">{{ item.fullName }}</div>
            </template>

            <v-card>
              <v-card-text>
                <div class="mb-1">
                  <div class="d-flex gap-2">
                    <v-icon icon="mdi-account-circle"></v-icon>
                    <span>ФІО</span>
                  </div>

                  <div>{{ item.fullName }}</div>
                </div>

                <div class="mb-1">
                  <div class="d-flex gap-2">
                    <v-icon icon="best:phone"></v-icon>
                    <span>Телефон</span>
                  </div>

                  <div class="link" @click="copyText(item.phone)">
                    {{ item.phone }}
                  </div>
                </div>

                <div>
                  <div class="d-flex gap-2">
                    <v-icon icon="best:email"></v-icon>
                    <span>Email</span>
                  </div>

                  <div class="link" @click="copyText(item.email)">
                    {{ item.email }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
        <template #item.applicationName="{ item }">
          <ALink
            :text="item.applicationName"
            :route="{
              name: 'edit-application',
              params: { applicationId: item.id },
            }">
          </ALink>
        </template>
        <template #item.responsible="{ item }">
          {{ getResponsibleShortName(item.responsible) }}
        </template>
        <template #item.actions="{ item }">
          <TableCrud
            data-readonly="hide"
            :actions-array="[
              {
                text: 'Переглянути',
                icon: 'mdi-eye',
                action: () => navigateToApplication(item.id),
              },
            ]">
          </TableCrud>
        </template>
      </v-data-table>
      <Pagination :getItems="getApplications" :trigger="paginationTrigger" />
    </div>
    <b-section-loader
      v-if="$loading.isLoading('getApplications')"></b-section-loader>
  </div>
</template>
<script>
import { BSectionLoader } from 'best-modules/components'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import Filters from '@/components/Filters.vue'
import TableCrud from '@/components/table-crud.vue'
import { reactive } from 'vue'
import { urlGetApplicationList } from '@/pages/request'
import { applicationListHeaders } from '@/utils/tableHeaders'
import { useFilter } from '@/utils/mixins/useFilter'
import {
  tableDateSort,
  getContactInitials,
  getResponsibleShortName,
} from '@/utils/helperFunc'
import { CustomDate, copyText } from 'best-modules/utils'
import { handleAsync } from 'best-modules/plugins'

export default {
  name: 'ApplicationList',
  components: {
    TableCrud,
    Filters,
    BSectionLoader,
    ALink,
    Pagination,
  },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'applicationStatuses' },
      },
      sourceTypeId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Джерело', items: 'applicationSourceTypes' },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      clientNeedId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Потреба', items: 'clientNeeds' },
      },
      responsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'allUsers',
        },
      },
      rejectionTypeId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Причина відмови', items: 'applicationRejectStatuses' },
        show: { filterName: 'statusId', acceptedValue: [6] },
      },
      search: { value: null },
    })
    const getColorByStatus = statusId => {
      switch (statusId) {
        case 1:
          return '#62C14A'
        case 2:
          return '#0098EE'
        case 3:
          return '#FC7247'
        case 4:
          return '#EA4646'
        case 5:
          return '#EA4646'
        case 6:
          return '#EA4646'
        case 7:
          return '#6495ed'
      }
    }
    const getSourceIconName = sourceTypeId => {
      switch (sourceTypeId) {
        case 1:
          return 'mdi-account'
        case 2:
          return 'best:account-badge'
        case 3:
          return 'best:arrow-left-variant'
        case 4:
          return 'best:domain-variant'
        case 5:
          return 'best:account-message'
        case 6:
          return 'best:account-message'
        case 7:
          return 'best:user-list'
        default:
          return null
      }
    }
    return {
      ...useFilter(filters),
      filters,
      getColorByStatus,
      getSourceIconName,
    }
  },
  data: () => {
    return {
      applicationListHeaders,
      filter: false,
      copied: { value: false, timeout: null },
      applicationList: [],
      CustomDate,
    }
  },
  methods: {
    tableDateSort,
    urlGetApplicationList,
    getResponsibleShortName,
    copyText,
    getApplications(page = 1) {
      return handleAsync('getApplications', () => {
        return this.$axios
          .get(this.urlGetApplicationList(page, this.filtersQueryObj))
          .then(res => {
            this.applicationList.splice(0)
            this.applicationList.push(
              ...this.modifyContacts(res.data.data || [])
            )

            return res
          })
          .catch(this.$err)
      })
    },
    navigateToApplication(applicationId) {
      this.$router.push({
        name: 'edit-application',
        params: { applicationId },
      })
    },
    modifyContacts(applications) {
      return applications.map(application => {
        const phone = application.phones?.length
          ? application.phones[0].phone
          : '---'
        const email = application.emails?.length
          ? application.emails[0].email
          : '---'

        return { ...application, phone, email }
      })
    },
  },
}
</script>
<style lang="scss" scoped></style>
