<template>
  <div>
    <LeasingObjectCard
      :calculation="calculation"
      simple
      :show-fields="['specificationDocuments']" />
    <div v-if="calculation.agent" style="background: #fff; padding: 13px">
      <span class="card-label d-block" style="margin-bottom: -25px !important"
        >Агент</span
      >
      <div>
        <v-row>
          <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
          <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
            <ALink
              v-if="calculation.agent.contact"
              :text="calculation.agent.contact.fullName"
              :route="{
                name: 'contact-common-info',
                params: { id: calculation.agent.contact.id },
              }">
            </ALink>
            <span class="card-value"
              >|
              {{
                calculation.agent?.dealerPointEmployees?.fullPostName ||
                'Посада вiдсутня'
              }}</span
            >
          </v-col>
          <v-col :cols="12" :md="3" class="pt-0 pb-0"></v-col>
          <v-col :cols="12" :md="9" class="pt-0 pb-0">
            <div class="d-flex align-center">
              <div class="card-sublable mr-1 align-center card-label">
                <PhoneIcon :width="16" class="mr-1" />
                Телефон:
              </div>
              <span class="card-label">
                {{
                  contactDetailsWithType(
                    calculation.agent.contact.phones,
                    'phone'
                  )
                }}
              </span>
            </div>
          </v-col>
          <v-col :cols="12" :md="3" class="pt-0 pb-0 pl-0"></v-col>
          <v-col :cols="12" :md="9" class="pt-0 pb-0">
            <div class="d-flex align-center">
              <div class="card-sublable mr-1 align-center card-label">
                <EmailIcon :width="16" class="mr-1" />
                Email:
              </div>
              <span class="card-label">
                {{
                  contactDetailsWithType(
                    calculation.agent.contact.emails,
                    'email'
                  )
                }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :sm="3" class="pt-1 pb-1"> </v-col>
          <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
            <span class="card-label mr-1">Торгова точка:</span>
            <span class="card-value">{{
              calculation?.agent?.dealerPointEmployees?.dealerPoint?.name ||
              '---'
            }}</span>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else style="background: #fff; padding: 13px">
      <span class="card-label d-flex justify-start">Агент відсутній</span>
    </div>
  </div>
</template>

<script>
import { contactDetailsWithType } from '@/utils/helperFunc'
import LeasingObjectCard from '@/components/LeasingObjectCard.vue'
import ALink from '@/components/Link.vue'
import EmailIcon from '@/assets/svg/social/email-icon.vue'
import PhoneIcon from '@/assets/svg/phone-icon.vue'
export default {
  components: {
    PhoneIcon,
    EmailIcon,
    ALink,
    LeasingObjectCard,
  },
  props: {
    calculation: { type: Object },
  },
  methods: {
    contactDetailsWithType,
  },
}
</script>

<style scoped></style>
