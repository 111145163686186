<template>
  <div class="crm-layout">
    <div ref="crmBody" class="crm">
      <div
        class="expand-nested-panel"
        :style="{ width: expandSubItems ? '250px' : '0' }"
        @mouseleave="clearNestedItems($event)"
        @transitionend.stop="showNestedItems()">
        <v-fade-transition hide-on-leave>
          <div
            v-if="routeItem && nestedVisible && routeItem.nested"
            class="expanded-nested-menu">
            <div class="expanded-nested-menu-title pb-4 pt-4">
              {{ routeItem.title }}
            </div>
            <v-divider></v-divider>
            <v-list dark style="background: transparent">
              <v-list-item
                v-for="(item, key) in routeItem.nested"
                :key="key"
                style="height: 42px"
                link
                nav
                @click="$router.push(item.to)">
                <v-list-item-title class="d-flex align-center">
                  <v-icon class="mr-2" color="white">{{ item.icon }}</v-icon>
                  <div style="color: white">{{ item.title }}</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-fade-transition>
      </div>
      <div
        v-show="!frame"
        class="crm-drawer"
        :class="{ expanded: expandDrawer && !frame }">
        <div class="drawer-btn-wrapper">
          <v-btn
            class="drawer-toggle-btn"
            icon
            variant="text"
            @click="toggleDrawer">
            <v-icon
              class="drawer-icon"
              :class="{ close: !expandDrawer }"
              color="white">
              {{ 'mdi-menu' }}
            </v-icon>
          </v-btn>
        </div>
        <CrmDrawer
          :menu="menu"
          :expandDrawer="expandDrawer"
          :showMobileMenu="showMobileMenu"
          :deskMenuCollapsed="deskMenuCollapsed"
          @open-drawer="setDrawer($event)"
          @change-desk-menu="deskMenuCollapsed = $event"
          @change-mobile-menu="showMobileMenu = $event">
        </CrmDrawer>
      </div>
      <div
        class="d-flex flex-column crm-main-col"
        :class="{
          expanded: !expandDrawer,
          frame: frame,
        }">
        <div v-if="showNewYearTopBanner">
          <v-toolbar
            class="new-year-top-banner cursor-default"
            prominent
            :height="46">
            <v-toolbar-title style="min-width: 96.5%">
              <div class="d-flex align-center justify-center">
                <span> Час дива та яскравих емоцій вже близько! </span>
              </div>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="removeBanner">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <div v-if="!frame" class="app-bar" data-readonly-wrapper="false">
          <div :style="{ width: $route.name === 'main' ? 'auto' : '100%' }">
            <BreadScrumb class="bread-scrumb"></BreadScrumb>
          </div>
          <div
            class="d-flex align-center"
            style="justify-content: flex-end"
            :style="{ width: $route.name === 'main' ? '100%' : '50%' }">
            <v-row v-if="$route.name === 'main'">
              <v-col cols="12" md="6" sm="12" class="pl-5">
                <v-scroll-x-transition>
                  <v-autocomplete
                    v-model="globalSearchValue"
                    class="global-search-autocomplete"
                    :items="globalSearch"
                    no-filter
                    item-title="name"
                    item-value="route"
                    dense
                    hide-details
                    rounded
                    placeholder="Пошук.."
                    @update:model-value="navigateToItem($event)"
                    @input="handleInputChange">
                    <template #no-data>
                      <Loader v-if="globalSearchTimeout" />
                      <v-card-text v-else>
                        По вашому запиту нічого не знайдено
                      </v-card-text>
                    </template>
                  </v-autocomplete>
                </v-scroll-x-transition>
              </v-col>
            </v-row>
            <v-scroll-x-transition
              v-if="!$vuetify.breakpoint?.xs"
              hide-on-leave>
              <div
                v-if="exchangeRates.length"
                class="d-flex exchange-rates"
                :style="{ display: $route.name === 'main' ? 'flex' : 'auto' }">
                <v-tooltip
                  v-for="(item, key) in exchangeRates"
                  :key="key"
                  bottom
                  :text="Number(item.rate).toFixed(2)"
                  color="grey darken-4">
                  <template #activator="{ props }">
                    <v-icon class="ml-2 mr-5" size="20" v-bind="props">
                      {{ getCurrencyIcon(item.currency.name) }}
                    </v-icon>
                  </template>
                  <span>{{ Number(item.rate).toFixed(2) }}</span>
                </v-tooltip>
              </div>
            </v-scroll-x-transition>
            <v-fade-transition hide-on-leave>
              <v-btn
                v-show="currencyLoad"
                class="mt-1"
                icon
                :loading="currencyLoad">
                <v-icon></v-icon>
              </v-btn>
            </v-fade-transition>

            <Notifications />
            <CallsNotifications class="ml-3" />

            <v-menu
              offset-y
              :close-on-content-click="false"
              :nudge-bottom="8"
              location="bottom">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  class="ml-10 mr-5"
                  icon
                  density="comfortable"
                  :loading="!!!user.id"
                  :disabled="!!!user.id">
                  <temp-image
                    v-if="user.photo"
                    :height="35"
                    :width="35"
                    cover
                    style="border-radius: 50%"
                    :src="user.photo" />
                  <v-icon v-else color="#000000a1" :size="31">
                    {{ 'mdi-account' }}
                  </v-icon>
                </v-btn>
              </template>

              <v-card class="profile-modal">
                <div class="pb-3">
                  <div>
                    {{ getContactInitials(user) }}
                  </div>
                  <div>{{ user.email }}</div>
                </div>

                <div class="d-flex flex-column mb-2">
                  <v-btn
                    class="text-white w-100 mb-2 justify-start"
                    small
                    style="background: #fc7247"
                    @click="
                      () => {
                        !profile && $router.push({ name: 'profile' })
                        $refs.crmBody.click()
                      }
                    ">
                    <div class="d-flex align-center">
                      <v-icon
                        style="font-size: 20px"
                        color="#fff"
                        class="mr-1"
                        >{{ 'mdi-cog-outline' }}</v-icon
                      >
                      <span>Налаштування</span>
                    </div>
                  </v-btn>
                  <v-btn
                    class="text-white w-100 justify-start"
                    small
                    style="background: #093a61"
                    :loading="logoutLoad"
                    @click="logOut()">
                    <div class="d-flex align-center">
                      <v-icon
                        style="font-size: 20px"
                        color="#fff"
                        class="mr-1"
                        >{{ 'mdi-logout' }}</v-icon
                      >
                      <span>Вийти з аккаунта</span>
                    </div>
                  </v-btn>
                </div>
              </v-card>
            </v-menu>
          </div>
        </div>
        <div class="crm-content">
          <v-card
            id="crmCard"
            :elevation="0"
            class="crm-main-card"
            :style="{ marginLeft: isRouteList ? '-12px' : null }">
            <v-fade-transition hide-on-leave>
              <RouterView :key="$store.state.common.componentKey"></RouterView>
            </v-fade-transition>
          </v-card>
        </div>
      </div>
    </div>
    <MainDialog> </MainDialog>
    <canvas id="canvas" style="display: none"></canvas>
  </div>
</template>
<script>
import Loader from '@/components/Loader.vue'
import BreadScrumb from '@/components/bread-scrumb.vue'
import CrmDrawer from '@/components/front-bonus/crm-drawer.vue'
import MainDialog from '@/dialogs/dialog.vue'
import routes from '@/layout/crm-routes'
import initPage from '@/utils/initLoader'
import Notifications from '@/components/notifications/Index.vue'
import CallsNotifications from '@/components/callsNotifications/Index.vue'
import { urlGlobalSearch } from '@/pages/request'
import { mdiLogout } from '@mdi/js'
import { mapState } from 'vuex'
import { getContactInitials } from '@/utils/helperFunc'
import { fillDirectory, getDirectory } from '@/plugins/directory/index.ts'
import TempImage from '@/components/temp-image.vue'

export default {
  components: {
    TempImage,
    BreadScrumb,
    Notifications,
    CrmDrawer,
    MainDialog,
    CallsNotifications,
    Loader,
  },
  data: () => ({
    globalSearchTimeout: null,
    globalSearch: [],
    globalSearchString: null,
    globalSearchValue: null,
    currentMenu: null,
    currentNestedMenu: null,
    menu: routes,
    expandDrawer: true,
    expandSubItems: false,
    logoutLoad: false,
    mdiLogout,
    deskMenuCollapsed: false,
    showMobileMenu: false,
    index: null,
    routeItem: null,
    nestedVisible: false,
    exchangeRates: [],
    currencyLoad: false,
    frame: false,
    searchMap: {
      contact: {
        title: 'Контакти:',
        getName: el => el.name,
        getRoute: id => {
          return { name: 'individuals-form', params: { id: id } }
        },
      },
      contractor: {
        title: 'Контрагенти:',
        getName: el => el.name,
        getRoute: id => {
          return { name: 'contractors-form', params: { id: id } }
        },
      },
      application: {
        title: 'Заявки:',
        getName: el => el.name,
        getRoute: id => {
          return { name: 'edit-application', params: { applicationId: id } }
        },
      },
      calculation: {
        title: 'Калькуцяції:',
        getName: el => `${el.requestId}`,
        getRoute: (id, applicationId) => {
          return {
            name: 'application-edit-calculation',
            params: { calculationId: id, applicationId: applicationId },
          }
        },
      },
      lead: {
        title: 'Лiди:',
        getName: el => el.name,
        getRoute: id => {
          return { name: 'edit-lead', params: { id: id } }
        },
      },
      project: {
        title: 'Проекти:',
        getName: el => el.number,
        getRoute: id => {
          return { name: 'project', params: { projectId: id } }
        },
      },
      dealerPointOfSale: {
        title: 'Торгові точки:',
        getName: el => el.name,
        getRoute: id => {
          return { name: 'dealer-form', params: { id: id } }
        },
      },
    },
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      selectItems: state => state.selectItems,
    }),
    showNewYearTopBanner() {
      const bannerState = this.$store.state.auth.newYearTopBanner
      return bannerState === 'true' || bannerState === true
    },
    route() {
      return this.$route.name
    },
    profile() {
      return this.$route.name === 'profile'
    },
    isRouteList() {
      const routes = [
        'front-sales-plan',
        'front-activities-plan',
        'leads',
        'service-application-list',
        'security-service-verification-list',
        'risk-department',
        'security-service-legal-department',
        'financial-department',
        'leasing-committee-projects',
        'delayList',
        'scheduledList',
        'registration-application',
        'dkp-contracts',
        'dfl-contracts',
        'dol-contracts',
        'additional-agreements-dfl',
        'additional-agreements-dkp',
        'surety-agreements',
        'mortgagor-agreements',
        'services-contracts',
        'leasing-object-list',
        'average-price',
        'e-docs',
        'book-keeping-payments',
        'marketing-company',
        'archive-list',
        'plan-list',
        'admin-plan-list',
        'tiny-templates',
        'best-leasing-templates',
        'logging',
        'admin-calls-phones',
        'admin-lead-calls',
        'employees',
        'departments',
        'administration-directories',
        'administration-tickets',
        'admin-faq',
        'admin-supervisor-list',
        'user-calculations',
        'applications',
        'projects',
        'my-meetings',
        'my-reminder',
        'individuals',
        'contractors',
        'agent-list',
        'dealer-list',
        'car-for-sale-list',
      ]

      return routes.includes(this.$route.name)
    },
  },
  methods: {
    getContactInitials,
    async getGlobalSearch() {
      clearTimeout(this.globalSearchTimeout)
      this.globalSearchTimeout = setTimeout(async () => {
        const { data } = await this.$axios.get(
          urlGlobalSearch(this.globalSearchString)
        )
        const searchItems = []
        Object.keys(data).forEach(key => {
          data[key].length &&
            searchItems.push(
              ...[
                { name: this.searchMap[key].title, id: key },
                ...data[key].map(el => {
                  return {
                    name: this.searchMap[key].getName(el),
                    id: el.id,
                    applicationId: el.applicationId,
                  }
                }),
              ].map(s => ({
                ...s,
                route: this.searchMap[key].getRoute(s.id, s.applicationId),
              }))
            )
        })
        this.globalSearch = searchItems.map(item => ({
          ...item,
          props: {
            disabled: Object.keys(this.searchMap).includes(item.id),
          },
        }))
      }, 400)
    },
    handleInputChange(value) {
      this.globalSearchString = value?.target?.value || ''
    },
    navigateToItem(route) {
      if (route) {
        this.$router.push(route)
        this.clearMenuItems()
      }
      this.clearMenuItems()
    },
    clearMenuItems() {
      this.globalSearchValue = null
      this.globalSearch = []
      this.globalSearchTimeout = null
    },
    clearGlobalSearch() {
      this.globalSearch.splice(0)
    },
    checkFrame() {
      if (this.$route.query.frame) {
        this.frame = true
      }
    },
    setDrawer(route = {}) {
      if (route.nested && !this.expandDrawer) {
        this.expandSubItems = true
        this.routeItem = route
      } else {
        this.expandSubItems = false
        this.routeItem = null
        this.nestedVisible = false
      }
    },
    showNestedItems() {
      this.nestedVisible = true
    },
    clearNestedItems(e) {
      const target = e?.relatedTarget || e?.toElement
      if (
        target &&
        (target.classList.contains('nav-bar') ||
          target.classList.contains('mobile-nest') ||
          target.classList.contains('mobile-nest__nav-btn') ||
          target.classList.contains('v-btn__content') ||
          target instanceof SVGElement)
      )
        return

      this.expandSubItems = false
      this.routeItem = null
      this.nestedVisible = false
    },
    initPage,
    getCurrencyIcon(currency) {
      switch (currency) {
        case 'EUR':
          return 'mdi-currency-eur'
        case 'USD':
          return 'mdi-currency-usd'
      }
    },
    async toggleDrawer() {
      await this.$store.commit('rerenderMenu')
      this.expandDrawer = !this.expandDrawer
      this.$store.commit('setDrwState', this.expandDrawer)
    },
    logOut() {
      this.logoutLoad = true
      return this.$store.dispatch('logout').then(() => {
        this.logoutLoad = false
        return location.replace(this.$baseURL)
      })
    },
    getDrawerState() {
      const state = localStorage.getItem('drawerState')
      if (state === 'false') this.expandDrawer = false
    },
    getNbuCurrencies() {
      this.currencyLoad = true
      fillDirectory('nbuCurrencyCourses')
        .then(() => {
          this.exchangeRates = getDirectory('nbuCurrencyCourses')
            .filter(v => v.currency.name === 'USD' || v.currency.name === 'EUR')
            .reverse()
          this.currencyLoad = false
        })
        .catch(err => this.$err(err, () => (this.currencyLoad = false)))
    },
    removeBanner() {
      this.$store.commit('setNewYearTopBanner', false)
      localStorage.setItem('newYearTopBanner', 'false')
    },
  },
  watch: {
    globalSearchString(val) {
      val ? this.getGlobalSearch() : this.clearGlobalSearch()
    },
    route() {
      this.$store.commit('setBreadScrumbPart', [])
    },
    expandDrawer(state) {
      localStorage.setItem('drawerState', state)
      this.setDrawer()
    },
  },
  mounted() {
    this.checkFrame()
    this.initPage('.crm')
    this.getDrawerState()
    this.getNbuCurrencies()
    fillDirectory('currencies')
  },
}
</script>
<style lang="scss">
.profile-modal {
  background: white;
  padding: 10px;
  font-size: 13px;
}
.drawer-icon {
  text-align: center;
  transition: transform 0.1s ease !important;
  &.close {
    transform: rotate(90deg) scale(1.2) translate(3px, 0px);
  }
}
.expand-nested-panel {
  top: 0;
  z-index: 2;
  bottom: 0;
  left: 55px;
  background: steelblue;
  border-radius: 0 1rem 0 0;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%),
    0px 1px 14px 0px rgb(0 0 0 / 12%);
  overflow: hidden;
  transition: all 0.2s ease;
  display: inline-block;
  position: absolute;
}
.expanded-nested-menu {
  background: linear-gradient(34deg, transparent, rgba(0, 0, 0, 0.7));
  min-height: 300px;
  min-height: 100%;
}
.expanded-nested-menu-title {
  padding-left: 58px;
  color: lightcyan;
}
.exchange-rates {
  font-size: 0.9rem;
  color: grey;
}
.new-year-top-banner {
  font-family: WinterDairy, serif !important;
  background-image: url('@/assets/png/banner.png');
  color: white;
}
</style>
