<template>
  <div class="pt-0 pb-0 pr-3">
    <AnalyzeService
      :securityResult="securityResult"
      :legalResult="legalResult"
      :analyzeData="klpAnalyze"
      hideKlp
      @download-printed-form="service => downloadPrintedForm(service)" />

    <!--  documents    -->
    <div style="background: #fff; margin-top: 10px">
      <FilesUploader
        label="Параметри угоди"
        readonly
        :documents="klpAnalyze.project.riskResult.agreementParameters" />
      <FilesUploader
        label="Рейтинг НБУ"
        readonly
        :documents="klpAnalyze.project.riskResult.nbuRanking" />
      <FilesUploader
        label="Аналіз клієнта"
        readonly
        :documents="klpAnalyze.project.riskResult.templateClientAnalysis" />
    </div>
  </div>
</template>

<script>
import {
  getContactInitials,
  getSafe,
  toFormatDate,
  download,
} from '@/utils/helperFunc'
import FilesUploader from '@/components/FilesUploader.vue'
import {
  urlSecurityServicePrintedForm as securityPrintedForm,
  urlCreateLegalDepartmentPrintedForm as legalPrintedForm,
} from '@/pages/request'
import AnalyzeService from '@/components/AnalyzeService.vue'
export default {
  components: { AnalyzeService, FilesUploader },
  props: {
    klpAnalyze: { type: Object },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    legalResult() {
      const result = []
      result.push({
        name: this.klpAnalyze.project.legalResult?.client?.name + ' (Клієнт)',
        finishDate: this.klpAnalyze.project.legalResult?.client?.finishDate,
        responsible: this.klpAnalyze.project.legalResult?.responsible,
        conclusion:
          this.klpAnalyze.project.legalResult?.client?.clientConclusion,
        status: this.klpAnalyze.project.legalResult?.status,
      })
      if (this.klpAnalyze.project.legalResult.providers) {
        this.klpAnalyze.project.legalResult.providers.forEach(p =>
          result.push({
            name: p?.name + ' (Постачальник)',
            finishDate: p?.finishDate,
            responsible: this.klpAnalyze.project.legalResult?.responsible,
            conclusion: p?.providerConclusion,
            status: this.klpAnalyze.project.legalResult?.status,
          })
        )
      }
      if (this.klpAnalyze.project.legalResult.guarantors) {
        this.klpAnalyze.project.legalResult.guarantors.forEach(p =>
          result.push({
            name: p?.name + ' (Поручитель)',
            finishDate: p?.finishDate,
            responsible: this.klpAnalyze.project.legalResult?.responsible,
            conclusion: p?.guarantorConclusion,
            status: this.klpAnalyze.project.legalResult?.status,
          })
        )
      }
      return result
    },
    securityResult() {
      const result = []
      result.push({
        name:
          this.klpAnalyze.project.securityResult?.client?.name + ' (Клієнт)',
        finishDate: this.klpAnalyze.project.securityResult?.client?.finishDate,
        result:
          this.klpAnalyze.project.securityResult?.client.result?.name || '---',
        responsible: this.klpAnalyze.project.securityResult?.responsible,
        status: this.klpAnalyze.project.securityResult?.status,
      })
      if (this.klpAnalyze.project.securityResult.providers) {
        this.klpAnalyze.project.securityResult.providers.forEach(p =>
          result.push({
            name: p?.name + ' (Постачальник)',
            finishDate: p?.finishDate,
            result: p.result?.name || '---',
            responsible: this.klpAnalyze.project.securityResult?.responsible,
            status: this.klpAnalyze.project.securityResult?.status,
          })
        )
      }
      if (this.klpAnalyze.project.securityResult.guarantors) {
        this.klpAnalyze.project.securityResult.guarantors.forEach(p =>
          result.push({
            name: p?.name + ' (Поручитель)',
            finishDate: p?.finishDate,
            result: p?.result?.name || '---',
            responsible: this.klpAnalyze.project.securityResult?.responsible,
            status: this.klpAnalyze.project.securityResult?.status,
          })
        )
      }
      return result
    },
  },
  methods: {
    getSafe,
    getContactInitials,
    toFormatDate,
    download,
    securityPrintedForm, // used in urlPrintedForm
    legalPrintedForm, // used in urlPrintedForm
    async downloadPrintedForm(service) {
      const serviceId =
        service === 'security'
          ? this.klpAnalyze.project.securityResult.id
          : this.klpAnalyze.project.legalResult.id
      const urlPrintedForm = this[`${service}PrintedForm`]
      console.log(`${service}PrintedForm`)
      const { data } = await this.$axios.get(urlPrintedForm(serviceId))
      const url = data.url
      if (!url) return

      const fileName = url.split('/').pop()
      this.download(url, fileName)
    },
  },
}
</script>

<style lang="scss" scoped>
.department-object {
  &__cell {
    position: relative;
  }
  &__dialog {
    min-width: 600px;
    position: absolute;
    cursor: default;
    right: 0;
    top: -35px;
    z-index: 10000;
  }
}
.depbackdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  cursor: default;
  top: 0;
  left: 0;
  z-index: 9999;
}
</style>
