<template>
  <div>
    <router-view
      v-if="calculationRoute"
      :application-data="application"></router-view>
    <div v-else>
      <div v-if="!$loading.isLoading('getApplication')">
        <v-row>
          <v-col :cols="12" :md="3">
            <application-sidebar></application-sidebar>
          </v-col>
          <v-col :cols="12" :md="9">
            <application-header v-if="!createApplication"></application-header>
            <v-tabs
              class="mb-3 application-tabs"
              slider-color="#FC7247"
              style="padding-left: 10px"
              show-arrows
              :height="38">
              <v-tab
                v-if="createApplication"
                replace
                :to="{
                  name: 'create-application-common-info',
                }">
                Основна інформація
              </v-tab>
              <v-tab
                v-if="!createApplication"
                replace
                :to="{
                  name: 'application-common-info',
                  params: { applicationId: $route.params.applicationId },
                }">
                Основна інформація
              </v-tab>
              <v-tab
                v-if="!createApplication"
                replace
                :to="{
                  name: 'application-calculations',
                  params: { applicationId: $route.params.applicationId },
                }">
                Розрахунки
              </v-tab>
              <v-tab
                v-if="!createApplication"
                replace
                :to="{
                  name: 'application-comunication',
                  params: { applicationId: $route.params.applicationId },
                }">
                Активність
              </v-tab>
            </v-tabs>

            <div style="min-height: 320px">
              <router-view></router-view>
              <div
                v-if="!createApplication"
                v-show="
                  $route.name === 'application-calculations' &&
                  calculationList.length
                ">
                <pagination
                  :keep-query="false"
                  :get-items="getCalculationList"
                  :trigger="calcPaginationTrigger"></pagination>
              </div>
            </div>
            <action-buttons
              :confirm="updateApplication"
              :cancel="() => application.$reset()"
              :confirm-loading="$loading.isLoading('updateApplication')"
              :cancel-disable="!application.$hasChanges && !createApplication"
              :confirm-disable="
                !application.$hasChanges && !createApplication
              "></action-buttons>
          </v-col>
        </v-row>
      </div>
      <b-section-loader v-else></b-section-loader>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, provide, ref, watch } from 'vue'
import { cachedObject, keepArray } from 'best-modules/utils'
import { Application } from '@/utils/types/application'
import {
  applicationKey,
  createApplicationKey,
  calculationListKey,
  updateCalculationListKey,
  updateApplicationObjectKey,
  hasApprovedCalculationKey,
  v$Key,
} from './injectionKeys'
import { useRoute, useRouter } from 'vue-router'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import axios from '@/plugins/axios.js'
import {
  urlApplicationById,
  urlCalculationsByApplication,
  urlUpdateApplication,
  urlCreateApplication,
  urlApplicationCreatePhone,
  urlApplicationCreateEmail,
} from '@/pages/request.js'
import ApplicationHeader from './ApplicationHeader.vue'
import ApplicationSidebar from './sidebar/Index.vue'
import Pagination from '@/components/Pagination.vue'
import ActionButtons from '@/components/action-buttons.vue'
import { BSectionLoader } from 'best-modules/components'
import { fillDirectory } from '@/plugins/directory'
import { useStore } from 'vuex'
import { required, requiredIf } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { v$Notify } from '@/utils/helperFunc'

export default {
  name: 'ApplicationSingle',
  components: {
    ApplicationHeader,
    ApplicationSidebar,
    Pagination,
    ActionButtons,
    BSectionLoader: BSectionLoader,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const user = computed(() => store.state.user)
    const applicationId = computed(() => route.params.applicationId)

    const createApplication = computed<boolean>(() =>
      ['new-application', 'create-application-common-info'].includes(
        route.name as string
      )
    )
    const calculationRoute = computed<boolean>(() => {
      return [
        'application-create-calculation',
        'application-new-payment-plan',
        'application-slider',
        'slider-client',
        'slider-guarantors',
        'slider-subtenants',
        'slider-leasing-objects',
        'slider-scoring',
        'slider-main-info',
        'application-edit-calculation',
        'application-payment-plan',
        'edit-calculator-leasing-object',
        'edit-calculator-leasing-terms',
        'edit-calculator-additional-terms',
        'edit-calculator-service',
        'create-calculator-leasing-object',
        'create-calculator-leasing-terms',
        'create-calculator-additional-terms',
        'create-calculator-service',
      ].includes(route.name as string)
    })

    const createContacts = async (
      applicationId: number,
      contacts: any[],
      type: 'email' | 'phone'
    ): Promise<void> => {
      const url =
        type === 'email'
          ? urlApplicationCreateEmail()
          : urlApplicationCreatePhone()
      for (const contact of keepArray(contacts)) {
        const requestObj = { applicationId }
        requestObj[type] = contact[type]
        await axios.post(url, requestObj)
      }
    }
    const application = cachedObject({
      responsibleId: user.value.id,
      responsible: user.value,
      lesseeTypeId: 2,
      phones: [],
      emails: [],
      isPatronymic: true,
    } as Application)
    application.$setIgnore('phones', 'emails', 'slider', 'status', 'statusId')

    const sourceAgent = computed(() => application.sourceTypeId === 1)
    const sourceDealer = computed(() => application.sourceTypeId === 9)
    const sourceOnline = computed(() => application.sourceTypeId === 2)
    const sourceRetreatment = computed(() => application.sourceTypeId === 3)
    const isContact = computed(() => [1, 3].includes(application.lesseeTypeId))
    const applicationContacts = computed(() => {
      return ![1, 3].includes(application.sourceTypeId)
    })
    const rules = {
      sourceTypeId: { required: required },
      applicationName: { required: required },
      clientNeedId: { required: required },
      agentId: {
        required: requiredIf(sourceAgent),
      },
      dealerPointId: {
        required: requiredIf(sourceDealer),
      },
      sourceId: {
        required: requiredIf(sourceOnline),
      },
      lesseeId: {
        required: requiredIf(sourceRetreatment),
      },
      lessee: {
        phones: {
          required: requiredIf(() => {
            return (
              (!application.phones || !application.phones.length) && // телефони пусті
              (!application.emails || !application.emails.length) && // емайли пусті
              sourceRetreatment.value &&
              isContact.value
            )
          }),
        },
        emails: {
          required: requiredIf(() => {
            return (
              (!application.phones || !application.phones.length) && // телефони пусті
              (!application.emails || !application.emails.length) && // емайли пусті
              sourceRetreatment.value &&
              isContact.value
            )
          }),
        },
      },
      name: {
        required: requiredIf(() => !sourceRetreatment.value),
      },
      phones: {
        required: requiredIf(() => {
          return (
            applicationContacts.value &&
            (!application.phones || !application.phones.length) && // телефони пусті
            (!application.emails || !application.emails.length) // емайли пусті
          )
        }),
      },
      emails: {
        required: requiredIf(() => {
          return (
            applicationContacts.value &&
            (!application.phones || !application.phones.length) && // телефони пусті
            (!application.emails || !application.emails.length) // емайли пусті
          )
        }),
      },
    }
    const v$ = useVuelidate(rules, application)
    const updateApplicationObject = (obj: Application): void => {
      Object.assign(application, obj)
    }
    const getApplication = () => {
      return handleAsync('getApplication', () => {
        return axios.get(urlApplicationById(applicationId.value)).then(res => {
          application.$set(res.data)
        })
      })
    }
    const updateApplication = () => {
      return handleAsync('updateApplication', async () => {
        v$.value.$validate()
        if (v$.value.$invalid) {
          const v = { ...v$.value }
          if (sourceRetreatment.value && v$.value.lessee) {
            v.emails = v$.value.lessee.emails
            v.phones = v$.value.lessee.phones
          }
          return v$Notify(v, 'application', { deep: true })
        } else {
          const url = createApplication.value
            ? urlCreateApplication()
            : urlUpdateApplication(applicationId.value)

          return axios.post(url, application.$object).then(async res => {
            if (res.data.message) {
              return setSnackbar({
                text: res.data.message,
                color: 'warning',
              })
            }

            const successMessage: string = createApplication.value
              ? 'Заявку успішно оновлено'
              : 'Заявку успішно створено'
            setSnackbar({ text: successMessage })

            if (createApplication.value) {
              await createContacts(
                res.data.id,
                application.phones,
                'phone'
              ).catch(e => console.log('e', e))
              await createContacts(
                res.data.id,
                application.emails,
                'email'
              ).catch(e => console.log('e', e))

              return router.push({
                name: 'edit-application',
                params: { applicationId: res.data.id },
              })
            }

            application.$set(res.data)
          })
        }
      })
    }

    const calculationList = ref<any[]>([])
    const hasApprovedCalculation = computed<boolean>(() => {
      return calculationList.value.some(c => c.isAgreed)
    })
    const getCalculationList = (page = 1) => {
      return handleAsync('getCalculationList', () => {
        return axios
          .get(urlCalculationsByApplication(applicationId.value, { page }))
          .then(res => {
            calculationList.value.splice(0)
            const sortedCalculations = (res.data.data || []).sort(
              // @ts-expect-error
              (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
            )
            calculationList.value.push(...sortedCalculations)

            return res
          })
      })
    }
    const calcPaginationTrigger = ref<number>(1)
    const updateCalculationList = () => {
      calcPaginationTrigger.value += 1
    }

    provide(createApplicationKey, createApplication)
    provide(applicationKey, application)
    provide(calculationListKey, calculationList)
    provide(updateCalculationListKey, updateCalculationList)
    provide(updateApplicationObjectKey, updateApplicationObject)
    provide(hasApprovedCalculationKey, hasApprovedCalculation)
    // @ts-expect-error TODO
    provide(v$Key, v$)

    const handleLesseeType = () => {
      application.edrpou = null
      application.companyName = null
      application.inn = null
      application.surname = null
      application.name = null
      application.patronymic = null
    }
    const handleSourceType = (newSourceTypeId, oldSourceTypeId) => {
      if (newSourceTypeId === 3) {
        application.lesseeTypeId = null
      }
      if (newSourceTypeId !== 3) {
        application.lesseeId = null
        application.lessee = null

        if (oldSourceTypeId === 3) {
          application.lesseeTypeId = 2
        }
      }
      if (newSourceTypeId !== 1) {
        application.agentId = null
      }
      if (newSourceTypeId !== 9) {
        application.dealerPointId = null
      }
    }

    const watchApplication = () => {
      watch(() => application.lesseeTypeId, handleLesseeType)
      watch(() => application.sourceTypeId, handleSourceType)
    }
    if (createApplication.value) {
      fillDirectory(['clientNeeds', 'applicationSourceTypes'])
      watchApplication()
    } else {
      getApplication().then(watchApplication)
      fillDirectory('applicationStatuses')
    }
    fillDirectory('lesseeTypes')

    return {
      application,
      createApplication,
      getCalculationList,
      calculationList,
      calcPaginationTrigger,
      calculationRoute,
      updateApplication,
    }
  },
}
</script>

<style scoped>
.v-tab {
  padding: 0 !important;
  margin-right: 25px;
}
.v-tabs.application-tabs .v-tab,
.v-tabs.application-tabs .v-tab-item--selected {
  background: transparent !important;
}
.v-tab-item--selected.v-tab {
  color: #08487a !important;
  font-weight: 700 !important;
  font-size: 14px;
}
.v-tab {
  color: #838282 !important;
}
</style>
