<template>
  <v-card-text>
    <!--    todo-->
    <!--    <v-row>-->
    <!--      <v-col cols="12" md="6" sm="12">-->
    <!--        <ImageUploader :images="calculation.leasingObjectPhotos" />-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <v-row class="align-center mr-3">
      <v-col cols="12" md="9" sm="12" class="pb-0">
        <div
          class="text-field align-start"
          :class="{
            'flex-column align-start d-flex': $vuetify.breakpoint?.xs,
          }">
          <span class="text-title" :class="{ 'mb-1': $vuetify.breakpoint?.xs }"
            >Об'єкт лізингу:</span
          >
          <v-tooltip bottom :text="LO.description({ detailed: true })">
            <template #activator="{ props }">
              <span
                style="
                  display: block;
                  width: 80%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
                class="text-desc"
                v-bind="props"
                >{{ LO.description({ detailed: true }) }}</span
              >
            </template>
            <span>{{ LO.description({ detailed: true }) }}</span>
          </v-tooltip>
        </div>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <span class="area-label">Країна походження</span>
        <v-autocomplete
          v-model="calculationData.leasingObjectCountryId"
          class="mt-0"
          :items="
            $directory.get(
              'countryList',
              calculationData.leasing_object_country
            )
          "
          :loading="$loading.isLoading('countryList')"
          item-title="name"
          item-value="id"
          placeholder="Оберіть зі списку"
          dense
          hide-details
          :disabled="!!project.readOnly"
          :error-messages="leasingObjectCountryIdErr"
          @blur="
            v.project.calculations[calcIdx].leasingObjectCountryId.$touch()
          "
          @focus="$directory.fill('countryList')">
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col :cols="12" class="pt-0">
        <v-row class="mt-0">
          <v-col :cols="12" :lg="4" :md="3" :sm="12" class="pt-0">
            <div class="text-field">
              <span class="text-title">Програма:</span>
              <span class="text-desc">{{
                getSafe(() => calculationData.leasingProgram).name
              }}</span>
            </div>
          </v-col>
          <v-col :cols="12" :lg="4" :md="3" :sm="12" class="pt-0">
            <div class="text-field">
              <span class="text-title">Стан:</span>
              <span class="text-desc">{{
                LO.state === 'Б.в.' ? 'Вживаний' : LO.state
              }}</span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="mb-5 d-flex align-items-center">
      <FinanceIcon />
      <span class="card-block-title">Фінансування</span>
    </div>
    <div
      class="data-card mb-3"
      style="flex-direction: row; align-items: center">
      <span style="margin: 0; font-weight: 400">Сума фiнансування:</span>
      <p class="ml-2" style="margin: 0; font-weight: 600">
        {{ LOAmountOfFinancing }}
      </p>
    </div>
    <v-row>
      <v-col :cols="12" :lg="2" :md="3" :sm="4">
        <div class="data-card">
          <span class="area-label">Графік погашення</span>
          <p class="title-label">{{ graphType }}</p>
        </div>
      </v-col>
      <v-col :cols="12" :lg="2" :md="3" :sm="4">
        <div class="data-card">
          <span class="area-label">Вартість технiки</span>
          <p class="title-label">{{ LO.totalAmount }}</p>
        </div>
      </v-col>
      <v-col :cols="12" :lg="2" :md="3" :sm="4">
        <div class="data-card">
          <span class="area-label">Прайсова вартість</span>
          <p class="title-label">{{ LO.amountDFL }}</p>
        </div>
      </v-col>
      <v-col :cols="12" :lg="2" :md="3" :sm="4">
        <div class="data-card">
          <span class="area-label">Аванс</span>
          <p class="title-label">{{ LO.advance }}</p>
        </div>
      </v-col>
      <v-col :cols="12" :lg="2" :md="3" :sm="4">
        <div class="data-card">
          <span class="area-label">Термін лізингу</span>
          <p class="title-label">{{ LO.leasingTerm }}</p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="6" :sm="12">
        <div class="mb-5 d-flex align-items-center">
          <InsuranceIcon />
          <span class="card-block-title">Страхування</span>
        </div>
        <v-row>
          <v-col :cols="12" :lg="4" :md="6" :sm="4" class="pt-0 pb-0">
            <div class="data-card">
              <span class="area-label">КАСКО</span>
              <p class="title-label">
                {{
                  calculation?.insuranceProgramType?.name ||
                  calculation?.requestData?.insuranceProgramType?.name
                }}
              </p>
            </div>
          </v-col>
          <v-col :cols="12" :lg="4" :md="6" :sm="4" class="pt-0 pb-0">
            <div class="data-card">
              <span class="area-label">Франшиза</span>
              <p class="title-label">
                {{
                  calculation?.insuranceFranchiseType?.name ||
                  calculation?.requestData?.insuranceFranchiseType?.name
                }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="12" :md="6" :sm="12">
        <div class="mb-5 d-flex align-items-center">
          <GpsIcon />
          <span class="card-block-title">GPS-навігація</span>
        </div>
        <v-row>
          <v-col :cols="12" :lg="4" :md="6" :sm="4" class="pt-0 pb-0">
            <div class="data-card">
              <span class="area-label">GPS маяк</span>
              <p class="title-label">{{ gpsBeacon }}</p>
            </div>
          </v-col>
          <v-col :cols="12" :lg="5" :md="6" :sm="4" class="pt-0 pb-0">
            <div class="data-card">
              <span class="area-label">GPS трекер</span>
              <p class="title-label">{{ gpsTracker }}</p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="!calculation.leaseReverse" class="mt-10">
      <div class="mb-1 d-flex align-items-center">
        <ContactIcon />
        <span class="card-block-title">Дані постачальника</span>
      </div>
      <v-row>
        <v-col :cols="12" :lg="6" :md="6" :sm="6" style="padding-bottom: 0">
          <div class="d-flex flex-column">
            <span class="area-label" style="margin-bottom: 5px"
              >Постачальник</span
            >
            <ALink
              :text="`${getEntityName(calculation.provider)} (${
                calculation?.provider?.edrpou
                  ? `ЄДРПОУ: ${calculation.provider.edrpou}`
                  : calculation?.provider?.inn
                  ? `ІНН: ${calculation.provider.inn}`
                  : 'відсутнє'
              })`"
              :route="
                getEntityRoute(
                  calculation.providerId,
                  calculation.providerTypeId
                )
              ">
            </ALink>
          </div>
        </v-col>
        <v-col :cols="12" :lg="6" :md="6" :sm="6">
          <div class="d-flex flex-column">
            <span class="area-label" style="margin-bottom: 5px"
              >Торгова точка</span
            >
            <ALink
              :text="calculation.dealer_point.name"
              :route="{
                name: 'dealer-form',
                params: { id: calculation.dealer_point.id },
              }">
            </ALink>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" :md="6" :sm="12" style="padding-top: 5px">
          <div v-if="calculationData.providerTypeId === 2">
            <span class="area-label">Контактна особа</span>
            <v-autocomplete
              v-model="calculationData.providerEmployeeId"
              class="icon-contact mt-0"
              :items="calculationData.provider.employees || []"
              :item-title="
                item => `${item?.contact?.fullName} (${item?.fullPostName})`
              "
              item-value="contactId"
              placeholder="Оберіть зі списку"
              dense>
            </v-autocomplete>
          </div>
        </v-col>
        <v-col :md="6" :sm="12">
          <v-row style="margin-top: -13px">
            <v-col :sm="6">
              <div class="mr-5">
                <div class="subtitle d-flex align-center">
                  <PhoneIcon class="mr-1" />
                  Телефони:
                </div>
                <div class="d-flex align-center mt-2">
                  <div class="d-flex flex-column">
                    <div
                      v-for="(item, idx) in calculationData.providerTypeId === 2
                        ? contractorPhones
                        : contactPhones"
                      :key="idx"
                      class="ml-2">
                      <div class="d-flex align-center">
                        <span>{{ item?.phone }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col :sm="6">
              <div>
                <div class="subtitle d-flex align-center">
                  <EmailIcon class="mr-1" />
                  Emails:
                </div>
                <div class="d-flex align-center mt-2">
                  <div class="d-flex flex-column">
                    <div
                      v-for="(item, idx) in calculationData.providerTypeId === 2
                        ? contractorEmails
                        : contactEmails"
                      :key="idx"
                      class="ml-2 d-flex flex-column">
                      <div class="d-flex align-center">
                        <span>{{ item?.email }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col :cols="12" :lg="2" :md="3" :sm="4">
        <div style="width: 130px">
          <span class="area-label">Передоплата</span>
          <v-text-field
            id="supl-prepaid"
            v-model="calculationData.prepayToSupplier"
            :error-messages="prepayToSupplierErr"
            placeholder="Число у %"
            dense
            :disabled="!!project.readOnly"
            @input="maxNum('supl-prepaid', 100)"
            @blur="v.project.calculations[calcIdx].prepayToSupplier.$touch()">
            <template #append>
              <span style="margin-top: 0 !important" class="append-text"
                >%</span
              >
            </template>
          </v-text-field>
        </div>
      </v-col>
      <v-col :lg="4" :md="6" :sm="4">
        <span class="area-label">Мiсце вiдгрузки</span>
        <v-text-field
          v-model="calculationData.placeOfShipment"
          placeholder="Введіть текст"
          :error-messages="placeOfShipmentErr"
          :disabled="!!project.readOnly"
          dense
          @blur="v.project.calculations[calcIdx].placeOfShipment.$touch()">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row style="margin-top: -20px">
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <v-radio-group
          v-model="calculationData.inStock"
          class="size-18 sm-label"
          :error-messages="inStockErr"
          row
          dense
          :disabled="!!project.readOnly"
          @blur="v.project.calculations[calcIdx].inStock.$touch()"
          @change="calculationData.productStatusText = null">
          <v-radio label="В наявностi" :value="true"> </v-radio>
          <v-radio label="В поставцi" :value="false"> </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-fade-transition v-if="!calculationData.inStock" hide-on-leave>
        <v-col :cols="12" :md="6" class="pt-1 pb-0">
          <span class="area-label">Дані про поставку</span>
          <v-text-field
            v-model="calculationData.productStatusText"
            :error-messages="productStatusTextErr"
            placeholder="Введіть текст"
            dense
            :disabled="!!project.readOnly"
            @blur="v.project.calculations[calcIdx].productStatusText.$touch()">
          </v-text-field>
        </v-col>
      </v-fade-transition>
    </v-row>

    <OpenableSection
      v-if="!calculationData.requestData.isNew"
      style="margin-top: -15px"
      leasingObjectIcon
      title="Відомості про ОЛ">
      <template #body>
        <v-row>
          <v-col :cols="12" :md="4" class="pt-1 pb-0">
            <span class="area-label">VIN код</span>
            <v-text-field
              v-model="calculationData.vinCode"
              :error-messages="vinCodeErr"
              placeholder="Введіть значення"
              dense
              :disabled="!!project.readOnly"
              @blur="v.project.calculations[calcIdx].vinCode.$touch()">
            </v-text-field>
          </v-col>
          <v-col :cols="12" :md="4" class="pt-1 pb-0">
            <span class="area-label">Пробіг</span>
            <v-text-field
              v-model.number="calculationData.mileage"
              placeholder="Введіть значення"
              dense
              :disabled="!!project.readOnly"
              @input="inputNumber($event, { rankSpacing: false })"
              @blur="
                () => {
                  if (!calculationData.mileage) {
                    calculationData.mileage = 0
                  }
                }
              ">
              <template #append>
                <span style="margin-top: 0 !important" class="append-text"
                  >км</span
                >
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </template>
    </OpenableSection>
    <OpenableSection title="Додані документи">
      <template #body>
        <FilesUploader
          v-model:documents="calculationData.specificationDocuments"
          label="Специфікація"
          :readonly="!!project.readOnly"
          :maxSize="50000000"
          required />
        <FilesUploader
          v-if="!calculationData.requestData.isNew"
          v-model:documents="calculationData.agreedMarketValuePL"
          label="Погоджена ринкова вартість"
          :readonlyl="!!project.readOnly"
          :maxSize="50000000"
          required />
        <FilesUploader
          v-if="!calculationData.requestData.isNew"
          v-model:documents="calculationData.leasingObjectPhotos"
          label="Фото обʼєкта"
          :readonly="!!project.readOnly"
          :maxSize="50000000"
          required />
      </template>
    </OpenableSection>
  </v-card-text>
</template>

<script>
import ContactIcon from '@/assets/svg/contact-icon.vue'
import EmailIcon from '@/assets/svg/email-icon.vue'
import PhoneIcon from '@/assets/svg/phone-icon.vue'
import GpsIcon from '@/assets/svg/gps-icon.vue'
import InsuranceIcon from '@/assets/svg/insurance-icon.vue'
import FinanceIcon from '@/assets/svg/finance-icon.vue'
import OpenableSection from '@/components/OpenableSection.vue'
import ALink from '@/components/Link.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState, mapGetters } from 'vuex'
import { formutSum, maxNum, inputNumber } from '@/utils/formatFunc'
import {
  setErrHandler,
  getEntityRoute,
  getEntityName,
  LODescription as getLoDescription,
  getBeautyNum,
  getSafe,
} from '@/utils/helperFunc'
import { useSelect } from '@/utils/mixins/useSelect'
import { fillDirectory, getDirectory } from '@/plugins/directory'
export default {
  components: {
    // ImageUploader,
    FilesUploader,
    ALink,
    OpenableSection,
    FinanceIcon,
    GpsIcon,
    InsuranceIcon,
    EmailIcon,
    PhoneIcon,
    ContactIcon,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    projectData: { type: Object },
    LOData: { type: Object },
    calculation: { type: Object },
    calcIdx: { type: Number },
    v: { type: Object },
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    graphType() {
      return (
        getDirectory('chartTypes', null, { type: 'collection' }).getItem(
          this.calculation.acceptedGraphName
        )?.name || '---'
      )
    },
    gpsTracker() {
      return this.calculation?.gpsTrackerType?.name || '---'
    },
    gpsBeacon() {
      const beacon = this.calculation?.gpsBeaconType?.name
      if (!beacon) return '---'
      return `${beacon} (${this.calculation?.requestData?.beaconAmount}шт)`
    },
    project() {
      return this.projectData
    },
    LO() {
      return this.LOData
    },
    calculationData() {
      return this.calculation
    },
    LOAmountOfFinancing() {
      const amount =
        this.calculation?.exportData?.export?.['offer-amount-of-financing']
      if (!amount) return
      return `${getBeautyNum(amount, { float: 2 })} грн`
    },
    contractorEmails() {
      if (this.calculationData.providerEmployeeId) {
        const employees = this.calculationData?.provider?.employees || []
        const employee = employees.find(
          emp => emp.contact?.id === this.calculationData.providerEmployeeId
        )

        return employee ? employee.contact.emails : '---'
      }
      return '---'
    },
    contactEmails() {
      return this.calculationData?.provider?.emails || []
    },
    contractorPhones() {
      if (this.calculationData.providerEmployeeId) {
        const employees = this.calculationData?.provider?.employees || []
        const employee = employees.find(
          emp => emp.contact?.id === this.calculationData.providerEmployeeId
        )

        return employee ? employee.contact.phones : '---'
      }
      return '---'
    },
    contactPhones() {
      return this.calculationData?.provider?.phones || []
    },
    prepayToSupplierErr() {
      return setErrHandler(
        this.v.project.calculations[this.calcIdx].prepayToSupplier
      )
    },
    placeOfShipmentErr() {
      return setErrHandler(
        this.v.project.calculations[this.calcIdx].placeOfShipment
      )
    },
    inStockErr() {
      return setErrHandler(this.v.project.calculations[this.calcIdx].inStock)
    },
    productStatusTextErr() {
      return setErrHandler(
        this.v.project.calculations[this.calcIdx].productStatusText
      )
    },
    vinCodeErr() {
      return setErrHandler(this.v.project.calculations[this.calcIdx].vinCode)
    },

    leasingObjectCountryIdErr() {
      return setErrHandler(
        this.v.project.calculations[this.calcIdx].leasingObjectCountryId
      )
    },
  },
  methods: {
    getSafe,
    getLoDescription,
    getEntityName,
    formutSum,
    maxNum,
    getEntityRoute,
    inputNumber,
  },
  created() {
    fillDirectory('chartTypes')
  },
}
</script>

<style scoped>
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}

.data-card {
  display: flex;
  flex-direction: column;
}
.text-field {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.text-title {
  color: #1c1d21;
  font-weight: 600;
  font-size: 15px;
  margin-right: 6px;
  display: block;
}

.title-label {
  font-weight: 600;
  font-size: 15px;
  color: #1c1d21;
  margin-top: 5px;
}
.text-desc {
  font-weight: 400;
  font-size: 16px;
  color: #1c1d21;
  display: block;
}

.main-card-info {
  padding: 10px;
  border-radius: 4px;
  background: #f7f7f7;
  box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  display: flex !important;
  align-items: center !important;
}
</style>
