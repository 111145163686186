<template>
  <div>
    <span class="label">
      {{ type === 'contact' ? 'Контакт' : 'Контрагент' }}
    </span>
    <b-server-autocomplete
      v-model="entity"
      :url="url"
      :error-messages="getErrorMessages(v$.entity)"
      :search-min-length="3"
      :default-item="entity"
      item-value="id"
      :item-title="type === 'contact' ? 'fullName' : 'shortName'"
      return-object
      hide-details
      placeholder="Введіть текст (мін. 3 символи)"
      @blur="v$.entity.$touch()">
    </b-server-autocomplete>
  </div>
</template>

<script lang="ts">
import { BServerAutocomplete } from 'best-modules/components'
import { urlGetAllContractors, urlContactsGetAll } from '@/pages/request'
import { ref } from 'vue'
import { getErrorMessages } from 'best-modules/utils'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { cloneDeep } from 'lodash'

export default {
  name: 'SelectEntity',
  components: { BServerAutocomplete },
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const type: 'contact' | 'contractor' = props.dialog.dialogItem.type

    const url =
      type === 'contractor' ? urlGetAllContractors() : urlContactsGetAll()

    const entity = ref(props.dialog.dialogItem.entity || null)

    const rules = { entity: { required: required } }
    const v$ = useVuelidate(rules, { entity })

    const submit = () => {
      const value = cloneDeep(entity.value)
      if (type === 'contact') {
        const [surname, name, patronymic] = value.fullName.split(' ')
        value.surname = surname
        value.name = name
        value.patronymic = patronymic
      }
      return Promise.resolve(value)
    }

    return { type, url, entity, v$, submit }
  },
}
</script>

<style scoped></style>
