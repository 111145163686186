<template>
  <FieldsColumnBlock
    class="mt-2"
    title="Пункти договору"
    :readonly="readonly"
    :addAction="addEditorshipPoint">
    <template #default>
      <div>
        <v-data-table
          :headers="ExtraAgreementEditorshipHeaders"
          :items="localEditorships"
          class="application-table calc-table"
          mobile-breakpoint="1200"
          hide-default-footer
          hide-default-header
          :items-per-page="-1">
          <!-- eslint-disable-next-line -->
          <template #item.editorship="{ item }">
            <div class="d-flex align-center">
              <span class="d-block">{{ item?.editorship?.name }}</span>
              <span
                v-if="item.editorship?.id === 18"
                class="ml-1 d-block font-weight-bold"
                >({{
                  `Реквізити: ${item.currentAccount?.bankName}, ${item.currentAccount?.accountNumber}`
                }})</span
              >
              <div v-if="item.editorship?.id === 17">
                <v-btn icon size="small" density="comfortable" rounded>
                  <v-icon
                    size="18"
                    icon
                    rounded
                    small="small"
                    color="#FC7247"
                    @click="editManualEditorship(item, true)">
                    mdi-eye
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              v-if="!readonly"
              data-readonly="hide"
              :actionsArray="actions(item)">
            </TableCrud>
          </template>
        </v-data-table>
      </div>
    </template>
  </FieldsColumnBlock>
</template>

<script>
import { ExtraAgreementEditorshipHeaders } from '@/utils/tableHeaders'
import TableCrud from '@/components/table-crud.vue'
import { urlDeleteExtraAgreementEditorShip } from '@/pages/request'
import FieldsColumnBlock from '@/pages/contracts/components/FieldsColumnBlock.vue'

export default {
  components: { FieldsColumnBlock, TableCrud },
  props: {
    agreement: { type: Object },
    readonly: { type: Boolean },
  },
  data() {
    return {
      ExtraAgreementEditorshipHeaders,
      localEditorships: this.agreement.selectedEditorships || [],
    }
  },
  computed: {
    actions() {
      return item => {
        const editEditorshipPoint = [
          {
            action: () => this.editManualEditorship(item),
            text: 'Редагувати',
            icon: 'mdi-pencil',
          },
        ]
        const editCurrentAccount = [
          {
            action: () => this.editCurrentAccount(item),
            text: 'Редагувати',
            icon: 'mdi-pencil',
          },
        ]

        let actionsList = [
          {
            action: () => this.deleteEditorshipPoint(item.id),
            text: 'Видалити',
            icon: 'mdi-delete',
          },
        ]

        if (item.editorship.id === 17) {
          actionsList = [...editEditorshipPoint, ...actionsList]
        }
        if (item.editorship.id === 18) {
          actionsList = [...editCurrentAccount, ...actionsList]
        }

        return actionsList
      }
    },
  },
  methods: {
    addEditorshipPoint() {
      this.$store.commit('setDialog', {
        title: 'Пункти договору',
        dialogItem: {
          exitedPoints: this.agreement?.selectedEditorships || [],
          currentAccounts:
            this.agreement?.contract?.lessor.currentAccounts || [],
        },
        params: {
          dialogWidth: '650px',
          submitText: 'Додати',
          hideRejectButton: true,
          flexEnd: true,
        },
        action: 'createEditorShipPoint',
      })
    },
    editManualEditorship(item, isOnlyView = false) {
      this.$store.commit('setDialog', {
        title: 'Ручне введення',
        dialogItem: item || {},
        params: {
          hideActions: isOnlyView,
          dialogWidth: 1100,
          isOnlyView: isOnlyView,
          submitText: 'Змінити',
          cb: res => {
            if (res) {
              this.$store.commit('refreshPage')
            }
          },
        },
        action: 'editManualEditorship',
      })
    },
    editCurrentAccount(item) {
      this.$store.commit('setDialog', {
        title: 'Зміна реквізитів ',
        dialogItem: {
          id: item.id,
          currentAccountId: item.currentAccountId,
          editorshipId: item.editorshipId,
          currentAccounts:
            this.agreement?.contract?.lessor.currentAccounts || [],
        },
        params: {
          dialogWidth: 450,
          submitText: 'Змінити',
          cb: res => {
            if (res) {
              this.$store.commit('refreshPage')
            }
          },
        },
        action: 'editCurrentAccount',
      })
    },
    deleteEditorshipPoint(id) {
      return this.$axios
        .delete(urlDeleteExtraAgreementEditorShip(id))
        .then(res => {
          const index = this.localEditorships.findIndex(item => item.id === id)
          if (index !== -1) {
            this.localEditorships.splice(index, 1)
          }

          return res
        })
    },
  },
}
</script>

<style scoped></style>
