<template>
  <div>
    <SectionLoader v-if="!manualEditorship" />
    <v-card v-else>
      <v-textarea
        v-model="manualEditorship"
        :readonly="isOnlyView"
        hide-details
        placeholder="Введіть текст"></v-textarea>
    </v-card>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { urlUpdateExtraAgreementEditorShip } from '@/pages/request'
import SectionLoader from '@/components/section-loader.vue'

export default {
  components: { SectionLoader },
  emits: ['editorInit'],
  props: {
    dialog: { type: Object },
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  data: () => {
    return {
      manualEditorship: null,
    }
  },
  computed: {
    key() {
      return import.meta.env.VITE_API_KEY_TINYMCE
    },
    isOnlyView() {
      return this.dialog.params.isOnlyView
    },
  },
  methods: {
    handleRequest() {
      return this.$axios.post(
        urlUpdateExtraAgreementEditorShip(this.dialog.dialogItem.id),
        {
          editorshipId: this.dialog.dialogItem.editorshipId,
          manualEditorship: this.manualEditorship,
        }
      )
    },
    checkExist() {
      return this.$setSnackbar({
        text: 'Пункт договору: ручне введення',
        color: 'error',
      })
    },
    submit() {
      if (this.manualEditorship) {
        return this.handleRequest()
      } else {
        this.checkExist()
        return Promise.reject('Пункт договору: ручне введення')
      }
    },
  },
  created() {
    this.manualEditorship = this.dialog.dialogItem?.manualEditorship
  },
}
</script>

<style scoped></style>
