<template>
  <div>
    <v-card class="mb-3" style="background: #fff !important" variant="flat">
      <v-card-text>
        <sidebar-form v-if="createApplication"></sidebar-form>
        <sidebar-info v-else></sidebar-info>
      </v-card-text>
    </v-card>

    <div v-if="application.sourceTypeId === 3">
      <lessee-select
        v-if="![1, 3].includes(application.lesseeTypeId)"
        type="contractor"
        class="mb-3"></lessee-select>
      <lessee-select
        v-if="application.lesseeTypeId !== 2"
        type="contact"></lessee-select>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, inject } from 'vue'
import {
  applicationKey,
  createApplicationKey,
  updateApplicationObjectKey,
} from '../injectionKeys'
import SidebarForm from './SidebarForm.vue'
import SidebarInfo from './SidebarInfo.vue'
import LesseeSelect from './LesseeSelect.vue'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { Application } from '@/utils/types/application'

export default {
  name: 'ApplicationSidebar',
  components: { SidebarForm, SidebarInfo, LesseeSelect },
  setup() {
    const createApplication = inject(createApplicationKey)

    const updateApplicationObject = inject(updateApplicationObjectKey)
    const application = computed<CachedObjectProxy<Application>>({
      get: () => inject(applicationKey),
      set: updateApplicationObject,
    })

    return { createApplication, application }
  },
}
</script>

<style scoped></style>
