<template>
  <div>
    <div>
      <div>
        <Filters :filters="filters" class="ml-2">
          <template #header-action>
            <ExportPfForm
              class="mr-2"
              exportFileName="Список бухгалтерії"
              :url="urlGetBookKeepingPrintedForm"
              :filters-query-obj="filtersQueryObj" />
          </template>
        </Filters>
      </div>
      <div v-show="!loading" style="margin-top: 10px !important">
        <v-data-table
          :headers="paymentListHeaders"
          :items="payments"
          class="application-table calc-table"
          mobile-breakpoint="1200"
          hide-default-footer
          :hide-default-header="!payments.length"
          :items-per-page="-1"
          @dblclick:row="navigateToPayment">
          <!-- eslint-disable-next-line -->
          <template #item.status="{ item }">
            {{ item.status?.name || 'Новий' }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.lessee="{ item }">
            <ALink :text="item.lessee.name" :route="getEntityRoute(item)" />
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.contractLessee="{ item }">
            <ALink
              :text="item.contractLessee.number"
              :route="{
                name: 'dfl-contract',
                params: { id: item.contractLessee.id },
              }" />
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.amountAB="{ item }">
            {{ getBeautyNum(item.amountAB) + ' грн' }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.amountOl="{ item }">
            {{ getBeautyNum(item.amountOl) + ' грн' }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              style="margin-right: -10px"
              :actionsArray="[
                {
                  action: () =>
                    $router.push({
                      name: 'edit-payments',
                      params: { id: item.id },
                    }),
                  text: 'Переглянути',
                  icon: 'mdi-eye',
                },
              ]">
            </TableCrud>
          </template>
        </v-data-table>
        <Pagination :getItems="getPayments" :trigger="paginationTrigger" />
      </div>
    </div>
    <SectionLoader v-if="loading"></SectionLoader>
  </div>
</template>

<script>
import Filters from '@/components/Filters.vue'
import TableCrud from '@/components/table-crud.vue'
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import {
  toFormatDate,
  backDate,
  getResponsibleShortName,
  getBeautyNum,
} from '@/utils/helperFunc'
import {
  urlGetAgentPaymentsActs,
  urlGetBookKeepingPrintedForm,
} from '@/pages/request'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import { paymentListHeaders } from '@/utils/tableHeaders'
import ExportPfForm from '@/components/ExportPfForm.vue'
export default {
  name: 'BookKeepingList',
  components: {
    ExportPfForm,
    Filters,
    TableCrud,
    SectionLoader,
    ALink,
    Pagination,
  },
  setup() {
    const filters = reactive({
      search: { value: null },
      statusId: {
        value: [2, 3],
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'bookKeepingStatus' },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
    })
    return {
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      loading: false,
      filter: false,
      payments: [],
      paymentListHeaders,
    }
  },
  methods: {
    urlGetBookKeepingPrintedForm,
    toFormatDate,
    urlGetAgentPaymentsActs,
    backDate,
    getBeautyNum,
    getResponsibleShortName,
    getEntityRoute(item) {
      const typeContractor = item.lessee.lesseeTypeId === 2
      const name = typeContractor ? 'contractors-form' : 'individuals-form'
      const id = item.lessee.id
      return { name, params: { id } }
    },
    getPayments(page = 1) {
      this.loading = true
      return this.$axios
        .get(urlGetAgentPaymentsActs(page, this.filtersQueryObj))
        .then(res => {
          this.payments = res.data.data
          return res
        })
        .finally(() => (this.loading = false))
        .catch(this.$err)
    },
    navigateToPayment(e, row) {
      this.$router.push({ name: 'edit-payments', params: { id: row.item.id } })
    },
  },
}
</script>

<style lang="scss" scoped>
.modalTitle {
  color: #838282;
  font-size: 12px;
}
.nameBlock {
  position: relative;
  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &Info {
    display: none;
    position: absolute;
    z-index: 1000;
    width: 300px;
    background: white;
    -webkit-box-shadow: 0 0 32px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 32px -6px rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 13px -6px rgba(0, 0, 0, 0.75);
    border-radius: 7px;
    &__wrapper {
      padding: 10px;
    }
  }
}
.nameBlock__wrapper:hover .nameBlockInfo {
  display: block;
}

.success-application {
  color: #cccccc;
}
.wrapper {
  padding: 20px 10px;
}
.lead-name {
  width: 200px;
}
.comment {
  &-cell {
    position: relative;
  }
  &-dialog {
    position: absolute;
    right: 0;
    bottom: 10px;
    z-index: 10000;
    &__content {
      padding: 0 10px 10px 10px;
    }
    &-backdrop {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
    }
  }
}
.title-absolute {
  position: absolute;
  top: -50px;
  left: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #08487a;
}
.v-list-item.theme--light {
  padding-left: 0 !important;
}

.v-list-item__content {
  padding-left: 15px !important;
}
</style>
