<template>
  <v-dialog
    v-model="modal"
    class="cursor-default"
    z-index="20000"
    persistent
    :max-width="460"
    max-height="auto">
    <v-card class="bg-white">
      <v-toolbar class="toolbar-custom" :height="60" dark>
        <v-toolbar-title style="font-size: 1rem; min-width: 80%">
          Створити нову групу:
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn size="small" class="rounded-circle" icon @click="closeDialog">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none">
            <path
              d="M25 13C25 6.375 19.625 1 13 1C6.375 1 1 6.375 1 13C1 19.625 6.375 25 13 25C19.625 25 25 19.625 25 13Z"
              stroke="#C0C1C7"
              stroke-width="2"
              stroke-miterlimit="10" />
            <path
              d="M17 17L9 9M9 17L17 9"
              stroke="#C0C1C7"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="pt-0">
            <span class="label">Назва</span>
            <v-text-field
              v-model="companyGroupData.name"
              hide-details
              placeholder="Введіть текст"
              :error-messages="nameErr"
              @blur="v$.companyGroup.name.$touch()">
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="bg-white justify-space-around">
        <v-btn
          text
          :style="{
            width: '45%',
            color: '#08487a',
            'font-weight': 700,
            'font-size': '12px',
            background: '#fff',
            border: '2px solid #08487a',
          }"
          :height="33"
          small
          :disabled="loading"
          :loading="loading"
          @click="closeDialog">
          ВІДХИЛИТИ
        </v-btn>
        <v-btn
          class="text-white"
          :style="{
            width: '45%',
            background: '#08487a',
            'border-radius': '4px',
          }"
          :height="33"
          small
          :disabled="loading"
          :loading="loading"
          @click="submit">
          Створити
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { setErrHandler, v$Notify } from '@/utils/helperFunc'
import { urlCreateCompanyGroup } from '@/pages/request'
import { pushDirectoryItem } from '@/plugins/directory'

export default {
  name: 'CreateNewGroup',
  emits: ['closeDialog'],
  components: {},
  props: {
    companyGroupDialog: { type: Boolean },
    v$: { type: Object },
    companyGroup: { type: Object },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    companyGroupData() {
      return this.companyGroup
    },
    modal() {
      return this.companyGroupDialog
    },
    nameErr() {
      return setErrHandler(this.v$.companyGroup.name)
    },
  },
  methods: {
    closeDialog() {
      return this.$emit('closeDialog')
    },
    submit() {
      if (this.v$?.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(this.v$.companyGroup, 'companyGroup')
      } else {
        return this.sendRequest()
      }
    },
    sendRequest() {
      this.loading = true
      return this.$axios
        .post(urlCreateCompanyGroup(), this.companyGroupData)
        .then(res => {
          this.$setSnackbar({ text: 'Нову групу створено' })
          this.loading = false
          this.closeDialog()
          pushDirectoryItem('companyGroupList', res.data)
          return res
        })
    },
  },
}
</script>

<style scoped>
.toolbar-custom {
  color: #08487a;
  font-weight: 700 !important;
  font-size: 16px;
  background-color: #fff;
  box-shadow: none;
}
</style>
