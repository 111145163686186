<template>
  <div class="split-btn">
    <v-btn
      v-if="!customActivator"
      class="split-btn__button"
      :disabled="actions.main.disabled"
      readonly>
      {{ actions?.main?.label }}
    </v-btn>
    <v-menu
      v-model="menuOpen"
      location="bottom"
      offset-y
      :close-on-content-click="false">
      <template #activator="{ props }">
        <div
          v-if="customActivator && Array.isArray(actions.list)"
          class="d-flex align-center">
          <span v-bind="props" class="link"> {{ customActivatorText }}</span>
          <v-icon
            v-if="customActivatorIcon"
            class="ml-1"
            size="small"
            color="#fc7247">
            {{ customActivatorIcon }}
          </v-icon>
        </div>

        <v-btn
          v-else
          class="split-btn__icon"
          max-width="40px"
          min-width="40px"
          v-bind="props">
          <v-icon>{{ 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </template>
      <v-list elevation="1">
        <v-list-item
          v-for="(item, idx) in actions.list.filter(item => item.show)"
          :key="idx"
          :value="idx"
          :disabled="item.disabled"
          @click="handleItemClick(item)">
          <!-- if has actions -->
          <template v-if="Array.isArray(item.actions)">
            <v-menu bottom offset-y>
              <template #activator="{ props }">
                <div v-bind="props" class="full-clickable">
                  <v-list-item-title style="color: #08487a">
                    <v-icon class="mr-2">{{ item.icon }}</v-icon>
                    <span>{{ item.label }}</span>
                  </v-list-item-title>
                  <v-icon class="ml-auto" style="color: #08487a"
                    >mdi-chevron-right</v-icon
                  >
                </div>
              </template>
              <v-list elevation="1">
                <v-list-item
                  v-for="(subItem, subIdx) in item.actions"
                  :key="subIdx"
                  :value="subIdx"
                  :disabled="subItem.disabled"
                  @click="handleSubItemClick(subItem)">
                  <v-list-item-title>
                    <v-icon class="mr-2">{{ subItem.icon }}</v-icon>
                    <span>{{ subItem.label }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <!-- if not has actions -->
          <template v-else>
            <v-list-item-title
              style="color: #08487a"
              @click="handleItemClick(item)">
              <v-icon class="mr-2">{{ item.icon }}</v-icon>
              <span>{{ item.label }}</span>
            </v-list-item-title>
          </template>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    customActivator: { type: Boolean, default: false },
    customActivatorText: { type: String },
    customActivatorIcon: { type: String },
    actions: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      menuOpen: false,
    }
  },
  methods: {
    handleItemClick(item) {
      if (item.action) {
        item.action()
      }
      this.menuOpen = false
    },
    handleSubItemClick(subItem) {
      if (subItem.action) {
        subItem.action()
      }
      this.menuOpen = false
    },
  },
}
</script>

<style scoped>
.split-btn {
  display: flex;
  align-items: center;
}
.split-btn__button {
  letter-spacing: 1px;
  text-transform: none !important;
  background: #fc7247 !important;
  border-radius: 8px !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 1px solid white;
}
.split-btn__icon {
  background: #fc7247 !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.full-clickable {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
</style>
