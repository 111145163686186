<template>
  <div>
    <signature-basis-form
      class="mb-3"
      :employee-id="dialog.dialogItem.employeeId"
      @submit="signatureBasisList.push($event)"></signature-basis-form>
    <div v-for="(item, idx) in signatureBasisList" :key="item.id">
      <div class="d-flex justify-space-between align-center">
        <div>
          <div>{{ item.basis_document?.name || item.basisDocument?.name }}</div>
          <div v-if="item.documentComment" class="text-disabled">
            {{ item.documentComment }}
          </div>
        </div>
        <v-btn
          icon="mdi-delete"
          density="compact"
          color="error"
          :loading="$loading.isLoading(`deleteSignatureBasis_${item.id}`)"
          @click="deleteSignatureBasis(item.id)">
        </v-btn>
      </div>
      <v-divider
        v-if="idx < signatureBasisList.length - 1"
        class="mt-2 mb-2"></v-divider>
    </div>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { SignatureBasis } from '@/utils/types'
import SignatureBasisForm from './SignatureBasisForm.vue'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import axios from '@/plugins/axios'
import { urlSignatureBasisDelete } from '@/pages/request'
import { cloneDeep } from 'lodash'

export default {
  name: 'SignatureBasis',
  components: { SignatureBasisForm },
  props: {
    dialog: {
      type: Object,
    },
  },
  setup(props) {
    const signatureBasisList = ref<SignatureBasis[]>(
      props.dialog.dialogItem.employeeSignatoryBases
        ? cloneDeep(props.dialog.dialogItem.employeeSignatoryBases)
        : []
    )

    const deleteSignatureBasis = (signatureBasisId: number) => {
      return handleAsync(`deleteSignatureBasis_${signatureBasisId}`, () => {
        return axios
          .delete(urlSignatureBasisDelete(signatureBasisId))
          .then(() => {
            signatureBasisList.value = signatureBasisList.value.filter(
              s => s.id !== signatureBasisId
            )

            setSnackbar({ text: 'Підставу успішно видалено' })
          })
      })
    }

    const submit = () => {
      return Promise.resolve(signatureBasisList.value)
    }
    const reject = () => {
      return Promise.resolve(signatureBasisList.value)
    }
    const close = () => {
      return Promise.resolve(signatureBasisList.value)
    }

    return {
      signatureBasisList,
      submit,
      deleteSignatureBasis,
      reject,
      close,
    }
  },
}
</script>

<style scoped></style>
