<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pb-0">
        <span class="label">Контакт представника</span>
        <b-server-autocomplete
          v-model="representative.representativeId"
          :url="'/json/list/contact'"
          :default-item="representative.representative"
          :error-messages="representativeIdErr"
          item-value="id"
          hide-details
          item-title="fullName"
          placeholder="Введіть текст (мін. 3 символи)"
          @blur="v$.representative.representativeId.$touch()">
        </b-server-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pb-0">
        <span class="label">Тип документа</span>
        <v-select
          v-model="representative.documentTypeId"
          placeholder="Оберіть зi списку"
          hide-details
          :error-messages="documentTypeIdErr"
          :items="
            $directory
              .get('basisDocuments', representative.documentType)
              .filter(d => [7, 6].includes(d.id))
          "
          item-value="id"
          item-title="name"
          @focus="$directory.fill('basisDocuments')"
          @blur="v$.representative.documentTypeId.$touch()">
          <template #no-data><loader></loader></template>
          <template #append-item>
            <loader
              v-if="
                $loading.isLoading('basisDocuments') &&
                $directory.get('basisDocuments', representative.documentType)
                  .length
              "></loader>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pb-0">
        <span class="label">Інфо до документа</span>
        <v-text-field
          v-model="representative.documentInfo"
          placeholder="Введіть номер"
          hide-details
          :error-messages="documentInfoErr"
          @blur="v$.representative.documentInfo.$touch()"></v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pb-0">
        <span class="label">Дата видачi</span>
        <DatePicker
          v-model="representative.date"
          single
          hide-details
          placeholder="Оберіть дату" />
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pb-0">
        <span class="label">Дата закінчення</span>
        <DatePicker
          v-model="representative.endDate"
          single
          hide-details
          placeholder="Оберіть дату" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import DatePicker from '@/components/DatePicker.vue'
import { setErrHandler } from '@/utils/helperFunc'
import {
  urlContactsGetAll,
  urlRepresentativeCreate,
  urlRepresentativeUpdate,
} from '@/pages/request'
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'
import { inputNumber } from '@/utils/formatFunc'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { BServerAutocomplete } from 'best-modules/components'

export default {
  components: {
    Loader,
    DatePicker,
    BServerAutocomplete,
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: {
    dialog: { type: Object },
  },
  validations() {
    return {
      representative: {
        representativeId: { required },
        documentTypeId: { required },
        documentInfo: { required },
        date: { required },
        endDate: { required },
      },
    }
  },
  data() {
    return {
      representative: {
        contactId: null,
        representativeId: null,
        date: null,
        endDate: null,
        documentTypeId: null,
        documentInfo: null,
      },
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    validation() {
      return { v$: this.v$.representative, name: 'representative' }
    },
    addRepresentative() {
      return this.dialog.action === 'addRepresentative'
    },
    editRepresentative() {
      return this.dialog.action === 'editRepresentative'
    },
    representativeIdErr() {
      return setErrHandler(this.v$.representative.representativeId)
    },
    documentTypeIdErr() {
      return setErrHandler(this.v$.representative.documentTypeId)
    },
    documentInfoErr() {
      return setErrHandler(this.v$.representative.documentInfo)
    },
  },
  methods: {
    urlContactsGetAll,
    inputNumber,
    submit() {
      const url = this.addRepresentative
        ? urlRepresentativeCreate
        : urlRepresentativeUpdate
      if (this.editRepresentative) {
        delete this.representative.documentType
        delete this.representative.representative
      }
      return this.$axios
        .post(url(this.representative.id), this.representative)
        .then(res => res.data)
    },
  },
  created() {
    if (this.editRepresentative) {
      this.representative = this.dialog.dialogItem
    } else {
      this.representative.contactId = this.dialog.params.contactId
    }
  },
}
</script>

<style lang="scss" scoped></style>
